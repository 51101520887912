import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { AuthenticationService } from '../../../services/authentication.service';
import { UserLdapWs } from '../../../../core/models/user.ldap.ws';

@Component({
  selector: 'app-calendar-view-assistance-user',
  templateUrl: './calendar-view-assistance-user.component.html',
  styleUrls: ['./calendar-view-assistance-user.component.scss'],
})
export class CalendarViewAssistanceUserComponent implements OnInit {
  @Input() day: any;
  @Input() listAttendancesUser: any;

  dateNew: Date;
  maxDayAssistance: number;
  permissions: MenuPermissions;
  user: UserLdapWs;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.dateNew = new Date();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.user = this.authenticationService.getCurrentUser();
  }

  getClassSelected(day: any) {
    let ret = '';
    if (day.selected) {
      ret = ` selected`;
    }
    if (day.disabledWhitAssistance) {
      ret += ` dayCalendarDisabledWhitAssistance`;
    }
    if (day.assignments && (day.assignments.id || day.assignments.day)) {
      ret += ` assignment-assist-user`;
    }
    return ret;
  }
}
