<div [hidden]="!visible" class="reservation-invites">
  <div class="modal fade editmodal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-info clearfix">
          <button
            type="button"
            class="vdp-icon-cancelar"
            (click)="closeComponent()"
          ></button>
          <label class="title" for="roomModalName">{{
            'send_invitation' | messagesPipe
          }}</label>
          <span class="roomName" id="roomModalName">{{
            currentReservation?.room?.name
          }}</span>

          <div class="last-textfield form-group col-sm-12 mui-textfield">
            <label>{{
              'search_users_to_send_invitation' | messagesPipe
            }}</label>
            <div class="ng-autocomplete" style="margin-top: 10px;">
              <ng-autocomplete
                #usersAutocompleteComponent
                [data]="users"
                [searchKeyword]="usersKeyword"
                (selected)="onUserSelected($event)"
                (inputChanged)="onSearchChanged($event)"
                (inputCleared)="onCleared()"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                [debounceTime]="600"
                [isLoading]="isLoadingUsers"
                [minQueryLength]="2"
                placeHolder="{{ 'user_to_send_invite' | messagesPipe }}"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a href="javascript:void(0)" class="invitation-users-item">
                  <span class="invitation-users-info">
                    <span [innerHTML]="item.userName"></span>
                    <span
                      class="invitation-users-email"
                      *ngIf="item.email != null && item.email.length > 0"
                    >
                      ({{ item.email }})</span
                    >
                  </span>
                </a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div>{{ 'invitation_user_not_found' | messagesPipe }}</div>
              </ng-template>
            </div>

            <button
              class="vdp-icon-mas add"
              type="button"
              [disabled]="invalidEmail"
              (click)="addUserToList()"
              style="margin-top: 10px;"
            ></button>
          </div>

          <ul class="invitation-users" *ngIf="selectedUsers.length > 0">
            <li
              class="invitation-users-item"
              *ngFor="let invitationSelectedUser of selectedUsers"
            >
              <span class="invitation-users-info">
                <span class="invitation-users-name">{{
                  invitationSelectedUser.name
                }}</span>
                <span
                  class="invitation-users-email"
                  *ngIf="
                    invitationSelectedUser.email != null &&
                    invitationSelectedUser.email.length > 0
                  "
                >
                  ({{ invitationSelectedUser.email }})</span
                >
              </span>
              <button (click)="removeUser(invitationSelectedUser)">
                <i class="vdp-icon-cancelar"></i>
              </button>
            </li>
          </ul>

          <button
            [disabled]="!selectedUsers || selectedUsers.length <= 0"
            class="reservation-button"
            type="button"
            (click)="sendInvitation()"
          >
            {{ 'send_invitation' | messagesPipe }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
