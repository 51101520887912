<div class="quesito-and-answers-new__container">
  <div class="quesito-and-answers-new__container__line">
    <label>{{ resumeTreeStage?.description }} </label>
    <label
      class="list"
      *ngFor="let tree of resumeTreeStage?.treeUserLdapStageEventList"
      (click)="getEditTreeStage(tree.id)"
    >
      {{ tree?.treeStage?.content ? ' > ' + tree.treeStage.content : null }}
    </label>
    <label
      *ngIf="nextTreeStage?.treeStage?.content"
      class="list"
      (click)="navigateNextTree(treeUserLdapIdActive)"
      >{{ ' > ' + nextTreeStage?.treeStage?.content }}
    </label>
  </div>

  <form
    class="quesito-and-answers-new__container__content"
    [formGroup]="questionAndAnswersForm"
    (ngSubmit)="isEdit ? postUpdateTreeStageEvent() : addTreeStageEvent()"
    [@inOutAnimation]
    *ngIf="activeTreeStage"
  >
    <div class="quesito-and-answers-new__container__content--question">
      <h1>
        {{ activeTreeStage?.treeStage?.content | uppercase }}
      </h1>
      <h4>
        {{ activeTreeStage?.treeStage?.comment }}
      </h4>
    </div>
    <div class="quesito-and-answers-new__container__content--answer">
      <div class="response" *ngIf="activeTreeStage?.treeEvents">
        <div class="response__selects" data-toggle="buttons">
          <button
            class="btn btn-secondary"
            [ngClass]="{
              active:
                questionAndAnswersForm.controls?.treeEventId.value === event?.id
            }"
            type="button"
            *ngFor="let event of activeTreeStage?.treeEvents"
            (click)="setEvent(event)"
          >
            {{ event.content }}
          </button>
        </div>
        <div class="response__comments">
          <span> {{ responseActive?.comment }}</span>
        </div>
        <div class="response__inputs">
          <div
            class="text"
            [ngClass]="{ active: responseActive?.enableInputText }"
          >
            <input
              [placeholder]="
                messagesPipe.transform(
                  'question-and-answers_new_text_placeholder'
                )
              "
              formControlName="text"
            />
          </div>
          <div
            class="file"
            [ngClass]="{ active: responseActive?.enableInputFile }"
          >
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="file"
                aria-describedby="inputGroupFileAddon01"
                (change)="setFile($event.target.files[0])"
              />
              <label class="custom-file-label" for="file">{{
                questionAndAnswersForm.controls?.file.value
                  ? questionAndAnswersForm.controls?.file.value.name
                  : messagesPipe.transform(
                      'question-and-answers_new_file_placeholder'
                    )
              }}</label>
              <span
                class="vdp-logout"
                *ngIf="
                  questionAndAnswersForm.controls?.file?.value &&
                  fileBlobEdit &&
                  isEdit
                "
                (click)="donwloadFile()"
              ></span>
              <span
                class="vdp-close-lateral-foto"
                *ngIf="questionAndAnswersForm.controls?.file?.value"
                (click)="deleteFile()"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="activeTreeStage"
        class="next"
        type="submit"
        [disabled]="!questionAndAnswersForm?.valid"
      >
        {{ 'question-and-answers_new_next' | messagesPipe }}
      </button>
    </div>
  </form>
</div>
