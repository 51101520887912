import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { ReservationCarwashWs } from '../../../core/models/reservation.carwash.ws';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { CarWashService } from '../../../shared/services/car-wash.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { slideButtons } from '../../../shared/utils/reservation.list.animations';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-car-wash-list',
  templateUrl: './car-wash-list.component.html',
  styleUrls: ['./car-wash-list.component.scss'],
  animations: [slideButtons()],
})
export class CarWashListComponent implements OnInit {
  carWashList: ReservationCarwashWs[] = [];
  carWashHistoryList: ReservationCarwashWs[] = [];
  showOptionsButtons: boolean = false;

  showReserveForm = false;
  dataEdit = null;
  ie: boolean;

  messagesPipe = new MessagesPipe();
  constructor(
    private carWashService: CarWashService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private sideModalService: SideModalService,
    private route: ActivatedRoute
  ) {
    this.showOptionsButtons = false;
    this.ie = false;
  }

  ngOnInit(): void {
    let userAgent: string, ieReg: RegExp;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
    this.getCarWash();
    this.getCarWashMe();
    this.closeReserve();
  }

  showButtons(): void {
    this.showOptionsButtons = !this.showOptionsButtons;
  }

  getCarWash() {
    this.carWashService.getCarWashHistoric().subscribe(
      (res) => {
        if (res) {
          this.carWashHistoryList = res;
        } else {
          this.carWashHistoryList = [];
        }
      },
      () => {
        this.carWashHistoryList = [];
      }
    );
  }

  getCarWashMe() {
    this.carWashService.getCarWashReservationMe().subscribe(
      (res) => {
        if (res) {
          this.carWashList = res;
        } else {
          this.carWashList = [];
        }
      },
      () => {
        this.carWashList = [];
      }
    );
  }

  cancelCarWashReservation(carwashReservationId: number) {
    this.spinnerService.show();
    this.carWashService
      .cancelCarwashReservation(carwashReservationId)
      .subscribe(
        (res) => {
          this.getCarWashMe();
          this.flashMessagesService.show(
            this.messagesPipe.transform('carWash_list_cancel_reservation_ok'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'carWash_list_cancel_reservation_error'
            ),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.spinnerService.hide();
        }
      );
  }

  editCarWashReservation(data) {
    this.dataEdit = data;
    this.sideModalService.openSideModal(
      'reserve',
      { dataEdit: this.dataEdit },
      this.messagesPipe.transform('carWash_edit_title'),
      this.route,
      true
    );
  }

  openReserve() {
    this.sideModalService.openSideModal(
      'reserve',
      {},
      this.messagesPipe.transform('carWash_reserve_title'),
      this.route,
      false
    );
  }

  closeReserve() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close && res.nameComponent) {
        this.showOptionsButtons = false;
        this.getCarWashMe();
      }
    });
  }

  getSlideButtonsClass(): string {
    if (this.ie) {
      const el = document.getElementById('carWash-list');
      if (this.showOptionsButtons) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    } else {
      if (this.showOptionsButtons) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }
}
