import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';

@Component({
  selector: 'app-calendar-edit-list-users',
  templateUrl: './calendar-edit-list-users.component.html',
  styleUrls: ['./calendar-edit-list-users.component.scss'],
})
export class CalendarEditListUsersComponent implements OnInit {
  assistanceSelected: any;
  listUsersForDay: UserLdapWs;

  user: UserLdapWs;

  constructor(private router: Router) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      const { assistanceSelected, listUsersForDay } =
        this.router.getCurrentNavigation().extras.state;
      this.assistanceSelected = assistanceSelected;
      this.listUsersForDay = listUsersForDay;
    }
  }

  ngOnInit() {}
}
