import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AttributeService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  reservationAttributeList(): Observable<DeskAttribute[]> {
    const url = `${SERVICE_BASE_URL}/api/deskReservationAttribute/list?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
