import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParkingSizeModel } from '../../../core/models/parking.size';
import { ReservationCarwashWs } from '../../../core/models/reservation.carwash.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { ModalReservationGenericService } from '../../../shared/components/modal-reservation-generic/modal-reservation-generic.service';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CarWashService } from '../../../shared/services/car-wash.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { Utils } from '../../../shared/utils/utils';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-car-wash-reserve',
  templateUrl: './car-wash-reserve.component.html',
  styleUrls: ['./car-wash-reserve.component.scss'],
})
export class CarWashReserveComponent implements OnInit {
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;

  dataEdit: ReservationCarwashWs;

  listCarWashParkingSizeAll: ParkingSizeModel[];

  reservationCarWashForm: FormGroup;
  datepickerOptions: any = {};
  locale: string;

  messagesPipe = new MessagesPipe();
  constructor(
    private carWashService: CarWashService,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private modalReservationGenericService: ModalReservationGenericService,
    private spinnerService: SpinnerService,
    private flashMessagesService: FlashMessagesService,
    private sideModalService: SideModalService,
    private router: Router
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.dataEdit = this.router.getCurrentNavigation().extras.state.dataEdit;
    }
  }

  ngOnInit(): void {
    if (this.dataEdit) {
      this.createFormEdit(this.dataEdit);
    } else {
      this.dataEdit = null;
      this.createForm();
    }
    this.getCarWashParkingSizeListAll();
    this.datepickerOptions = this.utils.getDatepickerOptionsOnlyFrom();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.dataEdit) {
        this.utils.setDatePickerDate(new Date(), this.datePickerElement);
      }
      if (this.dataEdit) {
        this.utils.setDatePickerDate(
          new Date(this.dataEdit.dateFrom),
          this.datePickerElement
        );
      }
    }, 0);
  }

  createForm() {
    const actualDate = new Date();
    this.reservationCarWashForm = new FormGroup({
      carwashSizeId: new FormControl({ value: 0, disabled: false }, [
        Validators.required,
        Validators.pattern(/[^0]/),
      ]),
      date: new FormControl(
        actualDate.setFullYear(
          actualDate.getFullYear(),
          actualDate.getMonth(),
          actualDate.getDate()
        ),
        Validators.required
      ),
      license: new FormControl(null, [Validators.required]),
    });
  }

  createFormEdit(data) {
    this.reservationCarWashForm = new FormGroup({
      carwashSizeId: new FormControl(
        { value: data.carwash.size.id, disabled: true },
        [Validators.required, Validators.pattern(/[^0]/)]
      ),
      date: new FormControl(data.dateFrom, Validators.required),
      license: new FormControl({ value: data.license, disabled: true }),
    });
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      const reservationDate = new Date();
      this.reservationCarWashForm
        .get('date')
        .setValue(
          reservationDate.setFullYear(
            $event.date.year,
            $event.date.month - 1,
            $event.date.day
          )
        );
    } else {
      this.reservationCarWashForm.get('date').setValue(null);
    }
  }

  getCarWashParkingSizeListAll() {
    this.carWashService.getCarWashParkingSizeListAll().subscribe((res) => {
      if (res) {
        this.listCarWashParkingSizeAll = res;
      } else {
        this.listCarWashParkingSizeAll = [];
      }
    });
  }

  checkHourReserve() {
    const { openingTime, endingTime, headquarters } =
      this.authenticationService.getCurrentUser();

    this.modalReservationGenericService.openModal(
      this.reservationCarWashForm.value.date,
      openingTime,
      endingTime,
      headquarters.carwashReservationConfigureTimeInterval,
      [],
      false
    );
    this.modalReservationGenericService.setLanguage(
      this.locale,
      'reserve_carwash_title'
    );
  }

  checkHourReserveEdit() {
    this.getCarWashReservation(
      this.reservationCarWashForm.value.date,
      this.dataEdit.carwash.id
    );
  }

  getCarWashReservation(date, carwashId) {
    this.carWashService
      .getCarWashReservation(date, carwashId)
      .subscribe((res) => {
        const { openingTime, endingTime, headquarters } =
          this.authenticationService.getCurrentUser();
        this.modalReservationGenericService.openModal(
          this.reservationCarWashForm.value.date,
          openingTime,
          endingTime,
          res.carwashReservationConfigureTimeInterval,
          res.reservations as any,
          false,
          this.dataEdit.id,
          true
        );
        this.modalReservationGenericService.setLanguage(
          this.locale,
          'reserve_carwash_edit_title'
        );
      });
  }

  addCarwashReservation(data: any) {
    this.spinnerService.show();
    this.carWashService
      .addCarwashReservation(
        data.dateFrom,
        data.dateTo,
        +this.reservationCarWashForm.get('carwashSizeId').value,
        this.reservationCarWashForm.get('license')?.value
      )
      .subscribe(
        (res) => {
          if (res) {
            this.modalReservationGenericService.closeModal();
            this.sideModalService.closeSideModal();
            this.flashMessagesService.show(
              this.messagesPipe.transform('carWash_list_add_reservation_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          } else {
            this.flashMessagesService.show(
              this.messagesPipe.transform('carWash_list_add_reservation_error'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('carWash_list_add_reservation_error'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.spinnerService.hide();
        }
      );
  }

  editCarwashReservation(data: any) {
    this.spinnerService.show();
    this.carWashService
      .editCarwashReservation(data.dateFrom, data.dateTo, this.dataEdit.id)
      .subscribe(
        (res) => {
          if (res) {
            this.modalReservationGenericService.closeModal();
            this.sideModalService.closeSideModal();
            this.flashMessagesService.show(
              this.messagesPipe.transform('carWash_list_edit_reservation_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          } else {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'carWash_list_edit_reservation_error'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('carWash_list_edit_reservation_error'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.spinnerService.hide();
        }
      );
  }
}
