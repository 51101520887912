export const ListOfPositionsRoutes = {
  searchUserPosition: {
    title: 'reservation_menu_user',
    routerName: 'searchUserPosition',
  },
  multipleReservation: {
    title: 'reservation_menu_multiple',
    routerName: 'multipleReservation',
  },
  reservationByPosition: {
    title: 'reservation_by_position_title',
    routerName: 'reservationByPosition',
  },
  checkInWithCode: {
    title: 'check_in_with_code_title',
    routerName: 'checkInWithCode',
  },
};
