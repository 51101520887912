import { Component, OnInit } from '@angular/core';
import { ModalAcceptDialogService } from '../../shared/components/modal-accept-dialog/modal-accept-dialog.service';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss'],
})
export class AssistanceComponent implements OnInit {
  mode = 'ASSISTANCE';

  constructor(private modalAcceptDialogService: ModalAcceptDialogService) {}

  ngOnInit() {}
}
