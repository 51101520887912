import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import {
  AVAILABLE_MINUTES,
  LOGOUT_TIMEOUT,
} from 'src/app/core/constants/consts';
import { AvailablePeriodsInput } from 'src/app/core/models/available.periods.input';
import { BuildingWs } from 'src/app/core/models/building.ws';
import { Headquarters } from 'src/app/core/models/headquarters';
import { RoomWs } from 'src/app/core/models/room.ws';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { RoomService } from 'src/app/shared/services/room.service';
import { Utils } from 'src/app/shared/utils/utils';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-reservation-search-by-room-and-period',
  templateUrl: 'reservation.search.by.room.period.component.html',
  styleUrls: ['reservation.search.by.room.period.component.scss'],
})
export class ReservationSearchByRoomAndPeriodComponent
  implements OnInit, AfterViewInit
{
  buildings: BuildingWs[];

  @ViewChild('datePickerFromElement', null) datePickerFromElement: MyDatePicker;
  @ViewChild('datePickerToElement', null) datePickerToElement: MyDatePicker;
  @ViewChild('selectDurationHours', null) selectDurationHours: any;
  @ViewChild('selectDurationMinutes', null) selectDurationMinutes: any;
  @ViewChild('selectBuildings', null) selectBuildings: any;
  @ViewChild('selectRooms', null) selectRooms: any;

  //TODO:  readonly durationHours: string[] = ['0', '1', '2', '3', '4', '5']; Este es para vodafone
  readonly durationHours: string[] = ['1', '2', '3', '4', '5'];
  readonly durationMinutes: string[] = AVAILABLE_MINUTES;

  reservationForm: FormGroup;
  datepickerFromOptions: any;
  datepickerToOptions: any;
  reservationDateFrom: Date;
  reservationDateTo: Date;
  reservationReason: string;
  rooms: RoomWs[];
  buildingSelected: BuildingWs;
  roomSelected: RoomWs;
  user: UserLdapWs;
  userHeadquarter: Headquarters;
  messagesPipe: MessagesPipe;
  selectedDurationHours: string = this.durationHours[0];
  selectedDurationMinutes: string = this.durationMinutes[0];
  availablePeriodsInput: AvailablePeriodsInput;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private utils: Utils,
    private roomService: RoomService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private router: Router,
    private sideModalService: SideModalService
  ) {
    this.messagesPipe = new MessagesPipe();
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.buildings =
        this.router.getCurrentNavigation().extras.state.buildings;
    }
  }

  ngOnInit() {
    this.reservationForm = this.formBuilder.group({
      reason: ['', Validators.required],
    });

    this.buildingSelected = null;
    this.roomSelected = null;
    this.rooms = [];
    this.availablePeriodsInput = null;

    this.user = this.authenticationService.getCurrentUser();
    this.userHeadquarter = this.user.headquarters;

    this.datepickerFromOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );

    const today = new Date();
    this.datepickerToOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate(),
      today
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const today = new Date();
      this.utils.setDatePickerDate(today, this.datePickerFromElement);
      this.resetDateToElement(today);
      this.selectBuildings.nativeElement.selectedIndex = 0;
      this.selectRooms.nativeElement.selectedIndex = 0;
    }, 0);
  }

  checkReservations(): void {
    this.reservationReason = this.reservationForm.controls['reason'].value;

    this.availablePeriodsInput = new AvailablePeriodsInput(
      this.roomSelected.id,
      parseInt(this.selectedDurationHours, 0),
      parseInt(this.selectedDurationMinutes, 0),
      this.reservationDateFrom,
      this.reservationDateTo
    );
  }

  submitButtonEnabled(): boolean {
    return (
      this.selectedDurationHours &&
      this.selectedDurationMinutes &&
      this.roomSelected &&
      this.reservationDateFrom !== null &&
      this.reservationDateTo !== null &&
      this.reservationForm.controls['reason'].value
    );
  }

  onBuildingChanged($event: any) {
    this.roomSelected = null;

    const buildingId = parseInt($event.target.value, 0);

    if (buildingId > 0) {
      this.buildingSelected = new BuildingWs(buildingId, '');
      this.updateRoomsForBuilding(this.buildingSelected);
    } else {
      this.buildingSelected = null;
      this.rooms = [];
    }
  }

  onRoomChanged($event: any) {
    if ($event.target.value !== '-1') {
      this.roomSelected = new RoomWs(
        $event.target.options[$event.target.selectedIndex].text,
        '',
        '',
        '',
        ''
      );
      this.roomSelected.id = $event.target.value;
    } else {
      this.roomSelected = null;
    }
  }

  onDateFromChanged($event: any): void {
    this.reservationDateFrom = $event.jsdate;

    if ($event.jsdate) {
      this.resetDateToElement($event.jsdate);
    }
  }

  onDateToChanged($event: any): void {
    this.reservationDateTo = $event.jsdate;
  }

  onChangeDurationHours($event): void {
    this.selectedDurationHours = $event.target.value;
  }

  onChangeDurationMinutes($event): void {
    this.selectedDurationMinutes = $event.target.value;
  }

  private resetDateToElement(initialDate: Date): void {
    const tomorrow = new Date(
      initialDate.getFullYear(),
      initialDate.getMonth(),
      initialDate.getDate() + 1
    );

    this.utils.setDatePickerDate(tomorrow, this.datePickerToElement);
  }

  private updateRoomsForBuilding(building: BuildingWs): void {
    this.roomService
      .getRoomsByBuilding(building, this.authenticationService.getToken())
      .subscribe(
        (rooms) => {
          this.rooms = rooms;
        },
        (error) => {
          this.handleRequestError(error, 'load_rooms_error', () => {
            this.updateRoomsForBuilding(building);
          });
        }
      );
  }

  private handleRequestError(
    error: any,
    message: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            () => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.baseService.showErrorDialog(
        this.flashMessagesService,
        error,
        this.messagesPipe.transform(message)
      );
    }
  }

  closeComponent() {
    this.sideModalService.closeSideModal();
  }
}
