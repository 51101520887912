import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { AdinResponse } from 'src/app/core/models/adin.response';
import { OPTIONS } from '../../core/constants/const';
import { FlashMessagesService } from './flash-messages.service';
import { AuthenticationService } from './authentication.service';
import { SERVICE_BASE_URL } from '../../../environments/environment';

@Injectable()
export class AdinService {

  constructor(
    private http: Http,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService
  ) {}

  searchByName(nameSearch: string): Observable<AdinResponse[]> {
    let resultados: AdinResponse[];

    let bodyString = JSON.stringify({
      search: nameSearch,
    });

    return this.http
      .post(`${SERVICE_BASE_URL}/web/parking/search/name?access_token=${this.authenticationService.getToken()}`, bodyString, OPTIONS)
      .map((response: Response) => {
        let adinResponse = response.json();

        let resultados = adinResponse.Valor_Devuelto;
        let errores = adinResponse.Errores;

        if (errores) {
          if (errores.length > 0) {
            let msj = errores[0];

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(msj, {
              cssClass: 'alert-error',
              timeout: 3000,
            });

            sessionStorage.setItem('authenticated', JSON.stringify(false));
            this.router.navigate(['/login']);
          }
        }

        return resultados;
      })
      .catch((err: Response) => {
        let details = err.json();
        return Observable.throw(details);
      });
  }

  searchByPlace(placeSearch: string): Observable<AdinResponse[]> {
    let resultados: AdinResponse[];

    let bodyString = JSON.stringify({
      search: placeSearch
    });

    return this.http
      .post(`${SERVICE_BASE_URL}/web/parking/search/place?access_token=${this.authenticationService.getToken()}`, bodyString, OPTIONS)
      .map((response: Response) => {
        let adinResponse = response.json();
        let resultados = adinResponse.Valor_Devuelto;
        let errores = adinResponse.Errores;
        if (errores) {
          if (errores.length > 0) {
            let msj = errores[0];

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(msj, {
              cssClass: 'alert-error',
              timeout: 3000,
            });

            if (msj == 'Usuario no se ha encontrado') {
              sessionStorage.setItem('authenticated', JSON.stringify(false));
              this.router.navigate(['/login']);
            }
          }
        }

        if (resultados == null) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'No existen resultados para la búsqueda',
            { cssClass: 'alert-error', timeout: 3000 }
          );
        } else if (resultados.length == 0) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            'No existen resultados para la búsqueda',
            { cssClass: 'alert-error', timeout: 3000 }
          );
        } else {
          return resultados;
        }
      })
      .catch((err: Response) => {
        let details = err.json();
        return Observable.throw(details);
      });
  }

  searchByPlate(plateSearch: string): Observable<AdinResponse[]> {
    let resultados: AdinResponse[];

    let bodyString = JSON.stringify({
      search: plateSearch,
    });

    return this.http
      .post(`${SERVICE_BASE_URL}/web/parking/search/plate?access_token=${this.authenticationService.getToken()}`, bodyString, OPTIONS)
      .map((response: Response) => {
        let adinResponse = response.json();
        let resultados = adinResponse.Valor_Devuelto;
        let errores = adinResponse.Errores;

        if (errores) {
          if (errores.length > 0) {
            let msj = errores[0];

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(msj, {
              cssClass: 'alert-error',
              timeout: 3000,
            });

            sessionStorage.setItem('authenticated', JSON.stringify(false));
            this.router.navigate(['/login']);
          }
        }

        return resultados;
      })
      .catch((err: Response) => {
        let details = err.json();
        return Observable.throw(details);
      });
  }
}
