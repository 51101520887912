import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { OPTIONS } from 'src/app/core/constants/const';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class BoxOfficeService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  searchHostDesck(
    areaId: number,
    date: number,
    token: string,
    option: string,
    listAttributes?: DeskAttribute[]
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/search?access_token=${token}`;
    const headers = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    let body: any = {
      areaId: areaId,
      date: date,
    };
    if (listAttributes) {
      body.attributesId = listAttributes;
    }

    return this.http
      .post(url, body, headers)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  hourReservationDesk(
    deskId: number,
    date: number,
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations?access_token=${token}`;
    const headers = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    const body = {
      deskId: deskId,
      date: date,
    };

    return this.http
      .post(url, body, headers)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  addReservationDesk(
    deskId: number,
    dateFrom: number,
    dateTo: number,
    checkin: boolean,
    token: string,
    option: string
  ) {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations/add?access_token=${token}`;
    const headers = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    const body = {
      deskId: deskId,
      dateTo: dateTo,
      dateFrom: dateFrom,
      checkin: checkin,
    };

    return this.http
      .post(url, body, headers)
      .map((res: any) => {
        return res;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  editReservationDesk(
    idReservation: number,
    dateFrom: number,
    dateTo: number,
    token: string,
    option: string
  ) {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations/edit/${idReservation}?access_token=${token}`;
    const headers = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    const body = {
      dateTo: dateTo,
      dateFrom: dateFrom,
    };

    return this.http
      .put(url, body, headers)
      .map((res: any) => {
        return res;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getUserReservations(token: string, option: string): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations/me?access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelHotDeskReservation(
    locatorCode: string,
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/cancel?access_token=${token}`;

    const body = {
      locatorCode,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getUserDeskHistory(
    token: string,
    page: number,
    size: number,
    option: string
  ): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/${option}/historic?page=${page}&size=${size}&access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getUserReservationsByPeriod(
    period: number,
    date: string,
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations/count/${period}/${date}?access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  doCheckInWithNfc(
    reservationId: number,
    nfc: string,
    option: string,
    token: string
  ): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/${option}/checkin/reservation?access_token=${token}`;

    const body = {
      reservationId,
      nfc,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  doCheckinWithoutNfc(
    reservationId: number,
    token: string,
    option: string
  ): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/${option}/webCheckin/reservation?access_token=${token}`;

    const body = {
      reservationId,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  doCheckout(locatorCode: string, token: string, option): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/checkout?access_token=${token}`;

    const body = {
      reservationCode: locatorCode,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  shareUsersAndDepartments(
    id: number,
    listOfEmails: any[],
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/shareUsersDepartments/${id}?access_token=${token}`;

    const body = listOfEmails;

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  shareUsers(
    idReserva: number,
    users: any[],
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/share?access_token=${token}`;

    const body = {
      reservationId: idReserva,
      users: users,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((res: any) => {
        return res;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getMultipleReservationList(
    dateFrom: number,
    dateTo: number,
    hotdeskNumber: number,
    hourNumber: number,
    usersList: any[],
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/multiReservations?access_token=${token}`;

    const body = {
      dateFrom,
      dateTo,
      hotdeskNumber,
      hourNumber,
      usersList,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  addMultipleReservation(
    dateFrom: number,
    dateTo: number,
    desks: any[],
    users: any[],
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/reservations/addMultiple?access_token=${token}`;

    const body = {
      dateFrom,
      dateTo,
      desks,
      users,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getReservationList(
    date: number,
    userId: number,
    token: string,
    option: string
  ): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/${option}/findByUserIdAndDate?access_token=${token}`;

    const body = {
      date,
      userId,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
