import { Injectable } from '@angular/core';
import {Headers, Http, RequestOptions} from '@angular/http';
import {AuthenticationService} from './authentication.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {SERVICE_BASE_URL} from '../../../environments/environment';

const GET_CUSTOM_EXTERNAL_MODULE_URL = '/api/externalModule/detail';

@Injectable({
  providedIn: 'root'
})
export class CustomExternalModuleService {

  constructor(private http: Http,
              private authenticationService: AuthenticationService,
              private baseService: BaseService) { }

  public getCustomExternalModule(moduleId: number, token: string): Observable<any> {
    const url = `${SERVICE_BASE_URL}${GET_CUSTOM_EXTERNAL_MODULE_URL}?access_token=${token}`;
    const headers = new RequestOptions({
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    });
    const body = {
        id: moduleId
    };

    return this.http.post(url, body, headers).map(
        (res: any) => {
          return res.json();
        }).catch(error => {
            if (error && error.status && error.status === 404) {
                return Observable.throwError({ code: 404 });
            } else {
                return this.baseService.handleError(this.authenticationService, error);
            }
    });
  }
}
