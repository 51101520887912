import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-switcher',
  templateUrl: './login-switcher.component.html',
  styleUrls: ['./login-switcher.component.scss'],
})
export class LoginSwitcherComponent implements OnInit {
  loginWithAzure = false;

  constructor() {}

  ngOnInit() {
    this.checkAvailabeLogins();
  }

  checkAvailabeLogins() {
    if (environment['use_azure']) {
      this.loginWithAzure = true;
    }
  }
}
