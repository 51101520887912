import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TYPE_MENU_EXTERNAL } from 'src/app/core/constants/consts';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-menu-external',
  templateUrl: './menu-external.component.html',
  styleUrls: ['./menu-external.component.scss'],
})
export class MenuExternalComponent implements OnInit {
  @Input() module: any;
  @Output() setNavSticky = new EventEmitter<any>();
  url: any;
  type_menu_external = TYPE_MENU_EXTERNAL;

  constructor(
    private domSanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    const token = this.authenticationService.getToken();
    const locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
    let url = this.module.content;
    if (url && this.module.type.code === '03') {
      url = `${url}?access_token=${token}&locale=${locale}`;
    }
    this.url = this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  navigateToCustomModule() {
    this.setNavSticky.emit();
    this.router.navigate([
      './menu/external-module/customExternalModule',
      this.module.id,
    ]);
  }

  isActive(id) {
    return this.router.isActive(
      `/menu/external-module/customExternalModule/${id}`,
      true
    );
  }
}
