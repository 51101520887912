import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { Subscription } from 'rxjs';
import { STATUS_FORBIDDEN } from '../../../core/constants/const';
import { LOGOUT_TIMEOUT } from '../../../core/constants/consts';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { ReservationService } from '../../../shared/services/reservation.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-reservation-invites',
  templateUrl: 'reservation.invites.component.html',
  styleUrls: ['reservation.invites.component.scss'],
})
export class ReservationInvitesComponent implements OnInit {
  @ViewChild('usersAutocompleteComponent', null)
  usersAutocompleteComponent: AutocompleteComponent;
  @Input() visible: boolean;
  @Input() currentReservation: ReservationWs;
  @Output() closeEvent = new EventEmitter();

  messagesPipe = new MessagesPipe();
  currentInvitationsRequestSubscription: Subscription;
  usersKeyword = 'userNameEmail';
  users: any[] = [];
  selectedUsers: any[] = [];
  isLoadingUsers = false;
  userNotRegistered = '';
  invalidEmail = true;

  constructor(
    private authenticationService: AuthenticationService,
    private reservationService: ReservationService,
    private userService: UserService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.reset();
  }

  onUserSelected(user: any): void {
    if (this.currentInvitationsRequestSubscription) {
      this.currentInvitationsRequestSubscription.unsubscribe();
    }

    this.isLoadingUsers = false;
    this.selectedUsers = this.addUserToSelectedUsers(user);

    this.usersAutocompleteComponent.clear();
    this.usersAutocompleteComponent.close();
  }

  onSearchChanged(searchText: string): void {
    this.isLoadingUsers = true;
    this.currentInvitationsRequestSubscription = this.userService
      .listUsersLdapByFilter(this.authenticationService.getToken(), searchText)
      .subscribe(
        (response) => {
          if (response) {
            this.users = response.filter((user) => {
              return user.email && user.email.length > 0;
            });
            this.users.forEach(item =>item.userNameEmail = item.userName + " " +item.mail);
          } else {
            this.users = [];
          }
        },
        (error) => {
          this.handleNetworkError(
            error,
            'invitations_users_search_error',
            () => {
              this.onSearchChanged(searchText);
            }
          );
        },
        () => {
          this.isLoadingUsers = false;
        }
      );
  }
  onCleared(): void {
    this.usersAutocompleteComponent.close();
  }
  sendInvitation(): void {
    this.reservationService
      .sendICalendarToInvitatedUsersMail(
        this.authenticationService.getToken(),
        this.currentReservation.id,
        this.selectedUsers.map((user) => {
          return user.email;
        })
      )
      .subscribe(
        (response) => {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('invitation_sent'),
            { cssClass: 'alert-success', timeout: 3000 }
          );

          this.closeComponent();
        },
        (error) => {
          this.handleNetworkError(error, 'invitations_users_send_error', () => {
            this.sendInvitation();
          });
        }
      );
  }

  closeComponent(): void {
    this.reset();
    this.usersAutocompleteComponent.clear();
    this.usersAutocompleteComponent.close();
    this.closeEvent.emit();
  }

  removeUser(user: any): void {
    this.selectedUsers = this.selectedUsers.filter((u) => {
      return u.userName.toLowerCase() !== user.userName.toLowerCase();
    });
  }

  private addUserToSelectedUsers(user: any): any[] {
    const filteredUsers = this.selectedUsers.filter((u) => {
      return u.userName.toLowerCase() !== user.userName.toLowerCase();
    });

    filteredUsers.push(user);

    return filteredUsers;
  }

  private reset(): void {
    this.users = [];
    this.selectedUsers = [];
    this.isLoadingUsers = false;
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            () => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }

  validateEmail(email: string): boolean {
    const regexp = new RegExp(
      // tslint:disable-next-line: max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return regexp.test(email);
  } 

  addUserToList(): void {
    const user: { email: string; userName: string; name: string } = {
      email: this.userNotRegistered,
      userName: new Date().getTime().toString(),
      name: this.messagesPipe.transform('invitations_user_not_registered'),
    };

    if (this.userNotRegistered.length > 0) {
      this.userNotRegistered = '';
      this.invalidEmail = true;
      this.onUserSelected(user);
    }
  }
}
