import { RoomType } from './room.type';
import { Floor } from './floor';
import { EquipementWs } from './equipement.ws';
import { Building } from './building';
import { Reservation } from './reservation';

export class RoomWs {
  id: number;
  name: string;
  description: string;
  capacityMax: string;
  capacityMin: string;
  floor: Floor;
  imageUrl: string;
  equipment: EquipementWs[];
  hasIncidence: boolean;
  reservations: Reservation[];
  serverDate: any;
  conferenceCode: string;
  lunchStartingTime: number;
  lunchEndingTime: number;
  enabled: boolean;
  type: RoomType;
  headquartersId: number;

  constructor(
    name: string,
    description: string,
    capacityMax: string,
    capacityMin: string,
    imageUrl: string,
    hasIncidence?: boolean,
    floor?: Floor,
    id?: number,
    serverDate?: any,
    conferenceCode?: string,
    lunchStartingTime?: number,
    lunchEndingTime?: number,
    enabled?: boolean,
    type?: RoomType,
    headquartersId?: number
  ) {
    this.name = name;
    this.description = description;
    this.capacityMax = capacityMax;
    this.capacityMin = capacityMin;
    this.imageUrl = imageUrl;
    this.headquartersId = headquartersId;

    if (id) {
      this.id = id;
    }

    if (hasIncidence) {
      this.hasIncidence = hasIncidence;
    } else {
      this.hasIncidence = false;
    }

    if (floor) {
      this.floor = floor;
    } else {
      let building = new Building(0, '');
      this.floor = new Floor(0, '', building);
    }

    if (serverDate) {
      if (typeof serverDate == 'number') {
        this.serverDate = new Date(serverDate as number);
      } else {
        this.serverDate = serverDate;
      }
    } else {
      this.serverDate = new Date();
    }

    if (conferenceCode) {
      this.conferenceCode = conferenceCode;
    }

    if (lunchStartingTime) {
      this.lunchStartingTime = lunchStartingTime;
    } else {
      this.lunchStartingTime = new Date().getTime();
    }

    if (lunchEndingTime) {
      this.lunchEndingTime = lunchEndingTime;
    } else {
      this.lunchEndingTime = new Date().getTime();
    }

    if (enabled) {
      this.enabled = enabled;
    } else {
      this.enabled = true;
    }

    if (type) {
      this.type = type;
    } else {
      this.type = new RoomType(1, '');
    }

    this.reservations = [];
    this.equipment = [];
  }
}
