import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import {
  SERVICE_BASE_URL,
  HOLIDAY_DATA_API_PATH,
} from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getConfigurationDays(headquarterId: number): Observable<any> {
    const url = `${SERVICE_BASE_URL}${HOLIDAY_DATA_API_PATH}${headquarterId}?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
