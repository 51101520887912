import { Component, OnInit } from '@angular/core';
import { AttendanceService } from '../../shared/services/attendance.service';

@Component({
  selector: 'app-assistance-iberdrola-user',
  templateUrl: './assistance-iberdrola-user.component.html',
  styleUrls: ['./assistance-iberdrola-user.component.scss'],
})
export class AssistanceIberdrolaUserComponent implements OnInit {
  listAttendancesUser: any;

  constructor(private attendanceService: AttendanceService) {}

  ngOnInit() {
    this.getAttendancesUsers();
  }

  getAttendancesUsers() {
    this.attendanceService.getListAttendancesUserManager().subscribe((res) => {
      if (res) {
        this.listAttendancesUser = res;
      }
    });
  }
}
