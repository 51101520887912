import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '../../core/models/message';
import { DICTIONARY } from '../../core/models/messages/dictionary';
import { MESSAGES } from '../../core/models/messages/messages';
import { DICTIONARY as DICTIONARYByClient } from '../../core/models/messagesByClient/dictionary';
import { MESSAGES as MESSAGESByClient } from '../../core/models/messagesByClient/messages/messages';

@Pipe({
  name: 'messagesPipe',
})
export class MessagesPipe implements PipeTransform {
  messages: Message[];
  messagesByClient: Message[];
  messagesDefault: Message[];
  messagesDefaultByClient: Message[];

  constructor() {
    this.messagesDefault = MESSAGES;
    this.messagesDefaultByClient = MESSAGESByClient;
    let messageLocales: any;
    let messageLocalesByClient: any;

    const languageDefault = localStorage.getItem('language_app');

    if (languageDefault) {
      messageLocales = DICTIONARY.filter((messageLanguage) => {
        return messageLanguage.locale === languageDefault;
      });
      messageLocalesByClient = DICTIONARYByClient.filter((messageLanguage) => {
        return messageLanguage.locale === languageDefault;
      });
    } else {
      messageLocales = DICTIONARY.filter((messageLanguage) => {
        return messageLanguage.locale === navigator.language;
      });
      messageLocalesByClient = DICTIONARYByClient.filter((messageLanguage) => {
        return messageLanguage.locale === navigator.language;
      });
    }

    if (messageLocales && messageLocales.length > 0) {
      this.messages = messageLocales[0].messages;
    } else {
      this.messages = this.messagesDefault;
    }

    if (messageLocalesByClient && messageLocalesByClient.length > 0) {
      this.messagesByClient = messageLocalesByClient[0].messages;
    } else {
      this.messagesByClient = this.messagesDefaultByClient;
    }
  }

  transform(key: string, params?: string[]): string {
    let messageValue = this.getByKey(this.messages, key, params);
    let messageValueByClient = this.getByKey(
      this.messagesByClient,
      key,
      params
    );
    if (messageValueByClient.length > 0) {
      return messageValueByClient;
    } else if (messageValue.length > 0) {
      return messageValue;
    } else {
      if (this.messages != this.messagesDefaultByClient) {
        return this.getByKey(this.messagesDefaultByClient, key, params);
      } else if (this.messages != this.messagesDefault) {
        return this.getByKey(this.messagesDefault, key, params);
      } else {
        return messageValue;
      }
    }
  }

  // Language modification for the mobility part
  setLanguage(value: string) {
    let messageLocales = DICTIONARY.filter((messageLanguage) => {
      return messageLanguage.locale == value;
    });

    let messageLocalesByClient = DICTIONARYByClient.filter(
      (messageLanguage) => {
        return messageLanguage.locale == value;
      }
    );

    if (messageLocalesByClient && messageLocalesByClient.length > 0) {
      this.messagesByClient = messageLocales[0].messages;
    } else {
      this.messagesByClient = this.messagesDefaultByClient;
    }
    if (messageLocales && messageLocales.length > 0) {
      this.messages = messageLocales[0].messages;
    } else {
      this.messages = this.messagesDefault;
    }
  }

  private getByKey(
    messages: Message[],
    key: string,
    params?: string[]
  ): string {
    let messageValue;

    let messagesReturned = messages.filter((message) => {
      return message.key == key;
    });

    if (messagesReturned && messagesReturned.length > 0) {
      messageValue = messagesReturned[0].value;

      if (params && params.length > 0) {
        params.forEach((param, index) => {
          if (messageValue.indexOf(`{${index}}`) >= 0) {
            messageValue = messageValue.replace(`{${index}}`, param);
          }
        });
      }

      return messageValue;
    } else {
      return '';
    }
  }
}
