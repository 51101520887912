import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeResumeModel } from '../../../core/models/tree.resume.model';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { TreeService } from '../../../shared/services/tree.service';

export const TypeStatus = {
  '001': {
    code: '001',
    id: 1,
    name: 'Iniciada',
    translate: 'question-and-answers_result_init',
  },
  '002': {
    code: '002',
    id: 2,
    name: 'Finalizada',
    translate: 'question-and-answers_result_finish',
  },
  '003': {
    code: '003',
    id: 3,
    name: 'Finalizada administración',
    translate: 'question-and-answers_result_finish_admin',
  },
};

@Component({
  selector: 'app-question-and-answers-result',
  templateUrl: './question-and-answers-result.component.html',
  styleUrls: ['./question-and-answers-result.component.scss'],
})
export class QuestionAndAnswersResultComponent implements OnInit {
  type = TypeStatus;

  resumeTreeStage: TreeResumeModel = null;
  treeUserLdapIdActive: number = null;

  constructor(
    private treeService: TreeService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      if (res && res.id) {
        this.treeUserLdapIdActive = res.id;
        this.getResumeTreeStage(parseInt(res.id));
      }
    });
  }

  getResumeTreeStage(id) {
    this.spinnerService.show();
    this.treeService.getResumeTreeStage(id).subscribe({
      next: (response) => {
        if (response) {
          this.resumeTreeStage = response;
        } else {
          this.resumeTreeStage = null;
        }
      },
      error: () => {
        this.resumeTreeStage = null;
      },
      complete: () => {
        this.spinnerService.hide();
      },
    });
  }

  donwloadFileUrl(url, fileName) {
    this.treeService.downloadFile(url).subscribe((res) => {
      if (res) {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(res.blob());
        a.download = fileName;
        a.click();
      }
    });
  }

  navigate() {
    this.router.navigate(['menu/questionsAndAnswers/list']);
  }
}
