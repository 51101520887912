<div *ngIf="permissions?.allowParking && !permissions?.disableReservation">
  <h3>{{ 'home_parking_title' | messagesPipe }}</h3>
  <div class="parking" *ngIf="parking != null">
    <div class="info">
      <span
        class="code"
        *ngIf="
          parking.parkingCodTitle &&
          parking.parkingCodTitle.length > 0 &&
          permissions.showParkingSpotInfo">
        {{ parking.parkingCodTitle }}
        <span>{{ parking.Parking_plaza_planta }}</span>
        <span class="text">{{ parking.parkingTipoTitle }}</span>
      </span>
      <span
        class="code"
        *ngIf="!permissions.showParkingSpotInfo">
        {{ parking.parkingTipoTitle }}
      </span>
    </div>
  </div>
</div>

<div *ngIf="permissions?.disableReservation">
  <h3>{{ 'home_parking_title' | messagesPipe }}</h3>
  <div class="disable-reservation">
    <label> {{ 'parking_disabled_reservation_title' | messagesPipe }}</label>
    <span>
      {{ 'parking_disabled_reservation_description' | messagesPipe }}</span
    >
  </div>
</div>
