import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { StewardService } from '../../shared/services/steward.service';
import { StewardUrlHelperService } from './steward.service';

@Component({
  selector: 'app-steward',
  templateUrl: './steward.component.html',
  styleUrls: ['./steward.component.scss'],
})
export class StewardComponent implements OnInit {
  @ViewChild('frameUrl') iframe: ElementRef;
  messagesPipe = new MessagesPipe();
  user: UserLdapWs;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private stewardService: StewardService,
    private stewardUrlHelperService: StewardUrlHelperService
  ) {}

  ngOnInit(): void {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_steward')
    );
    this.getUrlSteward();
  }

  getUrlSteward() {
    this.stewardService.getUrlSteward().subscribe((res) => {
      this.stewardUrlHelperService.get(res).subscribe((blob) => {
        this.iframe.nativeElement.src = blob;
      });
    });
  }
}
