import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AttendanceService } from 'src/app/shared/services/attendance.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import {
  STATUS_CONFLICT,
  STATUS_NOT_ACCEPTABLE,
} from '../../../core/constants/const';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-assign-place',
  templateUrl: './assign-place.component.html',
  styleUrls: ['./assign-place.component.scss'],
})
export class AssignPlaceComponent implements OnInit {
  assignPlaceForm: FormGroup;
  messagesPipe = new MessagesPipe();

  constructor(
    private attendanceService: AttendanceService,
    private flashMessagesService: FlashMessagesService,
    private sideModalService: SideModalService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.assignPlaceForm = new FormGroup({
      enrollment: new FormControl('', [Validators.required]),
    });
  }

  save() {
    const license = this.assignPlaceForm.controls['enrollment'].value;
    this.attendanceService
      .assignPlace(license)
      .pipe(
        finalize(() => {
          this.sideModalService.closeSideModal();
        })
      )
      .subscribe(
        (response) => {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('security_search_assign_place_ok'),
            {
              cssClass: 'alert-error',
              timeout: 3000,
            }
          );
        },
        (error) => {
          let message = '';
          if (error.code === STATUS_NOT_ACCEPTABLE) {
            message = this.messagesPipe.transform(
              'security_search_found_vehicle_error'
            );
          } else if (error.code === STATUS_CONFLICT) {
            message = this.messagesPipe.transform(
              'security_search_headquarters_vehicle_error'
            );
          } else {
            message = this.messagesPipe.transform(
              'security_search_assign_place_error'
            );
          }
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(message, {
            cssClass: 'alert-error',
            timeout: 3000,
          });
        }
      );
  }
}
