import {
  Directive,
  EventEmitter,
  ElementRef,
  Output,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  @Output('clickOutside') clickOutside: EventEmitter<void> = new EventEmitter();
  squareMatrix: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  sub: Subscription;
  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit() {
    const isIEOrEdge = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
    if (!isIEOrEdge) {
      setTimeout(() => {
        this.sub = fromEvent(window, 'click').subscribe((e: MouseEvent) => {
          this.populateMatrix();
          if (!this.checkIfClickOnSquare(e.clientX, e.clientY)) {
            this.clickOutside.emit();
          }
        }, error => {
          console.error(error);
        });
      }, 100);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private populateMatrix() {
    const {
      x,
      y,
      width,
      height
    } = this._elementRef.nativeElement.getBoundingClientRect();

    this.squareMatrix = {
      x1: x,
      y1: y,
      x2: x + width,
      y2: y + height
    };
  }

  private checkIfClickOnSquare(currentX, currentY): boolean {
    return (
      currentX > this.squareMatrix.x1 &&
      currentX < this.squareMatrix.x2 &&
      currentY > this.squareMatrix.y1 &&
      currentY < this.squareMatrix.y2
    );
  }
}
