import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../../../environments/environment';
import {
  TYPE_CODE_VEHICLE,
  TYPE_CODE_VEHICLE_RESERVE,
} from '../../../../../core/constants/consts';
import { SideModalService } from '../../../../../views/side-modal/side-modal.service';
import { MessagesPipe } from '../../../../pipes/messages.pipe';
import { AttendanceService } from '../../../../services/attendance.service';
import { BaseService } from '../../../../services/base.service';
import { FlashMessagesService } from '../../../../services/flash-messages.service';
import { ModalAcceptDialogService } from '../../../modal-accept-dialog/modal-accept-dialog.service';
import { CalendarEditConfirmService } from './calendar-edit-confirm.service';
declare var $: any;

@Component({
  selector: 'app-calendar-edit-confirm',
  templateUrl: './calendar-edit-confirm.component.html',
  styleUrls: ['./calendar-edit-confirm.component.scss'],
})
export class CalendarEditConfirmComponent implements OnInit {
  assistanceSelected: any;
  assistanceInfo: any;
  shiftPriority: any;
  mainVehicle: any; // Tambien se puede utilizar getMainVehicule()
  typeVehicle: any;
  showParkingSpotInfo: any;
  attendanceIntegrationParking: any;
  attendanceIntegrationDraw: any;
  attendanceIntegrationDinnerDesk: any;
  userSelect: any;

  messagesPipe = new MessagesPipe();
  assistanceForm: FormGroup;
  turnOK = false;

  buttonDisabledRequestParking = false;
  buttonDisabledRequestDinner = false;

  enableSelectDinner: boolean = false;
  enableButtonCancelDinner: boolean = false;

  enableSelectParking: boolean = false;
  enableModuleParking: boolean = false;
  isWaitingParking: boolean = false;

  parkingVehicles: any[];
  editingVehicle: boolean = false;
  buttonDisabledConfirm = false;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private router: Router,
    private calendarEditConfirmService: CalendarEditConfirmService,
    private modalAcceptDialogService: ModalAcceptDialogService,
    private sideModalService: SideModalService
  ) {
    this.calendarEditConfirmService.cancelAssistance =
      this.cancelAssistance.bind(this);
    this.calendarEditConfirmService.cancelAssistancePlace =
      this.liberateParkig.bind(this);
    this.calendarEditConfirmService.cancelAssistanceTurn =
      this.cancelTurn.bind(this);

    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      let {
        assistanceSelected,
        assistanceInfo,
        shiftPriority,
        mainVehicle,
        typeVehicle,
        showParkingSpotInfo,
        attendanceIntegrationParking,
        attendanceIntegrationDraw,
        attendanceIntegrationDinnerDesk,
        userSelect,
        parkingVehicles,
      } = this.router.getCurrentNavigation().extras.state;
      this.assistanceSelected = assistanceSelected;
      this.assistanceInfo = assistanceInfo;
      this.shiftPriority = shiftPriority;
      this.mainVehicle = mainVehicle;
      this.typeVehicle = typeVehicle;
      this.showParkingSpotInfo = showParkingSpotInfo;
      this.attendanceIntegrationParking = attendanceIntegrationParking;
      this.attendanceIntegrationDraw = attendanceIntegrationDraw;
      this.attendanceIntegrationDinnerDesk = attendanceIntegrationDinnerDesk;
      this.userSelect = userSelect;
      this.parkingVehicles = parkingVehicles;
    }
  }

  ngOnInit() {

    this.assistanceForm = this.formBuilder.group({
      turn: [this.shiftPriority ? this.shiftPriority : '0'],
      placeParking: [
        this.getMainVehicule()?.typeId ? this.getMainVehicule()?.typeId : '0',
      ],
    });

    if (
      this.assistanceForm &&
      parseInt(this.assistanceForm.controls['turn'].value, 10) > 0
    ) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }

    this.getMessageDinner();
    this.getMessageVehicule();
  }

  // CALL API

  cancelAssistance() {
    this.attendanceService
      .cancelAssistance(this.assistanceSelected.assignments.id, this.userSelect)
      .subscribe(
        (res) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_cancel_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_ko')
          );
          this.closeForm();
        }
      );
  }

  cancelTurn() {
    this.attendanceService
      .cancelDinnerDesk(this.assistanceInfo.dinner.id, this.userSelect)
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform('assistance_text_cancel_dinner_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_dinner_ok')
          );
          this.closeForm();
        }
      );
  }

  solictTurn() {
    this.buttonDisabledRequestDinner = true;
    const optionSelected = this.assistanceForm.controls['turn'].value;
    const assistanceSelect = this.assistanceInfo.dinner.availableTurns.filter(
      (element) => {
        return parseInt(element.id, 10) === parseInt(optionSelected, 10);
      }
    );
    const request = {
      day: this.assistanceInfo.attendance.day,
      availableTurns: {
        shiftFrom:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftFrom
            : '',
        shiftTo:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftTo
            : '',
      },
      userId: this.userSelect,
    };
    this.attendanceService.requestDinnerDesk(request).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_dinner_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_dinner_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestDinner = false;
      }
    );
  }

  liberateParkig() {
    this.attendanceService
      .liberateParking(
        this.assistanceSelected.assignments.parking.assignmentId,
        this.userSelect
      )
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'assistance_text_liberate_parking_ok'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_liberate_parking_ko')
          );
          this.closeForm();
        }
      );
  }

  solictParking() {
    this.buttonDisabledRequestParking = true;
    const optionSelected = $(
      'select[name="placeParking"] option:selected'
    ).val();
    const solict = {
      day: moment(this.assistanceInfo.attendance.day, 'DD/MM/YYYY').format(
        'DD/MM/YYYY'
      ),
      parking: {
        assignmentId: null,
        hasParking: false,
        parkingTypeId: optionSelected
          ? parseInt(optionSelected, 10)
          : this.getMainVehicule()?.typeId,
      },
      userId: this.userSelect,
    };
    this.attendanceService.solictParking(solict).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_parking_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
          this.router.navigate(['menu', 'assistance']);
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_parking_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestParking = false;
      }
    );
  }

  cancelWaitingList(day: string) {
    this.attendanceService.cancelWaitingList(day, this.userSelect).subscribe(
      (res) => {
        this.flashMessagesService.show(
          this.messagesPipe.transform(
            'assistance_text_liberate_waiting_list_ok'
          ),
          { cssClass: 'alert-success', timeout: 3000 }
        );
        this.closeForm();
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform(
            'assistance_text_liberate_waiting_list_ko'
          )
        );
        this.closeForm();
      }
    );
  }

  // ACTION BUTTON

  liberateOrSolictParking(value: any) {
    if (value) {
      const assist = {
        parking: {
          hasParking: true,
        },
      };
      this.modalAcceptDialogService.openModal(assist);
    } else {
      this.solictParking();
    }
  }

  confirmCancelAsistTurn() {
    const assist = {
      dinner: {
        hasReservation: true,
      },
    };
    this.modalAcceptDialogService.openModal(assist);
  }

  confirmCancelAssistance() {
    this.modalAcceptDialogService.openModal(this.assistanceInfo);
  }

  //CHANGE AND SELECT

  changeTurn(value: any) {
    if (parseInt(value, 10) > 0) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  // MESSAGES AND ICON

  getMessageVehicule() {
    if (
      this.assistanceInfo?.draw?.attendanceUserDraw &&
      this.assistanceInfo?.draw?.attendanceUserDraw?.userSelectVehicle
    ) {
      switch (
      this.assistanceInfo.draw?.attendanceUserDraw.userSelectVehicle.code
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_scooter');

        case TYPE_CODE_VEHICLE_RESERVE.CombustionCar:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_gas');

        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_gas');

        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_electric');

        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_electric');

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          return this.messagesPipe.transform('parking_lot_drawing_scooter');

        case TYPE_CODE_VEHICLE_RESERVE.bike:
          if (this.assistanceInfo?.draw?.executedDraw) {
            return this.messagesPipe.transform('parking_lot_drawing_generic_raffle_made');
          }
          else {
            return this.messagesPipe.transform('parking_lot_drawing_scooter');
          }
        default:
          return this.messagesPipe.transform('parking_lot_no_drawing');
      }
    }
  }

  getMessageVehiculeResult() {
    if (this.assistanceInfo.draw?.assignmentAttendance?.parkingSpot) {

      let parkingSpot = "";

      parkingSpot =
        this.assistanceInfo?.draw?.assignmentAttendance?.parkingSpot.name +
        ', ' +
        this.assistanceInfo?.draw?.assignmentAttendance?.parkingSpot.floorName;

      switch (
      this.assistanceInfo.draw?.assignmentAttendance?.parkingSpot?.typeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return (
            this.messagesPipe.transform('parking_lot_assignment_scooter') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return (
            this.messagesPipe.transform('parking_lot_assignment_gas') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return (
            this.messagesPipe.transform('parking_lot_assignment_electric') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return (
            this.messagesPipe.transform('parking_lot_assignment_electric') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return (
            this.messagesPipe.transform('parking_lot_assignment_scooter') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.bike:
          return this.messagesPipe.transform('parking_lot_drawing_scooter_assignment') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
            ;
        default:
          return this.messagesPipe.transform('parking_lot_no_drawing');
      }
    }
  }

  getMessageVehiculeResultRecharge() {
    if (this.assistanceInfo.draw?.assignmentAttendanceRecharge?.parkingSpot) {

      let parkingSpot = "";
      let temporalParkingSpotUsage = "";

      const datePipe: DatePipe = new DatePipe('es-ES');

      const fechadateFrom = datePipe.transform(this.assistanceInfo.draw?.assignmentAttendanceRecharge?.dateFrom, 'H:mm');

      const fechadateTo = datePipe.transform(this.assistanceInfo.draw?.assignmentAttendanceRecharge?.dateTo, 'H:mm');


      if (fechadateFrom > "0:00" && fechadateTo < "23:59") {
        temporalParkingSpotUsage =
          this.messagesPipe.transform('place_reserve_from') + fechadateFrom +

          this.messagesPipe.transform('place_reserve_to') + fechadateTo;
      }

      parkingSpot =
        this.assistanceInfo?.draw?.assignmentAttendanceRecharge?.parkingSpot.name +
        ', ' +
        this.assistanceInfo?.draw?.assignmentAttendanceRecharge?.parkingSpot.floorName +
        temporalParkingSpotUsage
        ;

      switch (
      this.assistanceInfo.draw?.assignmentAttendanceRecharge?.parkingSpot
        ?.typeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return (
            this.messagesPipe.transform('parking_lot_assignment_scooter') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return (
            this.messagesPipe.transform('parking_lot_assignment_gas') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return (
            this.messagesPipe.transform('parking_lot_assignment_electric') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return (
            this.messagesPipe.transform('parking_lot_assignment_electric') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return (
            this.messagesPipe.transform('parking_lot_assignment_scooter') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
          );
        case TYPE_CODE_VEHICLE_RESERVE.bike:
          return this.messagesPipe.transform('parking_lot_drawing_scooter_assignment') +
            ', ' +
            this.messagesPipe.transform('parking_lot') +
            ': ' +
            parkingSpot
            ;

        default:
          return this.messagesPipe.transform('parking_lot_no_drawing');
      }
    }
  }

  getMessageAdditionalParking() {
    switch (true) {
      case !this.assistanceInfo?.parking?.hasVehicle:
        return this.messagesPipe.transform(
          'assistance_text_not_assign_parking_text_alert'
        );
      case !this.assistanceInfo?.parking?.parkingSizeAttendance:
        return this.messagesPipe.transform(
          'assistance_text_not_parking_space_available'
        );
      default:
        break;
    }
  }

  getMessageDinner() {
    switch (true) {
      case !this.checkDinnerDays(this.assistanceSelected?.indexWeek) ||
        !this.checkDinnerMonths(this.assistanceSelected?.dateComplete):
        this.enableSelectDinner = false;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform(
          'assistance_text_not_dining_room_service'
        );

      case this.assistanceInfo?.dinner?.id &&
        this.assistanceInfo?.dinner?.hasReservation:
        if (
          this.checkDinnerDays(this.assistanceSelected?.indexWeek) &&
          this.checkDinnerMonths(this.assistanceSelected?.dateComplete) &&
          !this.assistanceSelected.disabledWhitAssistance
        ) {
          this.enableButtonCancelDinner = true;
        } else {
          this.enableButtonCancelDinner = false;
        }
        this.enableSelectDinner = false;
        return this.messagesPipe.transform('assistance_text_assign_dinner');

      case this.checkDinnerDays(this.assistanceSelected?.indexWeek) &&
        this.checkDinnerMonths(this.assistanceSelected?.dateComplete) &&
        !this.assistanceInfo?.dinner?.id &&
        !this.assistanceInfo?.dinner?.hasReservation &&
        this.assistanceInfo?.dinner?.availableTurns.length > 0 &&
        !this.assistanceSelected.disabledWhitAssistance:
        this.enableSelectDinner = true;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform(
          'assistance_text_available_dinner_space'
        );

      default:
        this.enableSelectDinner = false;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform('assistance_text_not_assign_dinner');
    }
  }

  activeIconParking() {
    return this.assistanceInfo &&
      this.assistanceInfo.draw &&
      this.assistanceInfo.draw.attendanceUserDraw &&
      this.assistanceInfo.draw.attendanceUserDraw.userSelectVehicle
      ? ` active`
      : ``;
  }

  activeIconDinner() {
    return this.assistanceInfo &&
      this.assistanceInfo.dinner &&
      this.assistanceInfo.dinner.hasReservation &&
      this.assistanceInfo.dinner.id
      ? ` active`
      : ``;
  }

  activeButtonParking() {
    if (
      this.assistanceInfo &&
      this.assistanceInfo.draw &&
      this.assistanceInfo.draw.executedDraw === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  getIconVehicule() {
    if (
      this.assistanceInfo.draw?.attendanceUserDraw &&
      this.assistanceInfo.draw?.attendanceUserDraw.userSelectVehicle
    ) {
      switch (
      this.assistanceInfo.draw?.attendanceUserDraw.userSelectVehicle.code
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.CombustionCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.bike:
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    }
  }

  getIconVehiculeResult() {
    if (this.assistanceInfo.draw?.assignmentAttendance?.parkingSpot) {
      switch (
      this.assistanceInfo.draw?.assignmentAttendance?.parkingSpot?.typeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.bike:
          return 'vdp-calendarParkingmotoQueue';
        default:
          return 'vdp-parking_big';
      }
    }
  }

  getIconVehiculeResultRecharge() {
    if (this.assistanceInfo.draw?.assignmentAttendanceRecharge?.parkingSpot) {
      switch (
      this.assistanceInfo.draw?.assignmentAttendanceRecharge?.parkingSpot
        ?.typeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return 'vdp-calendarParkingCharge';

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.bike:
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    }
  }

  // FUNCTION UTILS

  checkShiftPriority(value: any) {
    const turn = this.assistanceForm.controls['turn'].value;
    let res = true;
    value?.forEach((element) => {
      if (element.id === turn) {
        res = false;
      }
    });

    if (res) {
      this.assistanceForm.controls['turn'].setValue('0');
      this.turnOK = false;
    }

    return res;
  }

  parkingSizeAttendanceEnable(assistance) {
    if (Array.isArray(assistance?.parking?.parkingSizeAttendance)) {
      return assistance?.parking?.parkingSizeAttendance?.find((x) => x.enabled);
    } else if (assistance?.parking?.parkingSizeAttendance?.enabled) {
      return true;
    }
  }

  getMainVehicule() {
    if (Array.isArray(this.assistanceInfo?.parking?.parkingSizeAttendance)) {
      return this.assistanceInfo?.parking?.parkingSizeAttendance?.find(
        (x) => x.main
      );
    } else if (this.assistanceInfo?.parking?.parkingSizeAttendance?.main) {
      return this.assistanceInfo?.parking?.parkingSizeAttendance;
    }
  }

  closeForm() {
    this.assistanceForm.reset();
    this.buttonDisabledRequestDinner = false;
    this.buttonDisabledRequestParking = false;
    this.sideModalService.closeSideModal();
  }

  // CHECK AND DISABLED

  checkSelectedDisabled() {
    const select = $('#placeParking').val();
    return select == 0 ? true : false;
  }

  checkDinnerDays(indexWeek) {
    let hasDinner = false;
    const dinnerDays = environment['dinnerDays'];
    if (dinnerDays) {
      dinnerDays.forEach((element) => {
        if (element == indexWeek) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkDinnerMonths(day) {
    const date = moment(day, 'DD-MM-YYYY');
    const month = date.month() + 1;
    let hasDinner = false;
    const dinnerMonths = environment['dinnerMonths'];
    if (dinnerMonths) {
      dinnerMonths.forEach((element) => {
        if (element == month) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  statusModuleParking() {
    if (this.attendanceIntegrationDraw && this.assistanceInfo?.draw) {
      return (this.enableModuleParking = true);
    } else {
      this.enableModuleParking = false;
      return (this.enableModuleParking = false);
    }
  }

  editVehicle() {
    if (this.editingVehicle === false) {
      this.editingVehicle = true;
    } else if (this.editingVehicle === true) {
      this.editingVehicle = false;
    }
  }

  objectKeys(objeto: any) {
    const values = objeto ? Object.values(objeto) : '';
    return values;
  }

  // CHECK
  confirmAssistance() {
    this.buttonDisabledConfirm = true;
    const attendancesSave = [];

    if (this.assistanceForm.value.placeParking) {
      const vehicleTypeId = this.assistanceForm.value.placeParking;
      const drawId = this.assistanceInfo.attendance.id;
      const userId = this.userSelect;

      this.update(vehicleTypeId, drawId, userId);
    }
  }

  update(vehicleTypeId: number, drawId: number, userId: number) {
    this.attendanceService.updateDraw(drawId, userId, vehicleTypeId).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_confirm_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_update_error')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledConfirm = false;
      }
    );
  }
}
