<div class="container__customExternal">
  <div class="container__customExternal__header">
    <h1
      class="container__customExternal__header--title"
      *ngIf="isAndroid() || isIOS()"
    >
      {{ externalModule?.name }}
    </h1>
    <span class="container__customExternal__header--extract">
      {{ externalModule?.extracto }}
    </span>
  </div>
  <div
    class="container__customExternal__file"
    *ngIf="externalModule?.files && externalModule?.files.length > 0"
  >
    <h3 class="container__customExternal__file--title">
      {{ externalModuleFilesString }}
    </h3>
    <div class="card">
      <a
        class="card--item"
        *ngFor="let file of externalModule?.files"
        [href]="getDownloadUrl(file.file)"
        target="_blank"
      >
        <i class="vdp-icons_file"></i>
        <span>{{ file.title }}</span>
      </a>
    </div>
  </div>
  <div
    class="container__customExternal__app"
    *ngIf="externalModule?.apps && externalModule?.apps.length > 0"
  >
    <h2 class="container__customExternal__app--title">
      {{ externalModuleAppsString }}
    </h2>
    <div class="card">
      <a
        class="card--item"
        *ngFor="let app of externalModule?.apps"
        [href]="getAppUrl(app)"
        target="_blank"
      >
        <i class="vdp-icons_external"></i>
        <span>{{ app.title }}</span>
      </a>
    </div>
  </div>
  <div
    class="container__customExternal__buttom"
    *ngIf="externalModule?.incidenceIntegration"
  >
    <button type="button" (click)="goToIncidenced()">
      {{ externalModuleReportIncidenceString }} {{ externalModule?.name }}
    </button>
  </div>
</div>
