import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Department } from 'src/app/core/models/department';
import { SERVICE_BASE_URL } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable()
export class DepartmentService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getDepartmentListByHeadquarterId(token: string, id: number): Observable<any> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/department/listAll/${id}?access_token=${token}`
      )
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  findById(departmentList: Department[], id: number): Department {
    const departmentResult = departmentList.filter((deparment) => {
      return deparment.id === id;
    });

    if (departmentResult && departmentResult.length > 0) {
      return departmentResult[0];
    } else {
      return null;
    }
  }
}
