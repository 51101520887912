import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { windowTime } from 'rxjs/operator/windowTime';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { RoomListWs } from '../../core/models/room.list.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { WaitingListResponseWs } from '../../core/models/waiting.list.response.ws';
import { DetailWs } from '../../core/models/detail.ws';
import { GenericResponseBooleanWithErrorsWs } from '../../core/models/generic.response.boolean.with.errors.ws';
import { WaitingListWs } from '../../core/models/waiting.list.ws';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';

@Injectable()
export class WaitingService {
  constructor(
    private http: Http,
    protected authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  waitingListAdd(
    roomList: RoomListWs,
    token: string
  ): Observable<WaitingListResponseWs> {
    let waitingListWs = new WaitingListWs();
    waitingListWs.dateFrom = roomList.dateFrom;
    waitingListWs.dateTo = roomList.dateTo;
    waitingListWs.assistants = roomList.assistants;
    waitingListWs.reason = roomList.reason;
    waitingListWs.equipment = roomList.equipment;

    let bodyJson = JSON.stringify(waitingListWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/waiting/add?access_token=${token}`,
        bodyJson,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as WaitingListResponseWs;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  waitingListSpeedAdd(
    roomList: RoomListWs,
    token: string
  ): Observable<WaitingListResponseWs> {
    let waitingListWs = new WaitingListWs();
    waitingListWs.dateFrom = roomList.dateFrom;
    waitingListWs.dateTo = new Date();
    waitingListWs.reservationDuration = roomList.duration;
    waitingListWs.reason = roomList.reason;

    let bodyJson = JSON.stringify(waitingListWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/waiting/speed/add?access_token=${token}`,
        bodyJson,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as WaitingListResponseWs;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  list(token: string, userId: number): Observable<WaitingListWs[]> {
    let waitingListWs = new WaitingListWs();
    waitingListWs.dateFrom = new Date();

    let bodyJson = JSON.stringify(waitingListWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/waiting/list?access_token=${token}`,
        bodyJson,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as WaitingListWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancel(
    token: string,
    waiting: WaitingListWs
  ): Observable<GenericResponseBooleanWithErrorsWs> {
    let detailWs = new DetailWs(waiting.id);
    let bodyJson = JSON.stringify(detailWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/waiting/cancel?access_token=${token}`,
        bodyJson,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as GenericResponseBooleanWithErrorsWs;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  deleteWaitingFromList(
    waitingList: WaitingListWs[],
    waitingCanceled: WaitingListWs
  ): WaitingListWs[] {
    return waitingList.filter(waiting => {
      return waiting.id != waitingCanceled.id;
    });
  }
}
