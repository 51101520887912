import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from 'src/app/core/constants/consts';
import {
  slideButtons,
  slideDetailsBooking,
} from '../../shared/utils/reservation.list.animations';
import { SideModalService } from './side-modal.service';

@Component({
  selector: 'side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
  animations: [slideDetailsBooking(), slideButtons()],
})
export class SideModalComponent implements OnInit {
  private subjectClose = new Subject<any>();
  showForm: boolean = false;
  titleModal: string;
  componentActive: string;
  relativeTo: ActivatedRoute;
  outside: boolean;

  constructor(
    private sideModalService: SideModalService,
    private router: Router
  ) {
    this.sideModalService.openSideModal = this.openSideModal.bind(this);
    this.sideModalService.closeSideModal = this.closeSideModal.bind(this);
    this.sideModalService.subjectSideModal = this.subjectSideModal.bind(this);
  }

  ngOnInit(): void {
    if (this.showForm == false) {
      this.closeSideModal();
    }
  }

  openSideModal(
    nameComponent: string,
    params: any,
    title: string,
    relativeTo: ActivatedRoute,
    outside?: boolean
  ) {
    this.titleModal = title;
    this.componentActive = nameComponent;
    this.router.navigate([{ outlets: { modal: [nameComponent] } }], {
      relativeTo: relativeTo,
      state: { ...params },
    });
    this.outside = outside;
    this.showForm = true;
  }

  subjectSideModal(relativeTo: ActivatedRoute) {
    this.relativeTo = relativeTo;
    return this.subjectClose.asObservable();
  }

  closeSideModal(data?: any) {
    this.showForm = false;
    this.router.navigate([{ outlets: { modal: null } }], {
      relativeTo: this.relativeTo,
    });
    this.subjectClose.next({
      close: true,
      nameComponent: this.componentActive,
      ...data,
    });
    this.componentActive = null;
  }

  getAnimate(): string {
    if (this.showForm) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }
}
