import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Incidence } from '../../core/models/incidence';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { IncidenceService } from '../../shared/services/incidence.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { SideModalService } from '../side-modal/side-modal.service';

@Component({
  selector: 'app-incidence',
  templateUrl: './incidence.component.html',
  styleUrls: ['./incidence.component.scss'],
})
export class IncidenceComponent implements OnInit {
  listIncidence: Incidence[];
  category: any = null;
  messagesPipe = new MessagesPipe();

  constructor(
    private incidenceService: IncidenceService,
    private navigatorRefreshService: NavigatorRefreshService,
    private route: ActivatedRoute,
    private sideModalService: SideModalService
  ) {
    let messagesPipe = new MessagesPipe();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      messagesPipe.transform('menu_incidence')
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.modalShow) {
        setTimeout(() => {
          this.openSideModal();
        }, 1000);
      }
      if (params.modalShow && params.category) {
        this.category = params.category;
      }
    });
    this.getIncidence();
    this.closeFormNewIncidence();
  }

  getIncidence() {
    this.incidenceService.getIncidence().subscribe((res) => {
      if (res) {
        this.listIncidence = res;
      }
    });
  }

  closeFormNewIncidence() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res) {
        this.getIncidence();
      }
    });
  }

  openSideModal() {
    this.sideModalService.openSideModal(
      'incidenceForm',
      { categoryParam: this.category },
      this.messagesPipe.transform('title_incidence'),
      this.route,
      true
    );
  }
}
