import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateStringPipe'
})
export class DateStringPipe implements PipeTransform {
  transform(value: string, format: string = ''): string {
    if (!value || value === '') return '';
    moment();
    return moment(new Date(value)).format(format);
    // return moment(value).format(format);
  }
}
