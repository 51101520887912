<div class="reservation-results-by-room-and-period">
  <app-room-detail-header [hideCloseButton]="true" [room]="selectedRoom">
  </app-room-detail-header>

  <ul
    class="results-list"
    infinite-scroll
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScrollDown($event)"
    [scrollWindow]="false"
  >
    <li *ngFor="let result of results" class="results-list-item card">
      <div class="date">
        <span class="month">{{ result.dateFrom | customDatePipe }}</span>
        <span class="day">{{ result.dateFrom | dateStringPipe: 'DD' }}</span>
      </div>

      <div class="hour">
        <span
          >{{ 'time_str_from' | messagesPipe }}
          {{ result.dateFrom | dateStringPipe: 'HH:mm' }}</span
        >
        <span
          >{{ 'time_str_to' | messagesPipe }}
          {{ result.dateTo | dateStringPipe: 'HH:mm' }}</span
        >
      </div>

      <div class="result-list-item-action">
        <button
          [disabled]="reservationInProgress"
          type="button"
          class="btn btn-default"
          (click)="reserve(result)"
        >
          {{ 'room_available_btn' | messagesPipe }}
        </button>
      </div>
    </li>
  </ul>
</div>
