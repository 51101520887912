<div class="login">
  <div class="login-wrapper">
    <div class="login-form">
      <form
        (submit)="login(formLogin.value)"
        [formGroup]="formLogin"
        novalidate
      >
        <img src="../../assets/img/logo-login.png" alt="" />
        <div class="input-field">
          <label for="username" [ngClass]="{ active: usernameActive }">{{
            "login_username" | messagesPipe
          }}</label>
          <input
            id="username"
            type="text"
            class="form-control"
            name="username"
            maxlength="100"
            [formControl]="formLogin.controls['username']"
            (focus)="setUsernameActive()"
            (focusout)="removeUsernameActive()"
          />
          <span [hidden]="formLogin.controls.username.valid">{{
            "login_username_err" | messagesPipe
          }}</span>
          <i class="vdp-icon_usuario"></i>
        </div>

        <div class="input-field">
          <label for="password" [ngClass]="{ active: passwordActive }">{{
            "login_password" | messagesPipe
          }}</label>
          <input
            id="password"
            type="password"
            class="form-control"
            name="password"
            maxlength="100"
            [formControl]="formLogin.controls['password']"
            (focus)="setPasswordActive()"
            (focusout)="removePasswordActive()"
          />
          <span [hidden]="formLogin.controls.password.valid">{{
            "login_password_err" | messagesPipe
          }}</span>
          <i class="vdp-icon_password"></i>
        </div>

        <!-- If you want that this option appears, you must set it in its corresponding client environment -->
        <div class="reset-password" *ngIf="canResetPassword">
          <a [routerLink]="['/resetPassword']">{{ "login_forgot_password" | messagesPipe }}</a>
        </div>

        <div class="form-actions">
          <button
            *ngIf="loginBtnShow"
            type="submit"
            class="btn waves-effect waves-light"
            [disabled]="!formLogin.valid"
          >
            Acceder
          </button>
          <!-- <span *ngIf="!loginBtnShow">Cargando....</span> -->
          <span *ngIf="!loginBtnShow">
            <div class="loading">
              <div class="spinner-wrapper">
                <div class="spinner">
                  <div class="dot1"></div>
                  <div class="dot2"></div>
                </div>
              </div>
            </div>
          </span>
        </div>

        <div class="errorLogin" *ngIf="errorLogin.length > 0">
          <span>{{ errorLogin }}</span>
        </div>
      </form>
    </div>
  </div>
</div>
