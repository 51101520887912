<div class="assistance">
  <form class="mui-form" [formGroup]="assistanceForm" novalidate>
    <ng-container formArrayName="assistances">
      <ng-container
        *ngFor="let reser of assistances.controls; let index = index"
      >
        <div [formGroup]="reser">
          <span>{{ assistancesList[index]?.date | titlecase }}</span>
          <!-- PARKING -->
          <div
            class="detailAssistance"
            *ngIf="attendanceIntegrationDraw && parkingVehicles"
          >
            <!-- MESSAGE -->
            <div class="col-sm-12 text">
              <label>
                {{ getMessageParking(parkingVehicles, assistancesList[index]) }}
              </label>
              <label *ngIf="assistancesList[index].info?.draw?.userLdapGroupTypeCode === 'N2' && parkingVehicles">
                {{ getAdditionalN2Message(assistancesList[index]) }}
              </label>
            </div>
            <!-- SELECTOR -->
            <div
              class="col-sm-6"
              *ngIf="getEnableSelectParking(parkingVehicles, assistancesList[index])"
            >
              <div class="select-group">
                <label for="placeParking">{{
                  'assistance_text_available_vehicles' | messagesPipe
                }}</label>
                <select
                  id="placeParking"
                  name="placeParking"
                  class="form-control"
                  formControlName="placeParking"
                >
                  <option [value]="0">
                    {{ 'none' | messagesPipe }}
                  </option>
                  <option
                    *ngFor="let vehicle of objectKeys(parkingVehicles)"
                    [value]="vehicle?.type_id"
                  >
                    {{ vehicle?.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- DINNER -->
          <div
            class="detailAssistance"
            *ngIf="
              attendanceIntegrationDinnerDesk &&
              assistancesList[index]?.info?.dinnerDesk
            "
          >
            <!-- MESSAGE -->
            <div class="col-sm-12 text">
              <label>
                {{ getMessageDinnerDesk(assistancesList[index]) }}
              </label>
            </div>
            <!-- SELECTOR -->
            <div
              class="col-sm-6"
              *ngIf="
                checkDinnerDays(assistancesList[index]?.indexWeek) &&
                checkDinnerMonths(assistancesList[index]?.dateComplete) &&
                assistancesList[index]?.workingHome != true &&
                assistancesList[index]?.partTime != true
              "
            >
              <div class="select-group">
                <label for="turn">{{
                  'assistance_text_available_shifts' | messagesPipe
                }}</label>
                <select
                  id="turn"
                  name="turn"
                  class="form-control"
                  formControlName="turn"
                >
                  <option [value]="0">
                    {{ 'none' | messagesPipe }}
                  </option>
                  <option
                    *ngFor="
                      let turn of assistancesList[index]?.info?.dinnerDesk
                        ?.availableTurns
                    "
                    [value]="turn?.id"
                  >
                    {{ turn?.shiftFromAux }} - {{ turn?.shiftToAux }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- MESSAGE ADDITIONAL -->
          <div
            class="detailAssistance"
            *ngIf="
              attendanceIntegrationDinnerDesk &&
              !assistancesList[index]?.info?.dinnerDesk &&
              (assistancesList[index]?.workingHome ||
                assistancesList[index]?.partTime)
            "
          >
            <div class="col-sm-12 text">
              <label
                >{{ getMessageAdditionalDinner(assistancesList[index]) }}
              </label>
            </div>
          </div>
          <hr *ngIf="index + 1 != assistancesList?.length" />
        </div>
      </ng-container>
    </ng-container>
  </form>
  <!-- BUTTON SAVE -->
  <div class="btn-flotante">
    <a (click)="closeForm()">
      {{ 'cancel_reservation_btn' | messagesPipe }}
    </a>
    <a
      (click)="confirmAssistance()"
      class="reserve"
      [ngClass]="[buttonDisabledConfirm ? 'a-not-active' : '']"
    >
      {{ 'assistance_text_not_confirm' | messagesPipe }}
    </a>
  </div>
</div>
