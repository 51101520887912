<app-spinner></app-spinner>

<div class="row resource-list">
  <!-- ### Col 1: List ###  -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'resource_list_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-resource" *ngIf="resourceList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'resource_list_not_reserve' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card" *ngFor="let resource of resourceList">
      <div class="detail">
        <span class="detail--title"
          >{{ resource?.resourceTypeForHour?.name }}:
          {{ resource?.nameResourceForHour }}</span
        >
        <span class="detail--license" *ngIf="resource?.license">
          {{ 'resource_reserve_license' | messagesPipe }}:
          {{ resource?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ resource?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ resource?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ resource?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ resource?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ resource?.codeResourceForHour }}</span>

      <!-- Card Options -->
      <div class="card-options">
        <!-- Available Options -->
        <div class="available-options">
          <!-- Cancel -->
          <span
            class="available-options__cancel"
            (click)="cancelResourceForHourReservation(resource.id)"
          >
            {{ 'reservation_give' | messagesPipe }}
          </span>

          <!-- Edit -->
          <span
            class="available-options__edit"
            (click)="editResourceForHourReservation(resource)"
          >
            {{ 'reservation_edit' | messagesPipe }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- ### Col 2: History ###-->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reservation_historical_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-resource" *ngIf="resourceHistoryList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'reservation_historical_description' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div
      class="card history"
      *ngFor="let resourceHistory of resourceHistoryList"
    >
      <div class="detail">
        <span class="detail--title"
          >{{ resourceHistory?.resourceTypeForHour?.name }}:
          {{ resourceHistory?.nameResourceForHour }}</span
        >
        <span class="detail--license" *ngIf="resourceHistory?.license">
          {{ 'resource_reserve_license' | messagesPipe }}:
          {{ resourceHistory?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ resourceHistory?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ resourceHistory?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ resourceHistory?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ resourceHistory?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ resourceHistory?.codeResourceForHour }}</span>
    </div>
  </div>
</div>

<!--  ### Buttons ### -->
<div class="add-resource">
  <button
    type="button"
    class="vdp-icon-mas add"
    (click)="showButtons()"
    [ngClass]="{ closed: showOptionsButtons }"
  ></button>
  <div
    class="resource-list"
    id="resource-list"
    [@slideButtons]="getSlideButtonsClass()"
  >
    <div class="resource-list-buttons">
      <!-- Option 1 -->
      <button type="button" (click)="openReserve()">
        {{ 'resource_list_button' | messagesPipe }}
        <i class="vdp-buscar-usuario"></i>
      </button>
    </div>
  </div>
</div>

<side-modal></side-modal>
