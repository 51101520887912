import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getSensorData(token: string): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/sensor/data?access_token=${token}`;
    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getSensorDataByHeadquarterId(headquarterId: number): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/sensor/data/headquarters/${headquarterId}`;
    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
