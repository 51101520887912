<div class="modal" id="modalAcceptD" #modalAcceptD>
  <div class="modal__content">
    <div class="modal__content--title">
      <span>{{ 'assistance_dialog_confirm_title' | messagesPipe }}</span>
    </div>
    <div class="modal__content--desc">
      {{ 'assistance_dialog_confirm_text' | messagesPipe }}
      <br />
      <ul>
        <li *ngIf="assistanceInfo?.attendance?.id">
          {{ 'assistance_dialog_confirm_text_assistance' | messagesPipe }}
        </li>
        <li *ngIf="assistanceInfo?.parking?.hasParking">
          {{ 'assistance_dialog_confirm_text_assign_place' | messagesPipe }}
        </li>
        <li *ngIf="assistanceInfo?.dinner?.hasReservation">
          {{ 'assistance_dialog_confirm_text_restaurant_shift' | messagesPipe }}
        </li>
      </ul>
    </div>
    <div class="modal__content--bottom">
      <a (click)="closeModal()">{{
        'assistance_button_close' | messagesPipe
      }}</a>
      <a (click)="confirm()" class="indicate">{{
        'assistance_button_confirm' | messagesPipe
      }}</a>
    </div>
  </div>
</div>
