export const CalendarRoutes = {
  calendarEditConfirm: {
    title: 'assistance_text_confirm',
    routerName: 'calendarEditConfirm',
  },
  calendarEditNotConfirm: {
    title: 'assistance_text_not_confirm',
    routerName: 'calendarEditNotConfirm',
  },
  calendarEditListUser: {
    title: 'menu_assistance_user',
    routerName: 'calendarEditListUser',
  },
};
