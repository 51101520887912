<!-- There are available positions -->
<div *ngIf="existAvailablePositions">
  <div class="main-container-tab">
    <div class="fadeInLayer">
      <div class="positionsList row">
        <!-- Card -->
        <div
          class="available-position-card"
          *ngFor="let reservation of reservationList"
        >
          <div>
            <div class="position">{{reservation?.desk.code}}</div>
            <div class="floor">{{reservation?.desk.area.floor.building.name}}, {{reservation?.desk.area.floor.name}}, {{reservation?.desk.area.name}}</div>
            <div class="date">{{reservation?.dateTo | date}},  {{ 'time_str_from' | messagesPipe }} {{reservation?.hourFrom}}:{{reservation?.minuteFrom}} {{ 'time_str_to' | messagesPipe }} {{reservation?.hourTo}}:{{reservation?.minuteTo}}</div>
          </div>
          <div
            class="icon"
            (click)="
              viewMyPosition(
                reservation?.desk.id,
                reservation?.desk.area.id,
                reservation.dateTo
              )
            "
          >
            <i class="vdp-map"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Without available positions -->
<div *ngIf="!existAvailablePositions" class="without-positions">
  {{ 'reservation_no_available_positions' | messagesPipe }}
</div>
