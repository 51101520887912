<div class="quesito-and-answers-result__container">
  <div class="quesito-and-answers-result__container__title">
    <h1>
      {{
        type[resumeTreeStage?.treeUserLdapStatus?.code]?.translate
          | messagesPipe
      }}
    </h1>
  </div>

  <div class="quesito-and-answers-result__container__card card">
    <div
      *ngFor="let resume of resumeTreeStage?.treeUserLdapStageEventList"
      class="quesito-and-answers-result__container__card--answers"
    >
      <h2 class="title">{{ resume?.treeStage?.content }}</h2>
      <span class="result"
        >{{ 'question-and-answers_result_title' | messagesPipe }}
        {{ resume?.treeEvent.content }}</span
      >
      <span *ngIf="resume?.treeEvent?.text" class="text"
        >{{ 'question-and-answers_result_text' | messagesPipe }}
        {{ resume?.treeEvent?.text }}</span
      >
      <span *ngIf="resume?.treeEvent?.urlFile" class="file"
        >{{ 'question-and-answers_result_file' | messagesPipe }}
        <a
          class="file--content"
          (click)="
            donwloadFileUrl(
              resume?.treeEvent?.urlFile,
              resume?.treeEvent?.fileName
            )
          "
        >
          {{ resume?.treeEvent?.fileName }}</a
        ></span
      >
    </div>
  </div>
  <div class="quesito-and-answers-result__container__next">
    <button (click)="navigate()">
      {{ 'question-and-answers_result_button' | messagesPipe }}
    </button>
  </div>
</div>
