import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { CountryModel } from '../../core/models/country.ws';
import { HeadquartersListModel } from '../../core/models/headquarters.list.ws';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { ProvinceModel } from '../../core/models/province.ws';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable()
export class HeadquartersService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getHeadquarterListCountry(
    token: string,
    level: number
  ): Observable<CountryModel[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/headquarters/list?level=${level}&access_token=${token}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as CountryModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getHeadquarterListProvince(
    token: string,
    parentId?: number
  ): Observable<ProvinceModel[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/headquarters/list?parentId=${parentId}&access_token=${token}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ProvinceModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getListHeadquarter(
    token: string,
    parentId?: number
  ): Observable<HeadquartersListModel[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/headquarters/list?${
          parentId ? 'parentId=' + parentId : ''
        }&access_token=${token}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersListModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getFinalHeadquarterListAndNotUserHeadquarters(
    token: string
  ): Observable<HeadquartersWS[]> {
    let bodyString = JSON.stringify({});
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/headquarters/otherHeadquartersList?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersWS[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getFinalHeadquarterList(token: string): Observable<HeadquartersWS[]> {
    let bodyString = JSON.stringify({});
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/headquarters/finalHeadquartersList?access_token=${token}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as HeadquartersWS[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
