import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-positions',
  templateUrl: './user-positions.component.html',
  styleUrls: ['./user-positions.component.scss'],
})
export class UserPositionsComponent implements OnInit {
  messagesPipe: MessagesPipe;
  date: number;
  userId: number;
  existAvailablePositions: boolean;
  token = this.authenticationService.getToken();
  reservationList: Array<any> = [];
  url: String;
  selectOptionMenu: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private boxOffice: BoxOfficeService,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.url = this.activatedRoute.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position/') ? 'dinner' : 'desks';
    this.getParams();
    this.getAvailablePositions();
  }

  getParams(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.date = params.date;
      this.userId = params.userId;
    });
  }

  getAvailablePositions() {
    this.boxOffice
      .getReservationList(this.date, this.userId, this.token, this.selectOptionMenu)
      .subscribe(
        (data: any) => {
          if (data) {
            this.reservationList = data;
            this.reservationList.forEach((element) => {
              element.minuteFrom =
                element.minuteFrom.toString().length === 2
                  ? element.minuteFrom
                  : '0' + element.minuteFrom;
            });
            if (this.reservationList.length > 0) {
              this.existAvailablePositions = true;
            } else {
              this.existAvailablePositions = false;
            }
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'reservation_available_positions_error'
            ),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  viewMyPosition(id: number, areaId: number, date: number) {
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'selectView-position',
      id,
      areaId,
      date,
      this.token,
    ]);
  }
}
