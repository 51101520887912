import { Injectable } from '@angular/core';
import {
  Headers,
  Http,
  RequestOptions,
  ResponseContentType,
} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { DorletInfo } from '../../core/models/dorletInfo';

@Injectable()
export class UrlHelperService {
  constructor(private http: Http) {}

  get(dorletInfo: DorletInfo): Observable<any> {
    let options = new RequestOptions();
    options.headers = new Headers();
    dorletInfo?.header?.map((header) => {
      options.headers.append(header.tag, header.value);
    });
    options.responseType = ResponseContentType.Blob;

    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http.get(dorletInfo.url, options).subscribe((m) => {
        objectUrl = URL.createObjectURL(m.blob());
        observer.next(objectUrl);
      });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
}
