<div class="right-drawer" [@slideDetailsBooking]="getAnimate()">
  <button
    type="button"
    class="vdp-icon-cancelar"
    (click)="closeSideModal()"
  ></button>
  <span>{{ titleModal }}</span>

  <router-outlet name="modal"></router-outlet>
</div>
<div
  *ngIf="outside"
  [@slideButtons]="getAnimate()"
  id="outside"
  class="outside"
></div>
