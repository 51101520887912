import { Component, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';

@Component({
  selector: 'app-available-positions',
  templateUrl: './available-positions.component.html',
  styleUrls: ['./available-positions.component.scss'],
})
export class AvailablePositionsComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  dateFrom: number;
  dateTo: number;
  hotdeskNumber: number;
  hourNumber: number;
  usersList: any[];
  existAvailablePositions = false;
  token = this.authenticationService.getToken();
  buildings: any[];
  currentBuilding: any;
  currentSelection = false;
  currentReservation: any;
  optionReservationSelect = null;
  optionBuildingSelect = null;
  @ViewChildren('availablePositionCard', null) cards: any;

  url: String;
  selectOptionMenu: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private boxOffice: BoxOfficeService,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position/')
      ? 'dinner'
      : 'desks';
    this.getParams();
    this.getAvailablePositions();
    this.getParamsLocalStorage();
  }

  getParams(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.dateFrom = params.dateFrom;
      this.dateTo = params.dateTo;
      this.hotdeskNumber = params.hotdeskNumber;
      this.hourNumber = params.hourNumber;
      this.usersList = params.usersList.split(',');
    });
  }

  getAvailablePositions() {
    this.boxOffice
      .getMultipleReservationList(
        this.dateFrom,
        this.dateTo,
        this.hotdeskNumber,
        this.hourNumber,
        this.usersList,
        this.token,
        this.selectOptionMenu
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.buildings = response.buildingReservations.map((building) => {
              building.dateReservations.forEach((element) => {
                element.minuteFrom =
                  element.minuteFrom.toString().length === 2
                    ? element.minuteFrom
                    : '0' + element.minuteFrom;
                    element.minuteTo =
                  element.minuteTo.toString().length === 2
                    ? element.minuteTo
                    : '0' + element.minuteTo;
              });
              return {
                id: building.idBuilding,
                name: building.nameBuilding,
                reservations: building.dateReservations,
              };
            });

            if (this.buildings.length > 0) {
              if (this.optionBuildingSelect && this.optionReservationSelect) {
                this.buildings.forEach((element) => {
                  if (element.id === parseInt(this.optionBuildingSelect)) {
                    this.changeTab(element);
                    this.checkReservation(
                      element.reservations[
                        parseInt(this.optionReservationSelect)
                      ]
                    );
                  }
                });
              } else {
                this.changeTab(this.buildings[0]);
              }
              this.existAvailablePositions = true;
            }
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'reservation_available_positions_error'
            ),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  getParamsLocalStorage() {
    this.optionReservationSelect = localStorage.getItem(
      'optionReservationSelect'
    );
    this.optionBuildingSelect = localStorage.getItem('optionBuildingSelect');
  }

  changeTab(building: any) {
    this.currentBuilding = building;
  }

  filterPosition(hotDesktop: any[]): string {
    return hotDesktop
      .map((position) => {
        return position.codeHotdesk;
      })
      .join(', ');
  }

  filterId(hotDesktop: any[]): string {
    return hotDesktop
      .map((position) => {
        return position.idHotdesk;
      })
      .join(',');
  }

  confirmReservation(
    event: any,
    reservation: any,
    idOptionBuildingSelect: number,
    idOptionReservationSelect: number
  ): void {
    this.cards._results.forEach((element) => {
      element.nativeElement.classList = 'available-position-card';
    });

    this.optionReservationSelect = idOptionReservationSelect;
    this.optionBuildingSelect = idOptionBuildingSelect;
    localStorage.setItem(
      'optionReservationSelect',
      this.optionReservationSelect
    );
    localStorage.setItem('optionBuildingSelect', this.optionBuildingSelect);

    event.currentTarget.className += ' selected';
    this.currentSelection = true;
    this.currentReservation = reservation;
  }

  doReservation(reservation: any): void {
    const desks = reservation.deskMultiReservations[0].mapaHotDesktop.map(
      (position) => {
        return position.idHotdesk;
      }
    );

    this.boxOffice
      .addMultipleReservation(
        reservation.dateFrom,
        reservation.dateTo,
        desks,
        this.usersList,
        this.token,
        this.selectOptionMenu
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.flashMessagesService.show(
              this.messagesPipe.transform('reservation_multiple_desk_success'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }

          this.router.navigate([
            'menu',
            this.url.includes('/dinning-position/')
              ? 'dinning-position'
              : 'job-position',
          ]);
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_desk_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  showMapView(event: any, reservation: any): void {
    event.stopPropagation();

    const idList = this.filterId(
      reservation.deskMultiReservations[0].mapaHotDesktop
    );
    const areaId = reservation.deskMultiReservations[0].idArea;
    const date = reservation.dateFrom;

    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'viewMultiple-position',
      idList,
      areaId,
      date,
      this.token,
    ]);
  }

  checkReservation(reservation: any) {
    setTimeout(() => {
      document
        .getElementById(this.optionReservationSelect)
        .setAttribute('class', 'available-position-card selected');
      this.currentSelection = true;
      this.currentReservation = reservation;
    }, 50);
  }
}
