<roomWithoutTimeDetail [roomId]="room.id" [reservationDate]="date" [showCloseDetail]="false" (showModalEmitter)="showModalReservation($event)" ></roomWithoutTimeDetail>
<reservationDialog [reservation]="modalReservation" [hidden]="showModal == false" (cancelEmitter)="closeDialog()" (saveEmitter)="saveReservation($event)"></reservationDialog>
<!-- Lunch time confirmation -->
<div *ngIf="showServerLunchMsg" class="modal fade centermodal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-info">
                <span class="message">{{reservationResponse}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="backToReservationList()">{{'ok' | messagesPipe}}</button>
            </div>
        </div>
    </div>
</div>
