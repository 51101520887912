import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-check-in-with-code',
  templateUrl: './check-in-with-code.component.html',
  styleUrls: ['./check-in-with-code.component.scss'],
})
export class CheckInWithCodeComponent implements OnInit {
  checkinCodeForm: FormGroup;
  reservationId: number;
  option: string;
  token: string;
  messagesPipe = new MessagesPipe();

  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private boxOfficeService: BoxOfficeService,
    private sideModalService: SideModalService,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.reservationId =
        this.router.getCurrentNavigation().extras.state.reservationId;
      this.option = this.router.getCurrentNavigation().extras.state.option;
      this.token = this.authenticationService.getToken();
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.checkinCodeForm = new FormGroup({
      reservationId: new FormControl(this.reservationId, Validators.required),
      nfc: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
    });
  }

  checkin() {
    console.log(this.reservationId, this.checkinCodeForm.getRawValue());
    this.spinnerService.show();
    this.boxOfficeService
      .doCheckInWithNfc(
        this.reservationId,
        this.checkinCodeForm.get('nfc').value,
        this.option,
        this.token
      )
      .pipe(
        take(1),
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_checkin_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.sideModalService.closeSideModal({ checkinWithNfc: true });
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_checkin_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  close() {
    this.sideModalService.closeSideModal();
  }
}
