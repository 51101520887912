import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { CLIENT_ID, SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';

@Injectable()
export class LoginService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  login(username: string, password: string): Observable<UserLdapWs> {
    let bodyString = JSON.stringify({
      userName: username,
      password: password,
      clientId: CLIENT_ID
    });

    return this.http
      .post(`${SERVICE_BASE_URL}/web/soap/authenticate`, bodyString, OPTIONS)
      .map((response: Response) => {
        const user = response.json() as UserLdapWs;
        return user;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  loginByToken(username: string, token: string): Observable<UserLdapWs> {
    
    let params: URLSearchParams = new URLSearchParams();
    params.set('du', username);
    params.set('c', token);

    let requestOptions = new RequestOptions();
    requestOptions.search = params;

    return this.http
      .get(`${SERVICE_BASE_URL}/web/soap/validate-token?`, requestOptions)
      .map((response: Response) => {
        
        const user = response.json() as UserLdapWs;
        return user;
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  resetPassword(email: string) {
    const bodyString = JSON.stringify({email});

    return this.http
      .post(`${SERVICE_BASE_URL}/web/soap/resetPassword`, bodyString, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  validateTokenAzure(token: string, accessToken: string, user: string, app: string) {
    const bodyString = JSON.stringify({token, accessToken, user, app, clientId: CLIENT_ID});

    return this.http
      .post(`${SERVICE_BASE_URL}/web/soap/validate-token-azure`, bodyString, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
