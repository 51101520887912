<div class="modal" id="modalConfig" #modalConfig>
  <div class="modal__content">
    <div class="modal__content--title">
      <span>{{ 'indicate_your_dept_title' | messagesPipe }}</span>
    </div>
    <div class="modal__content--desc">
      {{ 'indicate_your_dept_desc' | messagesPipe }}
      <div class="row-sel mui-select">
        <label for="department">{{
          'profile_department' | messagesPipe
        }}</label>
        <select
          id="department"
          name="department"
          (change)="onChangeDept($event.target.value)"
        >
          <option
            [value]="department.id"
            *ngFor="let department of departments"
          >
            {{ department.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="modal__content--bottom">
      <a (click)="logout()">{{
        'indicate_your_dept_bt_cancel' | messagesPipe
      }}</a>
      <a (click)="indicateDept()" class="indicate">{{
        'indicate_your_dept_bt' | messagesPipe
      }}</a>
    </div>
  </div>
</div>
