import { Headquarters } from './headquarters';

export class Building {
	id:number;
	name:string;
	headquarters:Headquarters;

	constructor(id:number, name:string, headquarters?:Headquarters){
		this.id = id;
		this.name = name;

		if(headquarters) {
			this.headquarters = headquarters;
		}
	}
}
