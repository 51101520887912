import { Component, Input } from '@angular/core';
import { RoomWs } from '../../../core/models/room.ws';

@Component({
  selector: 'app-room-detail-header',
  templateUrl: 'room.detail.header.component.html',
  styleUrls: ['room.detail.header.component.scss'],
})
export class RoomDetailHeaderComponent {
  @Input() room: RoomWs;
  @Input() hideCloseButton = false;

  constructor() {}

  getAssistants(): string {
    if (this.room) {
      return `${this.room.capacityMin}/${this.room.capacityMax}`;
    } else {
      return '';
    }
  }
}
