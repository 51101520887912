import { Component, Inject } from '@angular/core';
import { APP_VERSION } from 'src/app/core/constants/const';

@Component({
  selector: 'app-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
})
export class AppVersionComponent {
  version: string;

  constructor(@Inject(APP_VERSION) private appVersion: string) {
    this.version = appVersion;
  }
}
