import { Component, OnInit } from '@angular/core';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  constructor(private navigatorRefreshService: NavigatorRefreshService) {}

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_resource')
    );
  }
}
