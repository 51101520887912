import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { RoomWs } from '../../../core/models/room.ws';
import { ReservationWs } from '../../../core/models/reservation.ws';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { RoomService } from '../../../shared/services/room.service';
import { STATUS_FORBIDDEN } from '../../../core/constants/const';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_ACTIVE_OPACITY,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY,
  LOGOUT_TIMEOUT
} from '../../../core/constants/consts';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { BaseService } from '../../../shared/services/base.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'roomWithoutTimeDetail',
  templateUrl: 'room.without.time.detail.html',
  styleUrls: ['room-withoutime.scss'],
  animations: [slideDetailsBooking()]
})
export class RoomWithoutTimeDetail implements OnInit, OnChanges, AfterViewInit {
  @Input() roomId: number;
  @Input() reservationDate: Date;
  @Input() showCloseDetail: boolean;
  @Output() showModalEmitter = new EventEmitter<ReservationWs>();
  @Output() closeDetailEmitter = new EventEmitter<any>();
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  room: RoomWs;
  hours: string[];
  datepickerOptions: any;

  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private roomService: RoomService,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService
  ) {}

  ngOnInit() {
    this.hours = this.utils.getReservationsHours();
    this.room = new RoomWs('', '', '', '', '');
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    if (this.reservationDate != null) {
      this.datepickerOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxReservationDate()
      );
      this.utils.setDatePickerDate(
        this.reservationDate,
        this.datePickerElement
      );
    }
  }

  getAssistants(): string {
    if (this.room) {
      return `${this.room.capacityMin}/${this.room.capacityMax}`;
    } else {
      return '';
    }
  }

  getClassScheduleDiv(dateIni: any, dateEnd: any) {
    return this.utils.getClassScheduleDiv(this.hours, dateIni, dateEnd,'', null, null, false);
  }

  getClassLaunchTime(): string {
    if (this.room) {
      let lunchDateIni = new Date(this.room.lunchStartingTime);
      let lunchDateEnd = new Date(this.room.lunchEndingTime);

      return `lunch start-${this.utils.getPosHour(
        this.hours,
        lunchDateIni
      )} end-${this.utils.getPosHour(this.hours, lunchDateEnd)}`;
    } else {
      return '';
    }
  }

  showModalAction(): void {
    let reservation = new ReservationWs();
    reservation.room = this.room;
    reservation.dateFrom = this.reservationDate;
    this.showModalEmitter.emit(reservation);
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0 && this.roomId != null) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
      this.getRoomDetail(this.roomId, this.reservationDate);
    } else {
      this.reservationDate = null;
    }
  }

  getRoomDetail(roomId: number, date: Date) {
    this.roomService
      .getRoomDetail(roomId, date, this.authenticationService.getToken())
      .subscribe(
        roomResponse => {
          this.room = roomResponse;
        },
        error => {
          let messagesPipe = new MessagesPipe();

          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.getRoomDetail(roomId, date);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.getRoomDetail(roomId, date);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              messagesPipe.transform('room_detail_error')
            );
          }
        }
      );
  }

  close() {
    this.closeDetailEmitter.emit();
  }

  getDetailReservationAnimateOpacity(): string {
    if (this.roomId) {
      return ANIMATE_CLASS_ACTIVE_OPACITY;
    } else {
      return ANIMATE_CLASS_INACTIVE_OPACITY;
    }
  }

  getRoomDetailAnimation(): string {
    if (this.showCloseDetail) {
      if (this.roomId != null) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    } else {
      return '';
    }
  }
}
