import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { STATUS_FORBIDDEN } from '../../../core/constants/const';
import {
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES,
  LOGOUT_TIMEOUT,
  RESERVATION_TYPE_NONE,
} from '../../../core/constants/consts';
import { EquipementWs } from '../../../core/models/equipement.ws';
import { HeadquartersWS } from '../../../core/models/headquarters.ws';
import { ReservationTypesWS } from '../../../core/models/reservation.types.ws';
import { RoomListWs } from '../../../core/models/room.list.ws';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BaseService } from '../../../shared/services/base.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { HeadquartersService } from '../../../shared/services/headquarters.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationNewFormByHQ',
  templateUrl: 'reservation.new.detail.by.hq.html',
  styleUrls: ['new-reservation.scss'],
})
export class ReservationNewDetailByHQ implements OnInit, AfterViewInit {
  equimenent: EquipementWs[];
  reservationTypes: ReservationTypesWS[];
  @ViewChild('selectHourFrom', null) selectHourFrom: any;
  @ViewChild('selectMinuteFrom', null) selectMinuteFrom: any;
  @ViewChild('selectHourTo', null) selectHourTo: any;
  @ViewChild('selectMinuteTo', null) selectMinuteTo: any;
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('datePickerMaxDate', null) datePickerMaxDate: MyDatePicker;
  @ViewChild('selectReservationTypes', null) selectReservationTypes: any;
  reservationTypeSelected: ReservationTypesWS;
  defaultReservationTypeSelected = RESERVATION_TYPE_NONE;
  reservationForm: FormGroup;
  reservationDate: Date;
  reservationMaxDate: Date;
  reservationDateFrom: string;
  reservationDateTo: string;
  equipementSelected: EquipementWs[];
  numberPattern: string;
  datepickerOptions: any;
  hoursFrom: string[];
  hoursTo: string[];
  minutesFrom = AVAILABLE_MINUTES;
  minutesTo = AVAILABLE_MINUTES;
  headquartersList: HeadquartersWS[];
  currentUser: UserLdapWs;
  messagesPipe = new MessagesPipe();
  selectedHeadquartersId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private headquartersService: HeadquartersService,
    private baseService: BaseService
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.equimenent =
        this.router.getCurrentNavigation().extras.state.equimenent;
      this.reservationTypes =
        this.router.getCurrentNavigation().extras.state.reservationTypes;
    }

    let minDate;
    let minHourIndex;
    let dateStringPipe = new DateStringPipe();

    this.equipementSelected = [];
    this.numberPattern = '^[0-9]*$';
    this.datepickerOptions = {};
    this.reservationTypeSelected = new ReservationTypesWS();

    //Remove the hours from selects
    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        dateStringPipe.transform(minDate, 'HH')
      );

      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
      this.hoursTo = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
      this.hoursTo = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    let date = new Date();

    this.reservationForm = this.formBuilder.group({
      assistants: [
        '',
        [Validators.required, Validators.pattern(this.numberPattern)],
      ],
      reason: ['', Validators.required],
    });

    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );
    this.currentUser = this.authenticationService.getCurrentUser();
    this.selectedHeadquartersId = this.currentUser.headquarters.id;
    this.getFinalHeadquarterListAndNotUserHeadquarters();

    let actualDate = new Date();
    let maxPeriodicityDate = this.authenticationService.getMaxReservationDate();

    this.reservationDate = actualDate;
    this.reservationMaxDate = maxPeriodicityDate;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let actualDate = new Date();
      let maxPeriodicityDate =
        this.authenticationService.getMaxReservationDate();

      this.utils.setDatePickerDate(actualDate, this.datePickerElement);
      this.utils.setDatePickerDate(maxPeriodicityDate, this.datePickerMaxDate);

      //Select current hours interval
      this.setPositionFrom();
      this.setPositionTo();

      if (this.reservationTypes && this.reservationTypes.length > 0) {
        this.reservationTypeSelected = this.reservationTypes.filter((type) => {
          return this.defaultReservationTypeSelected == type.id;
        })[0];
        this.selectReservationTypes.nativeElement.selectedIndex =
          this.reservationTypes.indexOf(this.reservationTypeSelected);
      }
    }, 0);
  }

  setPositionFrom(): void {
    this.reservationDateFrom = this.utils.selectHoursSetPositionFrom(
      this.authenticationService,
      this.hoursFrom,
      this.minutesFrom,
      this.selectHourFrom,
      this.selectMinuteFrom
    );
  }

  setPositionTo(): void {
    this.reservationDateTo = this.utils.selectHoursSetPositionTo(
      this.reservationDateFrom,
      this.selectHourTo,
      this.selectMinuteTo,
      this.selectHourFrom
    );
  }

  checkReservations() {
    let periodicityCorrect;
    let roomList;
    let comparePeriodicityDates;
    let timePipe = new CustomTimePipe();
    let messagesPipe = new MessagesPipe();
    let dateFrom = timePipe.transform(
      this.reservationDate,
      this.reservationDateFrom
    );
    let dateTo = timePipe.transform(
      this.reservationDate,
      this.reservationDateTo
    );
    let actualDate = new Date();
    let headquarters;

    if (this.utils.compareDates(dateFrom, actualDate) < 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_from_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else if (this.utils.compareDates(dateFrom, dateTo) >= 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_to_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else {
      if (
        this.reservationTypeSelected.id != this.defaultReservationTypeSelected
      ) {
        comparePeriodicityDates = this.utils.compareDatesWithoutTime(
          this.reservationDate,
          this.reservationMaxDate
        );

        if (comparePeriodicityDates >= 0) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            messagesPipe.transform('date_max_periodicity_greater'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
          periodicityCorrect = false;
        } else {
          periodicityCorrect = true;
        }
      } else {
        periodicityCorrect = true;
        this.reservationTypeSelected = null;
        this.reservationMaxDate = null;
      }

      if (periodicityCorrect) {
        roomList = new RoomListWs(
          dateFrom,
          dateTo,
          this.reservationForm.controls['reason'].value,
          this.reservationForm.controls['assistants'].value,
          this.equipementSelected,
          this.reservationTypeSelected,
          this.reservationMaxDate,
          null,
          this.selectedHeadquartersId
        );
        localStorage.setItem('roomFilter', JSON.stringify(roomList));

        this.router.navigate(['/menu/availableRooms']);
      }
    }
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  onDateMaxPeriodicity($event) {
    if ($event.formatted.length > 0) {
      this.reservationMaxDate = new Date();
      this.reservationMaxDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationMaxDate = null;
    }
  }

  onChangeHourFrom($event) {
    let minute = this.reservationDateFrom.split(':')[1];

    this.reservationDateFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    let hour = this.reservationDateFrom.split(':')[0];

    this.reservationDateFrom = `${hour}:${$event.target.value}`;
  }

  onChangeHourTo($event) {
    let minute = this.reservationDateTo.split(':')[1];

    this.reservationDateTo = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteTo($event) {
    let hour = this.reservationDateTo.split(':')[0];

    this.reservationDateTo = `${hour}:${$event.target.value}`;
  }

  onChangeReservationType($event) {
    let reservationId = parseInt($event.target.value);
    this.reservationTypeSelected = this.reservationTypes.filter((type) => {
      return type.id == reservationId;
    })[0];
  }

  addEquipement(equipement: EquipementWs) {
    if (this.equipementSelected.indexOf(equipement) > -1) {
      this.equipementSelected.splice(
        this.equipementSelected.indexOf(equipement),
        1
      );
    } else {
      this.equipementSelected.push(equipement);
    }
  }

  getFinalHeadquarterListAndNotUserHeadquarters() {
    this.headquartersService
      .getFinalHeadquarterListAndNotUserHeadquarters(
        this.authenticationService.getToken()
      )
      .subscribe(
        (headquarters) => {
          this.headquartersList = headquarters;
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getFinalHeadquarterListAndNotUserHeadquarters();
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getFinalHeadquarterListAndNotUserHeadquarters();
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getHeadquarters_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  selectHeadquarters($event: any) {
    this.selectedHeadquartersId = parseInt($event.target.value);
  }
}
