import { Component, OnInit } from '@angular/core';
import { ModalErrorMobileService } from './modal-error-mobile.service';

@Component({
  selector: 'app-modal-error-mobile',
  templateUrl: './modal-error-mobile.component.html',
  styleUrls: ['./modal-error-mobile.component.scss'],
})
export class ModalErrorMobileComponent implements OnInit {
  modalConfig: any = null;
  modalOpen: boolean = false;
  title: string = '';
  description: string = '';

  constructor(private modalErrorMobileService: ModalErrorMobileService) {
    this.modalErrorMobileService.show = this.show.bind(this);
    this.modalErrorMobileService.hide = this.hide.bind(this);
  }

  ngOnInit() {
    this.modalConfig = document.getElementById('modalConfigMobile');
  }

  show(title: string, description: string) {
    this.title = title;
    this.description = description;

    if (!this.modalOpen) {
      this.modalConfig.style.display = 'flex';
      window.setTimeout(() => {
        this.modalConfig.style.opacity = 1;
        this.modalOpen = true;
      }, 100);
      window.setTimeout(() => {
        this.hide();
      }, 3000);
    }
  }

  hide() {
    if (this.modalOpen) {
      this.modalConfig.style.opacity = 0;
      window.setTimeout(() => {
        this.modalOpen = false;
        this.modalConfig.style.display = 'none';
      }, 100);
    }
  }
}
