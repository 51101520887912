import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit
} from '@angular/core';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY,
  ANIMATE_CLASS_ACTIVE_OPACITY
} from '../../core/constants/consts';
import { DateStringPipe } from '../../shared/pipes/date.string.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { RoomWs } from '../../core/models/room.ws';
import { RoomDetailEmitter } from '../../core/models/room.detail.emitter';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { slideDetailsBooking } from '../../shared/utils/reservation.list.animations';
import { Utils } from '../../shared/utils/utils';

@Component({
  selector: 'roomDetail',
  templateUrl: 'room.detail.component.html',
  styleUrls: ['room-detail.scss'],
  animations: [slideDetailsBooking()]
})
export class RoomDetailComponent implements OnInit, OnChanges {
  @Input() roomDetail: RoomWs;
  @Input() dateFromInMillis: number;
  @Input() dateToInMillis: number;
  @Input() buildingName: string;
  @Input() reservationBtnDisabled: boolean;
  @Output() closeRoomDetailEmitter = new EventEmitter();
  @Output() roomReserveEmitter = new EventEmitter<RoomDetailEmitter>();
  dateFrom: Date;
  dateTo: Date;
  hours: string[];
  dateStringPipe = new DateStringPipe();
  currentUser: UserLdapWs;

  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.hours = this.utils.getReservationsHours();
  }

  ngOnChanges() {
    this.dateFrom = new Date(this.dateFromInMillis);
    this.dateTo = new Date(this.dateToInMillis);
  }

  reserveRoom() {
    let roomDetailEmmiter = new RoomDetailEmitter(
      this.roomDetail,
      this.dateFrom,
      this.dateTo
    );
    this.roomReserveEmitter.emit(roomDetailEmmiter);
  }

  getClassScheduleDiv(dateIni: any, dateEnd: any) {
    return this.utils.getClassScheduleDiv(
      this.hours,
      dateIni,
      dateEnd,
      '',
      null,
      null,
      false
    );
  }

  getClassLaunchTime(): string {
    let lunchDateIni = new Date(this.roomDetail.lunchStartingTime);
    let lunchDateEnd = new Date(this.roomDetail.lunchEndingTime);

    return `lunch start-${this.utils.getPosHour(
      this.hours,
      lunchDateIni
    )} end-${this.utils.getPosHour(this.hours, lunchDateEnd)}`;
  }

  getAssistants(): string {
    return `${this.roomDetail.capacityMin}/${this.roomDetail.capacityMax}`;
  }

  addMinimunHour(date: Date) {
    this.utils.addMinimunHour(date);
  }

  addMaximunHour(date: Date) {
    this.utils.addMaximunHour(date);
  }

  canChangeDates(dateMin: Date, dateMax: Date): boolean {
    return this.utils.canChangeDates(dateMin, dateMax, 15);
  }

  canChangeDateFrom(): boolean {
    return this.utils.canChangeDateFrom(
      this.dateFrom,
      this.roomDetail,
      this.authenticationService.getMinReservationTime()
    );
  }

  canChangeDateTo(): boolean {
    return this.utils.canChangeDateTo(this.dateTo, this.roomDetail);
  }

  closeDetail() {
    this.closeRoomDetailEmitter.emit();
  }

  getRoomDetailAnimation(): string {
    if (this.roomDetail.id != null) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  getRoomDetailAnimationOpacity(): string {
    if (this.roomDetail.id != null) {
      return ANIMATE_CLASS_ACTIVE_OPACITY;
    } else {
      return ANIMATE_CLASS_INACTIVE_OPACITY;
    }
  }
}
