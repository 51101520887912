import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: 'descargas.component.html',
  styleUrls: ['download.scss'],
})
export class DescargasComponent implements OnInit {
  androidUrl: string;
  iosUrl: any;

  trustedDashboardUrl: SafeUrl;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const messagesPipe = new MessagesPipe();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      messagesPipe.transform('menu_descargas')
    );
    this.loadUrl();
  }

  loadUrl() {
    if (environment['donwloadUrls']) {
      this.androidUrl = environment['donwloadUrls'].android;
      this.iosUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment['donwloadUrls'].ios
      );
    }
  }
}
