<div class="search-user-position">
  <!-- Content -->
  <div>
    <!-- Search People Component -->
    <app-search-people
      [serviceType]="searchType"
      [disabled]="disabledSearchPeople"
      [translationKey]="label"
      [placeHolderkey]="placeHolder"
      (onSelectedUsersChange)="getSelectedPeople($event)"
    ></app-search-people>

    <form
      class="row mui-form"
      (ngSubmit)="seePositions()"
      [formGroup]="searchUserPositionForm"
      novalidate
    >
      <!-- Date -->
      <div class="form-group select-group col-sm-12 mui-textfield">
        <label for="date">{{
          'reservation_user_position_date' | messagesPipe
        }}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerElement
          id="date"
          [options]="datepickerOptions"
          [locale]="locale"
          (dateChanged)="onDateChanged($event)"
        ></datepicker>
        <span class="required" [hidden]="date">{{
          'error_required' | messagesPipe
        }}</span>
      </div>

      <!-- Button -->
      <div class="placed-button">
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="!isFormValid()"
        >
          {{ 'reservation_user_position_see_reservations' | messagesPipe }}
        </button>
      </div>
    </form>
  </div>
</div>
