<div class="login" *ngIf="!loginDisplay">
  <div class="login-wrapper">
    <div class="login-form">
      <img src="../../assets/img/logo-login.png" alt="" />
      <p class="login-form-error" *ngIf="errorLoging">
        {{ 'login_with_azure_loading_error' | messagesPipe }}
      </p>
    </div>
  </div>
</div>
<div *ngIf="loginDisplay">
  <div class="loading" id="loading">
    <div class="login">
      <p>{{ 'login_with_azure_loading' | messagesPipe }}</p>
    </div>
    <div class="spinner-wrapper">
      <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
  </div>
</div>
