import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { Desks } from '../../core/models/desks';
import { ModalReservationService } from '../../shared/components/modal-reservation/modal-reservation.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { BoxOfficeService } from '../../shared/services/box-office.service';

@Component({
  selector: 'app-box-office',
  templateUrl: './box-office.component.html',
  styleUrls: ['./box-office.component.scss'],
})
export class BoxOfficeComponent implements OnInit {
  desks: Desks;
  token: string;
  areaId: number;
  listAttributes: DeskAttribute[];
  id: number = null;
  idList: Array<number> = null;
  date: number;
  readerDesk: boolean = false;
  viewSelect = false;
  viewMulti = false;
  timeOutApi: any;
  zoomScale = 0.3;
  onlyRead = false;
  language: string;

  // Attributes passed by messagesPipe
  box_title_legend: string;
  box_title: string;
  box_free: string;
  box_partially_occupied: string;
  box_occupied: string;
  box_disabled: string;
  box_yourDesk: string;
  box_userSelectDesk: string;
  box_position_available: string;

  selectOptionMenu: string;
  url: String;

  constructor(
    private modalReservationService: ModalReservationService,
    private boxOfficeService: BoxOfficeService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    document.head.innerHTML =
      document.head.innerHTML +
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">';
    this.url = this.route.snapshot['_routerState'].url as String;
    if (
      this.url.includes('/dinning-position') ||
      this.url.includes('-dinner')
    ) {
      this.selectOptionMenu = 'dinner';
    } else {
      this.selectOptionMenu = 'desks';
    }
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    this.route.params.forEach((params: Params) => {
      const url = this.route.snapshot['_routerState'].url as String;

      // Language modification for the mobility part
      let message = new MessagesPipe();
      this.language = params['language'];
      if (this.language) {
        message.setLanguage(this.language);
      }
      this.box_title_legend = message.transform('box_title_legend');
      this.box_title = message.transform('box_title');
      this.box_free = message.transform('box_free');
      this.box_partially_occupied = message.transform('box_partially_occupied');
      this.box_occupied = message.transform('box_occupied');
      this.box_disabled = message.transform('box_disabled');
      this.box_yourDesk = message.transform('box_yourDesk');
      this.box_userSelectDesk = message.transform('box_userSelectDesk');
      this.box_position_available = message.transform('box_position_available');

      if (
        url.includes('/readerBoxOffice/') ||
        url.includes('/view-position/') ||
        url.includes('/readerBoxOffice-dinner/')
      ) {
        this.readerDesk = true;
        if (
          params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.id = params['id'];
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.onlyRead = true;
        }
        this.centerScrollDesk();
      } else if (
        url.includes('/selectView-position/') ||
        url.includes('/viewSelectBoxOffice/') ||
        url.includes('/viewSelectBoxOffice-dinner/')
      ) {
        this.viewSelect = true;
        if (
          params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.id = params['id'];
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
        }
      } else if (
        url.includes('/viewMultiple-position/') ||
        url.includes('/viewMultipleBoxOffice/') ||
        url.includes('/viewMultipleBoxOffice-dinner/')
      ) {
        this.viewMulti = true;
        if (
          params['idList'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.idList = params['idList'].split(',');
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.onlyRead = true;
        }
      } else {
        this.readerDesk = false;
        if (
          !params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.id = null;
        }
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params?.atr) {
        this.listAttributes = [...params.atr];
      }
    });
    this.getSearchHostDesck(
      this.areaId,
      this.date,
      this.token,
      this.listAttributes
    );
  }

  getSearchHostDesck(
    areaId: number,
    date: number,
    token: string,
    listAttributes?: DeskAttribute[]
  ) {
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    let subsBoxOffice = this.boxOfficeService
      .searchHostDesck(
        areaId,
        date,
        token,
        this.selectOptionMenu,
        listAttributes
      )
      .subscribe(
        (res: Desks) => {
          if (res) {
            this.desks = res;
            this.spinnerService.hide();
            clearTimeout(this.timeOutApi);
            setTimeout(() => {
              this.centerScrollDesk();
              this.zoomInit();
            }, 0);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
            clearTimeout(this.timeOutApi);
          }
        }
      );
    this.timeOutApi = setTimeout(() => {
      subsBoxOffice.unsubscribe();
      this.dipastchEventError(408);
    }, 10000);
  }

  openReservationDeskId(item) {
    const url = this.route.snapshot['_routerState'].url as String;
    if (
      item &&
      item.id &&
      item.status &&
      item.status != 1 &&
      !this.id &&
      !url.includes('/viewMultiple-position/') &&
      !url.includes('/viewMultipleBoxOffice/') &&
      !url.includes('/viewMultipleBoxOffice-dinner/')
    ) {
      this.modalReservationService.openModal(
        item.id,
        this.date,
        this.areaId,
        this.token
      );
      this.modalReservationService.setLanguage(this.language);
    }
    if (
      item &&
      item.id &&
      item.status &&
      item.status != 1 &&
      (url.includes('/selectView-position/') ||
        url.includes('/viewSelectBoxOffice/') ||
        url.includes('/viewSelectBoxOffice-dinner/'))
    ) {
      this.modalReservationService.openModal(
        item.id,
        this.date,
        this.areaId,
        this.token
      );
      this.modalReservationService.setLanguage(this.language);
    }
  }

  centerScrollDesk() {
    if (this.desks && this.desks.hotdesks && this.readerDesk) {
      this.desks.hotdesks.forEach((x) => {
        if (x.id == this.id) {
          setTimeout(() => {
            document.getElementById('map').scrollTop =
              document.getElementById(x.id.toString()).offsetTop *
                this.zoomScale -
              (screen.height / 2 + 40) * this.zoomScale;
            document.getElementById('map').scrollLeft =
              document.getElementById(x.id.toString()).offsetLeft *
                this.zoomScale -
              (screen.width / 2 + 40) * this.zoomScale;
          }, 300);
        }
      });
    }
  }

  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.errorReserve(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  zoomInit() {
    if (document.getElementById('scroll')) {
      let elementScroll = document.getElementById('scroll');
      let widthImage = this.desks.width;
      switch (true) {
        case widthImage > 2200:
          this.zoomScale = 0.3;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;

        case widthImage > 2000:
          this.zoomScale = 0.4;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;

        case widthImage > 1800:
          this.zoomScale = 0.5;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;

        case widthImage > 1600:
          this.zoomScale = 0.6;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;

        case widthImage <= 1600:
          this.zoomScale = 0.7;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;

        default:
          this.zoomScale = 0.8;
          elementScroll.style.transform = `scale(${this.zoomScale})`;
          break;
      }
    }
  }

  zoomIn() {
    if (this.zoomScale < 1.1) {
      this.zoomScale = this.zoomScale + 0.05;
      document.getElementById(
        'scroll'
      ).style.transform = `scale(${this.zoomScale})`;
    }
  }

  zoomOut() {
    if (this.zoomScale > 0.3) {
      this.zoomScale = this.zoomScale - 0.05;
      document.getElementById(
        'scroll'
      ).style.transform = `scale(${this.zoomScale})`;
    }
  }

  changeReserve() {
    this.getSearchHostDesck(this.areaId, this.date, this.token);
  }

  compareIdsItem(item: any): string {
    let ret = '';
    let found = false;
    this.idList.forEach((element) => {
      if (!found) {
        if (element === item.id.toString()) {
          ret = `{ active }`;
          found = true;
        } else if (item.status === 1) {
          ret = `{ disabled }`;
        } else if (item.status === 2) {
          ret = `{ free }`;
        } else if (item.status === 3) {
          ret = `{ partiallyOccupied }`;
        } else if (item.status === 4) {
          ret = `{ occupied }`;
        }
      }
    });
    return ret;
  }

  compareIdsItemForId(item: any): boolean {
    let ret = false;
    let found = false;
    this.idList.forEach((element) => {
      if (!found) {
        if (element === item.id.toString()) {
          ret = true;
          found = true;
        }
      }
    });
    return ret;
  }
}
