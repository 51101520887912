import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalIndicateDepartmentService {
  constructor() {}

  openModal: () => void;
  closeModal: () => void;
}