import { Component, OnInit } from '@angular/core';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { NavigatorRefreshService } from 'src/app/shared/services/navigator.refresh.service';

@Component({
  selector: 'app-job-position',
  templateUrl: './job-position.component.html',
  styleUrls: ['./job-position.component.scss'],
})
export class JobPositionComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  constructor(private navigatorRefreshService: NavigatorRefreshService) {}

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_job_position')
    );
  }
}
