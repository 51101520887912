import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservationResourceWs } from '../../../core/models/reservation.resource.ws';
import { ModalReservationGenericService } from '../../../shared/components/modal-reservation-generic/modal-reservation-generic.service';
import { ResourcesService } from '../../../shared/services/resources.service';

@Component({
  selector: 'app-reserve-mobile',
  templateUrl: './reserve-mobile.component.html',
  styleUrls: ['./reserve-mobile.component.scss'],
})
export class ReserveMobileComponent implements OnInit {
  idResourceType: number;
  license: string;
  date: number;
  openingTime: number;
  endingTime: number;
  idEdit: number;
  idResourceForHour: number;
  timeInterval: number;
  token: string;
  language: string;

  constructor(
    private resourcesService: ResourcesService,
    private modalReservationGenericService: ModalReservationGenericService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam && queryParam.license) {
        this.license = queryParam.license;
      } else {
        this.license = null;
      }
    });
    this.route.params.subscribe((param) => {
      if (
        param?.idResourceType &&
        param?.date &&
        param?.openingTime &&
        param?.endingTime &&
        param?.token
      ) {
        this.idResourceType = Number(param.idResourceType);
        this.date = Number(param.date);
        this.openingTime = Number(param.openingTime);
        this.endingTime = Number(param.endingTime);
        this.token = param.token;
        this.language = param.language;

        if (param?.idResourceForHour && param?.id) {
          this.idEdit = Number(param.id);
          this.idResourceForHour = Number(param.idResourceForHour);
          this.getResourceForHourReservation(
            this.date,
            this.idResourceType,
            this.idResourceForHour
          );
        } else {
          this.timeInterval = Number(param.timeInterval);
          this.openModalReservationResource(
            'reserve_resource_title',
            this.date,
            this.openingTime,
            this.endingTime,
            this.timeInterval,
            [],
            true,
            null,
            false
          );
        }
      }
    });
  }

  getResourceForHourReservation(
    date,
    resourceTypeForHourId,
    resourceForHourId
  ) {
    this.resourcesService
      .getResourceForHourReservation(
        date,
        resourceTypeForHourId,
        resourceForHourId,
        this.token
      )
      .subscribe(
        (res) => {
          this.openModalReservationResource(
            'reserve_resource_edit_title',
            date,
            this.openingTime,
            this.endingTime,
            res.resourceForHourReservationConfigureTimeInterval,
            res.reservations,
            true,
            this.idEdit,
            true
          );
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  openModalReservationResource(
    titleKey: string,
    date: number,
    openingTime: number,
    endingTime: number,
    timeInterval: number,
    listOccupation: ReservationResourceWs[],
    fullPage: boolean,
    idEdit: number,
    edit: boolean
  ) {
    setTimeout(() => {
      this.modalReservationGenericService.openModal(
        date,
        openingTime,
        endingTime,
        timeInterval,
        listOccupation,
        fullPage,
        idEdit,
        edit
      );
      this.modalReservationGenericService.setLanguage(this.language, titleKey);
    }, 300);
  }

  addReservationResource(data: any) {
    this.resourcesService
      .addResourceForHourReservation(
        data.dateFrom,
        data.dateTo,
        this.idResourceType,
        this.license,
        this.token
      )
      .subscribe(
        (res) => {
          if (res) {
            this.dipastchEventReservation(true);
          } else {
            this.dipastchEventError(204);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  editReservationResource(data: any) {
    this.resourcesService
      .editResourceForHourReservation(
        data.dateFrom,
        data.dateTo,
        this.idEdit,
        this.token
      )
      .subscribe(
        (res) => {
          if (res) {
            this.dipastchEventReservation(true);
          } else {
            this.dipastchEventError(204);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
          }
        }
      );
  }

  // METODO PARA AVISAR A MOVIL DE QUE SE HA REALIZADO LA RESERVA
  dipastchEventReservation(status: boolean) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.statusReserve(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.statusReserve.postMessage(${status});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  // METODO PARA AVISAR A MOVIL DE QUE SE HA PRODUCIDO UN ERROR
  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.errorReserve(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }
}
