import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarEditConfirmService {
  constructor() {}

  cancelAssistance: () => void;
  cancelAssistancePlace: () => void;
  cancelAssistanceTurn: () => void;
}