import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalReservationService } from '../../../shared/components/modal-reservation/modal-reservation.service';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';

@Component({
  selector: 'app-reservation-desk',
  templateUrl: './reservation-desk.component.html',
  styleUrls: ['./reservation-desk.component.scss']
})
export class ReservationDeskComponent implements OnInit {

  id: number;
  idReservation: number;
  date: number;
  token: string;
  checkin: boolean = false;
  language: string;
  areaId: number;


  constructor(private modalReservationService: ModalReservationService, private route : ActivatedRoute, private router: Router, private spinnerService: SpinnerService) { }

  ngOnInit() {
    document.head.innerHTML = document.head.innerHTML + '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">';
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    this.route.params.forEach((params: Params) => {
      // Language modification for the mobility part
      let message = new MessagesPipe();
      this.language = params['language'];
      if (this.language) {
        message.setLanguage(this.language);
      }

      if (this.router.url.includes('/reservationDesk/') || this.router.url.includes('/reservationDesk-dinner/')) {
        if (params['date'] && params['id'] && params['token']) {
          this.id = params['id'];
          this.date = params['date'];
          this.token = params['token'];
          this.areaId = params['areaId']
          this.idReservation = null;
        }
      } else {
        if (
          params['idReservation'] &&
          params['date'] &&
          params['idDesk'] &&
          params['token'] &&
          params['areaId']
        ) {
          this.idReservation = params['idReservation'];
          this.id = params['idDesk'];
          this.date = params['date'];
          this.token = params['token'];
          this.areaId = params['areaId'];
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params.checkin) {
        this.checkin = params.checkin;
      }
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      if(this.router.url.includes('/reservationDesk/') || this.router.url.includes('/reservationDesk-dinner/')) {
        this.modalReservationService.openModal(this.id, this.date, this.areaId, this.token, true, this.checkin);
      } else {
        this.modalReservationService.openModal(this.id, this.date, this.areaId, this.token, true, this.checkin, this.idReservation);
      }
      this.modalReservationService.setLanguage(this.language);
    }, 0);
  }
}
