import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  STATUS_CONFLICT,
  STATUS_FORBIDDEN
} from "../../core/constants/const";
import { LOGOUT_TIMEOUT } from "../../core/constants/consts";
import { ReservationResponseWs } from "../../core/models/reservation.response.ws";
import { RoomListResponseWs } from "../../core/models/room.list.response.ws";
import { RoomListWs } from "../../core/models/room.list.ws";
import { RoomWs } from "../../core/models/room.ws";
import { UserLdapWs } from "../../core/models/user.ldap.ws";
import { DateStringPipe } from "../../shared/pipes/date.string.pipe";
import { MessagesPipe } from "../../shared/pipes/messages.pipe";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { BaseService } from "../../shared/services/base.service";
import { FlashMessagesService } from "../../shared/services/flash-messages.service";
import { NavigatorRefreshService } from "../../shared/services/navigator.refresh.service";
import { ReservationService } from "../../shared/services/reservation.service";
import { RoomService } from "../../shared/services/room.service";
import { WaitingService } from "../../shared/services/waiting.service";
import { Utils } from "../../shared/utils/utils";

@Component({
  templateUrl: "room.available.component.html",
  styleUrls: ["room-available.scss"]
})
export class RoomAvailableComponent implements OnInit {
  roomList: RoomListWs;
  availableRooms: RoomListResponseWs[];
  roomListResponseSelect: RoomListResponseWs;
  roomDetail: RoomWs;
  messagesPipe: MessagesPipe;
  existAvailableRooms: boolean;
  reservationResponse: ReservationResponseWs;
  showServerLunchMsg: boolean;
  reservationBtnDisabled: boolean;
  currentuser: UserLdapWs;

  constructor(
    private authenticationService: AuthenticationService,
    private roomService: RoomService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private reservationService: ReservationService,
    private waitingService: WaitingService,
    private baseService: BaseService,
    private utils: Utils,
    private navigatorRefreshService: NavigatorRefreshService
  ) {
    this.roomListResponseSelect = new RoomListResponseWs();
    this.roomListResponseSelect.rooms = [];
    this.availableRooms = [];
    this.messagesPipe = new MessagesPipe();
    this.existAvailableRooms = true;
    this.roomDetail = new RoomWs("", "", "", "", "", false);
    this.roomDetail.reservations = [];
    this.showServerLunchMsg = false;
    this.reservationBtnDisabled = false;
    this.reservationResponse = new ReservationResponseWs();
  }

  ngOnInit() {
    if (
      !this.authenticationService.getPermissionsForCurrentUser()
        .allowReservation
    ) {
      this.router.navigate(["/menu/home"]);
    }

    this.currentuser = this.authenticationService.getCurrentUser();
    this.loadAvailableRooms();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform("menu_available_rooms")
    );
  }

  loadAvailableRooms() {
    if (localStorage.getItem("roomFilter")) {
      this.roomList = JSON.parse(localStorage.getItem("roomFilter"));
      this.roomList.dateFrom = new Date(this.roomList.dateFromInMillis);
      this.roomList.dateTo = new Date(this.roomList.dateToInMillis);
      let actualDate = new Date();

      if (this.utils.compareDates(this.roomList.dateFrom, actualDate) < 0) {
        localStorage.removeItem("roomFilter");
        this.router.navigate(["/menu/home"]);
      } else {
        this.roomService
          .getAvailableRooms(
            this.roomList,
            this.authenticationService.getToken()
          )
          .subscribe(
            response => {
              this.availableRooms = response;

              if (this.availableRooms.length > 0) {
                this.roomListResponseSelect = this.availableRooms[0];
                this.existAvailableRooms = true;
              } else {
                if (this.roomList.periodicalDateTo) {
                  this.flashMessagesService.grayOut(false);
                  this.flashMessagesService.show(
                    this.messagesPipe.transform("periodicity_empty_rooms"),
                    { cssClass: "alert-sucess", timeout: 3000 }
                  );
                  this.router.navigate(["/menu/reservations"]);
                } else {
                  this.roomListResponseSelect = new RoomListResponseWs();
                  this.roomListResponseSelect.rooms = [];
                  this.existAvailableRooms = false;
                }
              }
            },
            error => {
              if (error.code == STATUS_FORBIDDEN) {
                this.authenticationService.refreshToken().subscribe(
                  response => {
                    this.loadAvailableRooms();
                  },
                  error => {
                    this.authenticationService.validateSessionId().subscribe(
                      response => {
                        this.loadAvailableRooms();
                      },
                      error => {
                        this.flashMessagesService.grayOut(false);
                        this.flashMessagesService.show(
                          this.messagesPipe.transform("error_forbidden"),
                          { cssClass: "alert-danger", timeout: 3000 }
                        );

                        setTimeout(() => {
                          this.authenticationService.logout();
                        }, LOGOUT_TIMEOUT);
                      }
                    );
                  }
                );
              } else {
                this.baseService.showErrorDialog(
                  this.flashMessagesService,
                  error,
                  this.messagesPipe.transform("available_rooms_list_error")
                );
                this.router.navigate(["/menu/home"]);
              }
            }
          );
      }
    } else {
      this.router.navigate(["/menu/home"]);
    }
  }

  changeBuilding(roomListResponse: RoomListResponseWs): void {
    this.closeRoomDetail();
    this.roomListResponseSelect = roomListResponse;
  }

  showRoomDetail($event): void {
    this.roomService
      .getRoomDetail(
        $event.id,
        this.roomList.dateFrom,
        this.authenticationService.getToken()
      )
      .subscribe(
        room => {
          if (typeof room.serverDate == "number") {
            room.serverDate = new Date(room.serverDate as number);
          }

          this.roomDetail = room;

          if (!this.roomDetail.reservations) {
            this.roomDetail.reservations = [];
          }
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.showRoomDetail($event);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.showRoomDetail($event);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform("error_getRoomDetail")
            );
            this.router.navigate(["/menu/home"]);
          }
        }
      );
  }

  closeRoomDetail(): void {
    this.roomDetail = new RoomWs("", "", "", "", "");
    this.roomDetail.reservations = [];
  }

  reserveRoom($event): void {
    let successMessage;
    this.reservationBtnDisabled = true;
    let roomWs = $event.room;

    if ($event.dateFrom && $event.dateTo) {
      this.roomList.dateFrom = new Date($event.dateFrom);
      this.roomList.dateTo = new Date($event.dateTo);
    }

    this.reservationService
      .reservationBooking(
        roomWs,
        this.roomList,
        this.authenticationService.getToken()
      )
      .subscribe(
        response => {
          let dateStringPipe = new DateStringPipe();
          localStorage.removeItem("roomFilter");

          if (response.message != null && response.message.length > 0) {
            this.showServerLunchMsg = true;
            this.reservationResponse = response;
          } else {
            if (this.roomList.periodicalDateTo) {
              successMessage = this.messagesPipe.transform(
                "room_reserved_multiple_msg"
              );
            } else {
              successMessage = this.messagesPipe.transform(
                "room_reserved_msg",
                [
                  roomWs.name,
                  dateStringPipe.transform(
                    this.roomList.dateFrom.toString(),
                    "DD/MM"
                  ),
                  dateStringPipe.transform(
                    this.roomList.dateFrom.toString(),
                    "HH:mm"
                  ),
                  dateStringPipe.transform(
                    this.roomList.dateTo.toString(),
                    "HH:mm"
                  )
                ]
              );
            }

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(successMessage, {
              cssClass: "alert-success",
              timeout: 3000
            });

            this.router.navigate(["/menu/reservations"]);
          }
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.reserveRoom($event);
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.reserveRoom($event);
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform("reserve_creation_error")
            );

            if (error.code == STATUS_CONFLICT) {
              localStorage.removeItem("roomFilter");
              this.router.navigate(["/menu/reservations"]);
            } else {
              this.router.navigate(["/menu/home"]);
            }
          }
        }
      );
  }

  backToReservationList(): void {
    this.router.navigate(["/menu/reservations"]);
  }

  addToWaitingList(): void {
    this.waitingService
      .waitingListAdd(this.roomList, this.authenticationService.getToken())
      .subscribe(
        response => {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform("room_to_waiting"),
            { cssClass: "alert-success", timeout: 3000 }
          );

          localStorage.removeItem("roomFilter");
          this.router.navigate(["/menu/reservations"]);
        },
        error => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              response => {
                this.addToWaitingList();
              },
              error => {
                this.authenticationService.validateSessionId().subscribe(
                  response => {
                    this.addToWaitingList();
                  },
                  error => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform("error_forbidden"),
                      { cssClass: "alert-danger", timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform("room_to_waiting_error")
            );

            if (error.code == STATUS_CONFLICT) {
              localStorage.removeItem("roomFilter");
              this.router.navigate(["/menu/reservations"]);
            } else {
              this.router.navigate(["/menu/home"]);
            }
          }
        }
      );
  }

  repeatWitouthEquipment(): void {
    this.roomList.equipment = [];

    localStorage.setItem("roomFilter", JSON.stringify(this.roomList));
    this.loadAvailableRooms();
  }

  backToBookings(): void {
    this.router.navigate(["/menu/reservations"]);
  }
}
