import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { SensorService } from '../../shared/services/sensor.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'app-dinning-occupation',
  templateUrl: './dinning-occupation.component.html',
  styleUrls: ['./dinning-occupation.component.scss'],
})
export class DinningOccupationComponent implements OnInit {
  // Percentage
  data: number[] = [0, 0];

  // Labels
  labels: Label[] = ['', ''];

  // Colors
  colors = [
    {
      backgroundColor: ['rgb(192 60 89)', 'rgb(142 186 229)'],
    },
  ];

  // Chart options
  chartOptions: ChartOptions = {};

  chartType: ChartType = 'doughnut';
  chartLegend = true;

  urlMenu: any;
  url: String;

  message: MessagesPipe;

  updatedTime: any;

  language: string;
  occupationTextLastUpdated: string;

  constructor(
    private sensorService: SensorService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    setInterval(() => this.reloadPage(), 30000);
  }

  reloadPage() {
    this.ngOnInit();
  }

  ngOnInit() {
    const date = new Date();
    this.updatedTime = moment(date).format('HH:mm:ss');
    this.message = new MessagesPipe();
    this.url = this.route.snapshot['_routerState'].url as String;
    let token = this.route.snapshot.queryParamMap.get('accessToken');
    let languageParam = this.route.snapshot.queryParamMap.get('locale');
    const headquarterId = +this.route.snapshot.paramMap.get('headquarterId');

    if (!token) {
        token = this.route.snapshot.paramMap.get('token');
    }
    if (!languageParam) {
        languageParam = this.route.snapshot.paramMap.get('language');
    }
    this.language = languageParam;

    if (this.url.includes('/dinning-occupation-headquarter/')) {
      this.getSensorDataByHeadquarterId(headquarterId);
    } else {
      this.getSensorData(token);
    }

    this.message.setLanguage(this.language);
    const occupancyLevel = this.message.transform(
      'occupation_text_occupancy_level'
    );

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        text: occupancyLevel,
        fontSize: 20,
        fontColor: 'rgba(0,0,0,1)',
        display: true,
      },
      legend: {
        position: 'bottom',
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
          color: '#fff',
        },
      },
    };
    this.occupationTextLastUpdated = this.message.transform('occupation_text_last_update');
  }

  getSensorData(token: string) {
    this.sensorService.getSensorData(token).subscribe((res) => {
      const free = this.message.transform('occupation_text_free');
      const occupied = this.message.transform('occupation_text_occupied');

      if (res) {
        this.urlMenu = res.urlMenu
          ? this.sanitizer.bypassSecurityTrustResourceUrl(res.urlMenu)
          : '';
        const freePercentage =
          100 - (res.occupationPercentage ? res.occupationPercentage : 0);
        this.data = [
          res.occupationPercentage <= 100 ? res.occupationPercentage : 100,
          freePercentage < 0 ? 0 : freePercentage,
        ];

        this.labels = [
          `${occupied} ${res.occupationPercentage}%`,
          `${free} ${this.data[1]}%`,
        ];
      }
    });
  }

  getSensorDataByHeadquarterId(headquarterId: number) {
    this.sensorService
      .getSensorDataByHeadquarterId(headquarterId)
      .subscribe((res) => {
        const free = this.message.transform('occupation_text_free');
        const occupied = this.message.transform('occupation_text_occupied');

        if (res) {
          this.urlMenu = res.urlMenu
            ? this.sanitizer.bypassSecurityTrustResourceUrl(res.urlMenu)
            : '';
          const freePercentage =
            100 - (res.occupationPercentage ? res.occupationPercentage : 0);
          this.data = [
            res.occupationPercentage <= 100 ? res.occupationPercentage : 100,
            freePercentage < 0 ? 0 : freePercentage,
          ];

          this.labels = [
            `${occupied} ${res.occupationPercentage}%`,
            `${free} ${this.data[1]}%`,
          ];
        }
      });
  }
}
