import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import {
  FLOOR_DATA_API_PATH,
  SERVICE_BASE_URL,
} from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class FloorService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getFloorsListAll(id: string): Observable<any> {
    const url = `${SERVICE_BASE_URL}${FLOOR_DATA_API_PATH}${id}?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getFloorsListAllByAttribute(
    id: string,
    listAttributes?: DeskAttribute[]
  ): Observable<any> {
    let url = `${SERVICE_BASE_URL}/api/desks/floors/listAll/${id}?access_token=${this.authenticationService.getToken()}`;
    if (listAttributes?.length > 0) {
      listAttributes.map((attribute) => {
        url = url + `&attributesId=${attribute}`;
      });
    }
    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
