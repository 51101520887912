import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getData(): Observable<any> {
    const bodyString = '';

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/configuration/getData?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
