import { Component, OnInit } from '@angular/core';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { finalize } from 'rxjs/operators';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { LOGOUT_TIMEOUT } from '../../core/constants/consts';

@Component({
  selector: 'app-cantine',
  templateUrl: './cantine.component.html',
  styleUrls: ['./cantine.component.scss'],
})
export class CantineComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  url: any = null;
  urlSafe: SafeResourceUrl;

  language: string;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    public sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.language = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language.substr(0, 2);
    this.messagesPipe.setLanguage(this.language);
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_cantine')
    );

    this.getData();
  }

  getData() {
    this.spinnerService.show();
    this.configurationService
      .getData()
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            this.url = res.cantineUrl + '/' + this.language;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          }
        },
        (error) => {
          this.handleNetworkError(error, 'assistance_search_error', () => {
            this.getData();
          });
        }
      );
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            (error) => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }
}
