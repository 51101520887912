import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../shared/services/login.service';
import { STATUS_CONFLICT } from '../../core/constants/const';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
// import globals from 'globals';

@Component({
  selector: 'validateTokenComponent',
  templateUrl: './validate-token.component.html',
  styleUrls: ['./validate-token.component.scss']
})
export class ValidateTokenComponent implements OnInit {

  username:string;
  token:string;
  errorLogin: string;


  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {

    if (this.authenticationService.existLogedUser()) {
      this.router.navigate(['/menu/home']);
    }

    this.validateToken();
  }

  validateToken() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.username = params['du'];
      this.token = params['c'];
    });

    this.loginService.loginByToken(this.username, this.token).subscribe(
      response => {

        this.authenticationService.saveToken(response);
        this.errorLogin = '';

          if (response.headquarters && response.headquarters.id) {
            this.router.navigate(['/menu/home']);
          } else {
            this.router.navigate(['/menu/profile']);
          }
      },
      error => {

        if (error.code == STATUS_CONFLICT) {
          this.errorLogin = error.message;
        } else {
          let messagePipe = new MessagesPipe();
          this.errorLogin = messagePipe.transform('error_validate_token');
        }
      }
    );
  }

}