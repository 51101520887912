import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { NavigatorRefreshService } from 'src/app/shared/services/navigator.refresh.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { BaseService } from '../../shared/services/base.service';
import { CustomExternalModuleService } from '../../shared/services/custom-external-module.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';

declare function dispatchEventIncidenceAndroid(): any;
declare function dispatchEventIncidenceIos(): any;
@Component({
  selector: 'app-custom-external-module',
  templateUrl: './custom-external-module.component.html',
  styleUrls: ['./custom-external-module.component.scss'],
})
export class CustomExternalModuleComponent implements OnInit {
  externalModule: any;
  moduleId: number;
  token: string;
  locale: string;
  messagesPipe: MessagesPipe;
  externalModuleFilesString: string;
  externalModuleAppsString: string;
  externalModuleReportIncidenceString: string;
  @Input() showForm: boolean;
  @Output() myEvent = new EventEmitter();

  constructor(
    private customExternalModuleService: CustomExternalModuleService,
    private route: ActivatedRoute,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigatorRefreshService: NavigatorRefreshService
  ) {}

  ngOnInit() {
    this.getParameters();
    this.setMessages();
  }

  private getParameters() {
    if (this.router.url.includes('external-module/customExternalModule/')) {
      this.token = this.authenticationService.getCurrentUser().accessToken;
    } else {
      const queryParams = this.route.snapshot.queryParamMap;
      this.token = queryParams.get('access_token');
      this.locale = queryParams.get('locale');
    }
    this.route.params.subscribe((params) => {
      this.moduleId = +params.moduleId;
      this.getExternalModule(this.moduleId, this.token);
    });
  }

  private setMessages() {
    this.messagesPipe = new MessagesPipe();
    if (this.locale) {
      this.messagesPipe.setLanguage(this.locale);
    }
    this.externalModuleFilesString = this.messagesPipe.transform(
      'external_module_files'
    );
    this.externalModuleAppsString = this.messagesPipe.transform(
      'external_module_apps'
    );
    this.externalModuleReportIncidenceString = this.messagesPipe
    .transform('external_module_report_incidence')
    .toUpperCase();
  }

  private getExternalModule(id, token) {
    this.customExternalModuleService
      .getCustomExternalModule(id, token)
      .subscribe(
        (next: any) => {
          if (next) {
            this.externalModule = next;
            this.navigatorRefreshService.emitMenuRefreshLinkEvent(
              this.externalModule.name
            );
            this.createdScript();
          } else {
            this.externalModule = {};
          }
        },
        (error: any) => {
          this.externalModule = {};
          let errorKey = 'external_module_error';
          if (error.code === 404) {
            errorKey = 'external_module_not_found';
          }
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform(errorKey)
          );
        }
      );
  }

  getDownloadUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  getAppUrl(application: any) {
    let url = this.getUrlByType(application);
    if (url && application.hasToken) {
      url = `${url}?token=${application.token}`;
    }
    if (url) {
      url = this.domSanitizer.bypassSecurityTrustUrl(url);
    }
    return url;
  }

  private getUrlByType(application: any) {
    let url = application.url;
    if (application.appType.code === '02') {
      url = this.getUrlByOperatingSystem(application);
    }

    return url;
  }

  private getUrlByOperatingSystem(application: any) {
    let url = null;

    if (this.isAndroid()) {
      url = application.androidPackage;
    } else if (this.isIOS()) {
      url = application.schemeUrlIos;
    }
    return url;
  }

  goToIncidenced() {
    if (this.isAndroid()) {
      dispatchEventIncidenceAndroid();
    } else if (this.isIOS()) {
      dispatchEventIncidenceIos();
    } else {
      this.router.navigate(['/menu/incidence'], {
        queryParams: {
          modalShow: true,
          category: this.externalModule.category.id,
        },
      });
    }
  }

  public isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public isIOS() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /iPad|iPhone|iPod/.test(userAgent);
  }

  createdScript() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (this.isAndroid() && this.externalModule) {
      var x = document.createElement('script');
      x.id = 'scriptEventIncidence';
      var t = document.createTextNode(`
        function dispatchEventIncidenceAndroid() {
          alert("metodo Android")
          JSInterface.openIssueFormWithCategory(${this.externalModule.category.id});
        }
        `);
      x.appendChild(t);
      document.head.appendChild(x);
    } else if (this.isIOS() && this.externalModule) {
      var x = document.createElement('script');
      x.id = 'scriptEventIncidence';
      var t = document.createTextNode(`
        function dispatchEventIncidenceIos() {
          window.webkit.messageHandlers.openIssueFormWithCategory.postMessage(${this.externalModule.category.id});
          }
        `);
      x.appendChild(t);
      document.head.appendChild(x);
    }
  }

  ngOnDestroy(): void {
    if (document.getElementById('scriptEventIncidence')) {
      let elem = document.getElementById('scriptEventIncidence');
      elem.parentElement.removeChild(elem);
    }
  }
}
