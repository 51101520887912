import { AuthenticationService } from '../../shared/services/authentication.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RoomWs } from '../../core/models/room.ws';
import { RoomDetailEmitter } from '../../core/models/room.detail.emitter';
import { UserLdapWs } from '../../core/models/user.ldap.ws';

@Component({
  selector: 'roomCard',
  templateUrl: 'room.card.component.html',
  styleUrls: ['room-card.scss']
})
export class RoomCardComponent {
  @Input() room: RoomWs;
  @Input() reservationBtnDisabled: boolean;
  @Output() roomReserveEmitter = new EventEmitter<RoomDetailEmitter>();
  @Output() roomDetailEmitter = new EventEmitter<RoomWs>();
  currentUser: UserLdapWs;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUser();
  }

  getAssistants(): string {
    return `${this.room.capacityMin}/${this.room.capacityMax}`;
  }

  roomDetail(): void {
    this.roomDetailEmitter.emit(this.room);
  }

  reserveRoom(): void {
    let roomDetailEmitter = new RoomDetailEmitter(this.room);
    this.roomReserveEmitter.emit(roomDetailEmitter);
  }
}
