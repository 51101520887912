import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalReservationService {

  constructor() { }

  openModal: (id: number, date:number, areadId: number, token : string, fullPage?: boolean, checkin?: boolean, idReservation?: number) => void;
  setLanguage: (language: string) => void;
  closeModal: () => void;
}
