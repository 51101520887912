<div class="profileForm" id="modalConfig">
  <app-spinner></app-spinner>
  <div class="updatePassword" *ngIf="showFormChangePassword">
    <form
      class="row mui-form"
      [formGroup]="formChangePassword"
      (ngSubmit)="updatePassword()"
      novalidate
    >
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-textfield">
          <label for="textCurrentPassword">{{
            'profile_currentPassword_title' | messagesPipe
          }}</label>
          <input
            type="password"
            placeholder="{{
              'profile_currentPassword_placeholder' | messagesPipe
            }}"
            #textCurrentPassword
            id="textCurrentPassword"
            name="textCurrentPassword"
            formControlName="currentPassword"
            class="form-control"
            rows="3"
            required
          />
        </div>
      </div>
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-textfield">
          <label for="textNewPassword">{{
            'profile_newPassword_title' | messagesPipe
          }}</label>
          <input
            type="password"
            placeholder="{{ 'profile_newPassword_placeholder' | messagesPipe }}"
            #textNewPassword
            id="textNewPassword"
            name="textNewPassword"
            formControlName="newPassword"
            class="form-control"
            rows="3"
            required
          />
          <div
            *ngIf="f.newPassword.touched && f.newPassword.invalid"
            class="alert alert-danger"
          >
            <div *ngIf="f.newPassword.errors.required">
              {{ 'error_password_required' | messagesPipe }}
            </div>
          </div>
        </div>
      </div>
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-textfield">
          <label for="textConfirmNewPassword">{{
            'profile_confirmNewPassword_title' | messagesPipe
          }}</label>
          <input
            type="password"
            placeholder="{{
              'profile_confirmNewPassword_placeholder' | messagesPipe
            }}"
            #textConfirmNewPassword
            id="textConfirmNewPassword"
            name="textConfirmNewPassword"
            formControlName="confirmNewPassword"
            class="form-control"
            rows="3"
            required
          />
          <div
            *ngIf="f.confirmNewPassword.touched && f.confirmNewPassword.invalid"
            class="alert alert-danger"
          >
            <div *ngIf="f.confirmNewPassword.errors.required">
              {{ 'error_password_required' | messagesPipe }}
            </div>
            <div *ngIf="f.confirmNewPassword.errors.confirmedValidator">
              {{ 'error_password_different' | messagesPipe }}
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="check-availability button-by"
        [disabled]="!formChangePassword.valid"
      >
        {{ 'profile_updatePassword' | messagesPipe }}
      </button>
    </form>
  </div>
  <div class="changeVehicle" *ngIf="showVehicle">
    <div class="listVehicle" id="listVehicle">
      <ul>
        <li
          *ngFor="let vehicle of userVehicle"
          (click)="vehicle.active = true"
          (clickOutside)="vehicle.active = false"
        >
          <div class="listVehicle__content">
            <span class="listVehicle__content--type">{{
              vehicle.type.name
            }}</span>
            <span class="listVehicle__content--detail"
              >{{ vehicle.model }} - {{ vehicle.license }}</span
            >
          </div>
          <div
            class="listVehicle__modify"
            [ngClass]="{ active: vehicle.active }"
          >
            <div class="principal">
              <input
                type="checkbox"
                [checked]="vehicle.main"
                (change)="changueMain(vehicle, $event.target.checked)"
                [id]="vehicle.id"
                [name]="vehicle.id"
              />
              <label [for]="vehicle.id">{{
                'profile_vehicle_main' | messagesPipe
              }}</label>
            </div>
            <button class="btn" (click)="openEditVehicle(vehicle)">
              {{ 'profile_vehicle_edit' | messagesPipe }}
            </button>
            <button class="btn" (click)="remoVehicle(vehicle?.id)">
              {{ 'profile_vehicle_remove' | messagesPipe }}
            </button>
          </div>
        </li>
      </ul>
      <div class="add-vehicle">
        <button
          type="button"
          class="vdp-icon-mas add"
          (click)="openNewVehicle()"
        ></button>
      </div>
    </div>
    <div class="formVehicle" id="formVehicle">
      <form
        class="row mui-form"
        [formGroup]="formVehicle"
        (ngSubmit)="updateVehicle()"
        novalidate
      >
        <div class="select-by form-group select-group col-sm-12">
          <div class="row-profile mui-select">
            <label for="type">{{
              'profile_vehicleEdit_type_title' | messagesPipe
            }}</label>
            <select id="type" name="type" formControlName="type">
              <option
                [value]="0"
                [selected]="formVehicle?.controls['type']?.value == 0"
              >
                {{ 'profile_vehicleEdit_type_select' | messagesPipe }}
              </option>
              <option
                [value]="type.type_id"
                *ngFor="let type of typeVehicle"
                [selected]="
                  formVehicle?.controls['type']?.value?.type_id == type.type_id
                "
              >
                {{ type.name }}
              </option>
            </select>
            <span class="required" [hidden]="formVehicle.controls.type.valid">{{
              'error_required' | messagesPipe
            }}</span>
          </div>
        </div>
        <div class="select-by form-group select-group col-sm-12">
          <div class="mui-textfield">
            <label for="license">{{
              'profile_vehicleEdit_license_title' | messagesPipe
            }}</label>
            <input
              placeholder="{{
                'profile_vehicleEdit_license_placeholder' | messagesPipe
              }}"
              #license
              id="license"
              name="license"
              formControlName="license"
              class="form-control"
              rows="3"
              required
            />
            <span
              class="required"
              [hidden]="formVehicle.controls.license.valid"
              >{{ 'error_license_required' | messagesPipe }}</span
            >
          </div>
        </div>
        <div class="select-by form-group select-group col-sm-12">
          <div class="mui-textfield">
            <label for="model">{{
              'profile_vehicleEdit_model_title' | messagesPipe
            }}</label>
            <input
              placeholder="{{
                'profile_vehicleEdit_model_placeholder' | messagesPipe
              }}"
              #model
              id="model"
              name="model"
              formControlName="model"
              class="form-control"
              rows="3"
              required
            />
            <span
              class="required"
              [hidden]="formVehicle.controls.model.valid"
              >{{ 'error_required' | messagesPipe }}</span
            >
          </div>
        </div>
        <div class="select-by form-group select-group col-sm-12">
          <div class="mui-textfield">
            <label for="color">{{
              'profile_vehicleEdit_color_title' | messagesPipe
            }}</label>
            <input
              placeholder="{{
                'profile_vehicleEdit_color_placeholder' | messagesPipe
              }}"
              #color
              id="color"
              name="color"
              formControlName="color"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
        <div class="select-by form-group select-group col-sm-12">
          <div class="mui-textfield main">
            <label for="principal">{{
              'profile_vehicleEdit_main_title' | messagesPipe
            }}</label>
            <input
              type="checkbox"
              #principal
              id="principal"
              name="principal"
              formControlName="main"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="!formVehicle.valid"
        >
          {{ menssageButtonVehicle }}
        </button>
        <button
          type="button"
          class="cancel button-by"
          (click)="closeEditVehicle()"
        >
          {{ 'profile_vehicleEdit_cancel' | messagesPipe }}
        </button>
      </form>
    </div>
  </div>
  <div class="supportAssistance" *ngIf="showSupportAssistance">
    <!-- Assistance -->
    <form
      [formGroup]="formSupportAssistance"
      (ngSubmit)="saveAssistante()"
      novalidate
    >
      <div class="row">
        <div class="col-sm-12">
          <span class="checkbox">
            <input
              type="checkbox"
              [checked]="userVehicle?.length > 0 && userProfile.parkingRequest"
              [attr.disabled]="userVehicle?.length === 0 ? true : null"
              id="solictParking"
              formControlName="solictParking"
            />
            <label for="solictParking">{{
              'profile_assistance_parking' | messagesPipe
            }}</label>
            <p *ngIf="userVehicle?.length === 0">
              {{ 'profile_assistance_no_vehicle_for_parking' | messagesPipe }}
            </p>
            <p *ngIf="userVehicle?.length > 0">
              {{ 'profile_assistance_parking_description' | messagesPipe }}
            </p>
          </span>
        </div>
        <div class="col-sm-12">
          <span class="checkbox">
            <input
              type="checkbox"
              [checked]="userProfile.dinnerShiftRequest"
              (change)="changeCheckDinner($event.target.checked)"
              id="solictDinner"
              formControlName="solictDinner"
            />
            <label for="solictDinner">{{
              'profile_assistance_dinner' | messagesPipe
            }}</label>
            <p>{{ 'profile_assistance_dinner_description' | messagesPipe }}</p>
          </span>
        </div>
        <div class="col-sm-12 availableShifts" *ngIf="showSelectTurn">
          <div class="mui-select">
            <label for="availableShifts">{{
              'profile_preferred_time_slot' | messagesPipe
            }}</label>
            <select
              id="availableShifts"
              name="availableShifts"
              formControlName="availableShifts"
            >
              <option
                [value]="0"
                [selected]="availableShifts ? checkShiftPriority() : 'true'"
              >
                {{ 'select' | messagesPipe }}
              </option>
              <option
                [value]="shift.id"
                *ngFor="let shift of availableShifts"
                [selected]="
                  formSupportAssistance.controls['availableShifts'].value ===
                  shift.id
                "
              >
                {{ shift.shiftFromAux }} - {{ shift.shiftToAux }}
              </option>
            </select>
          </div>
        </div>
        <div class="add-support-assistance">
          <button type="submit" class="check-availability save">
            {{ 'assistance_bottom_save' | messagesPipe }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
