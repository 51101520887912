<div
  class="card row"
  *ngIf="viewCard"
  [ngClass]="{
    differentBuilding:
      currentUser.headquarters.id !=
      reservationWs.room.floor.building.headquarters.id
  }"
>
  <div class="col-md-6" (click)="showDetail($event)">
    <div class="card-image">
      <img [src]="reservationWs.room.imageUrl" alt="" />
    </div>
    <i *ngIf="reservationWs.room.hasIncidence" class="vdp-salas-incidencia"></i>
    <div class="date">
      <span class="month">{{ reservationWs.dateFrom | customDatePipe }}</span>
      <span class="day">{{
        reservationWs.dateFrom | dateStringPipe: 'DD'
      }}</span>
      <span class="hour">{{
        reservationWs.dateFrom | dateStringPipe: 'HH:mm'
      }}</span>
    </div>
    <div
      class="assistants"
      *ngIf="
        reservationWs.room.capacityMin != null &&
        reservationWs.room.capacityMax != null
      "
    >
      <i class="vdp-label-asistentes"></i><span>{{ getAssistants() }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="info" (click)="showDetail($event)">
      <span class="name">{{ getRoomBuildingName() }}</span>
      <span class="name">{{ getRoomHeadquartersName() }}</span>
      <span class="code" *ngIf="reservationWs.room.conferenceCode != null"
        >{{ 'room_conference_code_title' | messagesPipe }}
        {{ reservationWs.room.conferenceCode }}</span
      >
      <span class="reason">{{ reservationWs.reason }}</span>
      <span class="tag" *ngIf="inProgress()">{{
        'reservation_in_progress' | messagesPipe
      }}</span>

      <span class="tag tag-warning" *ngIf="reservationPendingConfirmation()">{{
        'reservation_pending_confirmation' | messagesPipe
      }}</span>
    </div>
    <div class="options" *ngIf="!getIsChecked()">
      <button
        type="button"
        (click)="showInvitationPopup()"
        *ngIf="!reservationPendingConfirmation() && permissions.allowInvitation && !hiddenInviteReservationRoom"
      >
        {{ 'send_invite_btn' | messagesPipe }}<i class="vdp-invitar"></i>
      </button>
      <button type="button" (click)="cancelReservation()">
        {{ 'cancel_reservation_btn' | messagesPipe }}
        <i class="vdp-icon-cancelar"></i>
      </button>
      <button
        type="button"
        (click)="sendToMail()"
        [disabled]="btnSendDisabled"
        *ngIf="!reservationPendingConfirmation()"
      >
        {{ 'send_reservation_mail_btn' | messagesPipe }}
        <i class="vdp-salas-alerta"></i>
      </button>
    </div>
  </div>
</div>

<!-- Viewmode list -->
<div
  class="list-card row"
  *ngIf="!viewCard"
  [ngClass]="{
    differentBuilding:
      currentUser.headquarters.id !=
      reservationWs.room.floor.building.headquarters.id
  }"
>
  <div class="progress-assistants">
    <span class="progress-info" *ngIf="reservationWs.inProgress">{{
      'reservation_in_progress' | messagesPipe
    }}</span>
    <div
      class="assistants"
      *ngIf="
        reservationWs.room.capacityMin != null &&
        reservationWs.room.capacityMax != null
      "
    >
      <i class="vdp-label-asistentes"></i><span>{{ getAssistants() }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="info" (click)="showDetail($event)">
      <span class="name">{{ getRoomBuildingName() }}</span>
      <span class="code" *ngIf="reservationWs.room.conferenceCode != null"
        >{{ 'room_conference_code_title' | messagesPipe }}
        {{ reservationWs.room.conferenceCode }}</span
      >
      <span class="reason">{{ reservationWs.reason }}</span>
      <span class="date">{{ getListViewDate() }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="options" *ngIf="!getIsChecked()">
      <button type="button" (click)="cancelReservation()">
        {{ 'cancel_reservation_btn' | messagesPipe }}
        <i class="vdp-icon-cancelar"></i>
      </button>
      <button type="button" (click)="sendToMail()" [disabled]="btnSendDisabled">
        {{ 'send_reservation_mail_btn' | messagesPipe }}
        <i class="vdp-salas-alerta"></i>
      </button>
    </div>
  </div>
</div>
