import { Injectable } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs-compat/Observable';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { TreeModel } from '../../core/models/tree.model';
import { TreeNextModel } from '../../core/models/tree.next.model';
import { TreeResumeModel } from '../../core/models/tree.resume.model';
import { TreeTypeModel } from '../../core/models/tree.type.model';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getTreeTypeByUser(): Observable<TreeTypeModel[]> {
    const bodyString = {
      offset: 0,
      pageNumber: 0,
      pageSize: 99,
    };

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/treeTypeByUser?access_token=${this.authenticationService.getToken()}`,
        { ...OPTIONS, body: bodyString }
      )
      .map((response: Response) => {
        return response.json().content as TreeTypeModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getTreeHistoric(): Observable<TreeModel[]> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/historic?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json().content as TreeModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getTreeMe(): Observable<TreeModel[]> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/me?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json().content as TreeModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getTreeEditStage(treeUserStageEventId): Observable<TreeNextModel> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/editTreeStage/${treeUserStageEventId}?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as TreeNextModel;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getResumeTreeStage(treeUserStageEventId): Observable<TreeResumeModel> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/resumeTreeStage/${treeUserStageEventId}?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as TreeResumeModel;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getTreeNextStage(treeUserId): Observable<TreeNextModel> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/tree/nextTreeStage/${treeUserId}?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as TreeNextModel;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  deleteTree(id): Observable<any> {
    let bodyString = {};

    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/tree/delete/${id}?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  postAddTreeStageEvent(
    text: string,
    treeEventId: number,
    treeStageId: number,
    treeUserLdapId: number,
    file: File
  ): Observable<any> {
    let bodyString = {
      text,
      treeEventId,
      treeStageId,
      treeUserLdapId,
    };

    var form = new FormData();
    form.append('file', file);
    form.append(
      'treeUserLdapStageEventWs',
      new Blob([JSON.stringify(bodyString)], {
        type: 'application/json',
      })
    );

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/tree/addTreeStageEvent?access_token=${this.authenticationService.getToken()}`,
        form
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  postNewTreeUserLdap(id: number): Observable<TreeModel> {
    let bodyString = {
      id,
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/tree/newTreeUserLdap?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as TreeModel;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  postUpdateTreeStageEvent(
    id: number,
    text: string,
    treeEventId: number,
    treeStageId: number,
    treeUserLdapId: number,
    file: File
  ): Observable<any> {
    let bodyString = {
      id,
      text,
      treeEventId,
      treeStageId,
      treeUserLdapId,
    };

    var form = new FormData();
    form.append('file', file);
    form.append(
      'treeUserLdapStageEventWs',
      new Blob([JSON.stringify(bodyString)], {
        type: 'application/json',
      })
    );

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/tree/updateTreeStageEvent?access_token=${this.authenticationService.getToken()}`,
        form
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  downloadFile(url): Observable<any> {
    return this.http.get(url, { responseType: ResponseContentType.Blob });
  }
}
