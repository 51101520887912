
import {Pipe, PipeTransform} from '@angular/core';
import {LowerCasePipe} from "@angular/common";

@Pipe({name: 'customDatePipe'})
export class CustomDatePipe {

    months:string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    transform(value: number, monthToLower?:boolean) :string{
        let lower;

        let fecha = new Date();
        fecha.setTime(value);

        if(monthToLower){
            lower = new LowerCasePipe();
            return lower.transform(this.months[fecha.getMonth()]);
        }else{
            return this.months[fecha.getMonth()];
        }

    }
}
