import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getAttendanceInfo(id: number, userId: any): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/${id}/${userId}?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAttendances(userId: any, month: number, year: number): Observable<any[]> {
    const momentIni = this.createMoment(month, year);
    let momentFin = this.createMoment(month, year);
    momentFin = momentFin.endOf('month');
    const fechaIni = momentIni.format('DD/MM/YYYY');
    const fechaFin = momentFin.format('DD/MM/YYYY');

    const url = `${SERVICE_BASE_URL}/api/attendance/list?userId=${userId}&fechaIni=${fechaIni}&fechaFin=${fechaFin}&access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  private createMoment(month: number, year: number) {
    let monthString = '';
    if (month > 9) {
      monthString = monthString + month;
    } else {
      monthString = '0' + month;
    }
    const dateString = `01/${monthString}/${year}`;
    return moment.utc(dateString, 'DD/MM/YYYY');
  }

  getListAttendancesUserManager(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/list/users/responsable?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        const data = response.json();
        data.forEach((attendance) => {
          const day = moment
            .utc(attendance.day, 'YYYY-MM-DD')
            .format('DD/MM/YYYY');
          attendance.day = day;
        });
        return data;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getListAttendancesUserManagerForDay(day: string): Observable<UserLdapWs[]> {
    const bodyString = JSON.stringify({ day });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/list/users/responsable/day?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as UserLdapWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelAssistance(idAttendance: any, idUser: any): Observable<any> {
    const bodyString = {
      idAttendance,
      idUser,
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getCandidates(attendanceList: any, userId: any): Observable<any[]> {
    const bodyString = {
      attendanceList,
      userId: userId,
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/getCandidates?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  update(attendanceList: any, userId: any): Observable<any> {
    const bodyString = {
      attendanceList,
      userId,
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/update?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  updateDraw(id: number, userId: number, vehicleTypeId: number): Observable<any> {
    const bodyString = {
      id,
      userId,
      vehicleTypeId
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/updateDraw?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  liberateParking(id: number, userId: any): Observable<any> {
    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/parking/${id}/${userId}?access_token=${this.authenticationService.getToken()}`
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  solictParking(solict: any): Observable<any> {
    const bodyString = JSON.stringify(solict);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/parking?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelWaitingList(day: any, userId: any): Observable<any> {
    const bodyString = {
      day,
      userId,
    };

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/parking/waitinglist/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelDinnerDesk(id: number, userId: any): Observable<any> {
    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/deleteDinnerDeskReservation/${id}/${userId}?access_token=${this.authenticationService.getToken()}`
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  requestDinnerDesk(request: any): Observable<any[]> {
    request.day = moment.utc(request.day, 'DD/MM/YYYY').toDate();
    const bodyString = JSON.stringify(request);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/requestDinnerDesk?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getPlacesAvailable(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/getFreeParkingSpot?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  assignPlace(text: string): Observable<any> {
    const bodyString = JSON.stringify({ text });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/getFreeParkingSpotByLicense?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getUsers(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/user/listUsersManaged?access_token=${this.authenticationService.getToken()}`;
    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  cancelAssistanceIberdrola(idAttendance: any): Observable<any> {
    const bodyString = JSON.stringify({ idAttendance });

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  cancelDinnerDeskIbedrola(id: number): Observable<any> {
    const bodyString = JSON.stringify({});

    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/deleteDinnerDeskReservation/${id}?access_token=${this.authenticationService.getToken()}`,
        bodyString
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  liberateParkingIbedrola(id: number): Observable<any> {
    const bodyString = JSON.stringify({});

    return this.http
      .delete(
        `${SERVICE_BASE_URL}/api/attendance/parking/${id}?access_token=${this.authenticationService.getToken()}`,
        bodyString
      )
      .map((response: Response) => {
        return response.ok;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  updateIberdrola(attendances: any): Observable<any> {
    const bodyString = JSON.stringify(attendances);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/update?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  getCandidatesIberdrola(days: any): Observable<any[]> {
    const bodyString = JSON.stringify(days);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/attendance/getCandidates?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as any[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  getAttendancesIberdrola(): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/list?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  //TODO: IBERDROLA ASSISTANCE --> README
  getAttendanceInfoIberdola(id: number): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/attendance/${id}?access_token=${this.authenticationService.getToken()}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
