import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DorletInfo } from '../../core/models/dorletInfo';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { UrlHelperService } from './dorlet.service';

@Component({
  selector: 'app-dorlet',
  templateUrl: './dorlet.component.html',
  styleUrls: ['./dorlet.component.scss'],
})
export class DorletComponent implements OnInit {
  @ViewChild('frameUrl') iframe: ElementRef;
  user: UserLdapWs;
  moduleDorletActive: boolean = false;
  infoDorlet: DorletInfo;
  messagesPipe = new MessagesPipe();

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private urlHelperService: UrlHelperService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_dorlet')
    );
    this.user = this.authenticationService.getCurrentUser();
    this.moduleDorletActive = this.user.headquarters.modules.find(
      (module) => module.code === '0024'
    )?.state;
    this.infoDorlet = this.user?.dorletInfo;
    if (this.infoDorlet) {
      this.urlHelperService
        .get(this.infoDorlet)
        .subscribe((blob) => (this.iframe.nativeElement.src = blob));
    }
  }
}
