import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReservationResourceWs } from '../../../core/models/reservation.resource.ws';
import { ResourceWs } from '../../../core/models/resource.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { ModalReservationGenericService } from '../../../shared/components/modal-reservation-generic/modal-reservation-generic.service';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { ResourcesService } from '../../../shared/services/resources.service';
import { Utils } from '../../../shared/utils/utils';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss'],
})
export class ReserveComponent implements OnInit, AfterViewInit {
  @ViewChild('datePickerElement', null) datePickerElement: MyDatePicker;
  @ViewChild('selectResource', null) selectResource: ElementRef;

  dataEdit: any;

  listResourceType: ResourceWs[];

  dataReserve: ReservationResourceWs;

  reservationResourceForm: FormGroup;
  datepickerOptions: any = {};
  locale: string;

  TypeResourceActiveLicense = [1];

  messagesPipe = new MessagesPipe();
  constructor(
    private resourcesService: ResourcesService,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private modalReservationGenericService: ModalReservationGenericService,
    private spinnerService: SpinnerService,
    private flashMessagesService: FlashMessagesService,
    private sideModalService: SideModalService,
    private router: Router
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.dataEdit = this.router.getCurrentNavigation().extras.state.dataEdit;
    }
  }

  ngOnInit(): void {
    if (this.dataEdit) {
      this.createFormEdit(this.dataEdit);
    } else {
      this.dataEdit = null;
      this.createForm();
    }
    this.getResourceType();
    this.datepickerOptions = this.utils.getDatepickerOptionsOnlyFrom();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.dataEdit) {
        this.utils.setDatePickerDate(new Date(), this.datePickerElement);
      }
      if (this.dataEdit) {
        this.utils.setDatePickerDate(
          new Date(this.dataEdit.dateFrom),
          this.datePickerElement
        );
        this.selectResource.nativeElement.disabled = true;
        this.datePickerElement.opts.componentDisabled = true;
      }
    }, 0);
  }

  createForm() {
    const actualDate = new Date();
    this.reservationResourceForm = new FormGroup({
      idResourceType: new FormControl({ value: -1, disabled: false }, [
        Validators.required,
        Validators.min(1),
      ]),
      date: new FormControl(
        actualDate.setFullYear(
          actualDate.getFullYear(),
          actualDate.getMonth(),
          actualDate.getDate()
        ),
        Validators.required
      ),
      license: new FormControl(),
    });
  }

  createFormEdit(data) {
    this.dataReserve = data;
    this.reservationResourceForm = new FormGroup({
      idResourceType: new FormControl(data.resourceTypeForHour.id, [
        Validators.required,
        Validators.min(1),
      ]),
      date: new FormControl(data.dateFrom, Validators.required),
      license: new FormControl({ value: data?.license, disabled: true }),
    });
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      const reservationDate = new Date();
      this.reservationResourceForm
        .get('date')
        .setValue(
          reservationDate.setFullYear(
            $event.date.year,
            $event.date.month - 1,
            $event.date.day
          )
        );
    } else {
      this.reservationResourceForm.get('date').setValue(null);
    }
  }

  changeResourceType() {
    if (this.inputLicenseActive()) {
      this.reservationResourceForm
        .get('license')
        .setValidators(Validators.required);
    } else {
      this.reservationResourceForm.get('license').setValue('');
      this.reservationResourceForm.get('license').clearValidators();
      this.reservationResourceForm.get('license').updateValueAndValidity();
    }
  }

  getResourceType() {
    this.resourcesService.getResourcesTypeListAll().subscribe((res) => {
      if (res) {
        this.listResourceType = res;
      } else {
        this.listResourceType = [];
      }
    });
  }

  checkHourReserve() {
    const { openingTime, endingTime, headquarters } =
      this.authenticationService.getCurrentUser();
    this.modalReservationGenericService.openModal(
      this.reservationResourceForm.value.date,
      openingTime,
      endingTime,
      headquarters.resourceForHourReservationConfigureTimeInterval,
      [],
      false
    );
    this.modalReservationGenericService.setLanguage(
      this.locale,
      'reserve_resource_title'
    );
  }

  checkHourReserveEdit() {
    this.getResourceForHourReservation(
      this.reservationResourceForm.value.date,
      this.dataReserve.resourceTypeForHour.id,
      this.dataReserve.idResourceForHour
    );
  }

  getResourceForHourReservation(
    date,
    resourceTypeForHourId,
    resourceForHourId
  ) {
    this.resourcesService
      .getResourceForHourReservation(
        date,
        resourceTypeForHourId,
        resourceForHourId
      )
      .subscribe((res) => {
        const { openingTime, endingTime, headquarters } =
          this.authenticationService.getCurrentUser();
        this.modalReservationGenericService.openModal(
          this.reservationResourceForm.value.date,
          openingTime,
          endingTime,
          headquarters.resourceForHourReservationConfigureTimeInterval,
          res.reservations,
          false,
          this.dataReserve.id,
          true
        );
        this.modalReservationGenericService.setLanguage(
          this.locale,
          'reserve_resource_edit_title'
        );
      });
  }

  inputLicenseActive() {
    return this.TypeResourceActiveLicense.find(
      (x) => x == this.reservationResourceForm.get('idResourceType').value
    );
  }

  addReservationResource(data: any) {
    this.spinnerService.show();
    this.resourcesService
      .addResourceForHourReservation(
        data.dateFrom,
        data.dateTo,
        this.reservationResourceForm.get('idResourceType').value,
        this.reservationResourceForm.get('license')?.value
      )
      .subscribe(
        (res) => {
          if (res) {
            this.modalReservationGenericService.closeModal();
            this.sideModalService.closeSideModal();
            this.flashMessagesService.show(
              this.messagesPipe.transform('resource_list_add_reservation_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          } else {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'resource_list_add_reservation_error'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('resource_list_add_reservation_error'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.spinnerService.hide();
        }
      );
  }

  editReservationResource(data: any) {
    this.spinnerService.show();
    this.resourcesService
      .editResourceForHourReservation(
        data.dateFrom,
        data.dateTo,
        this.dataEdit.id
      )
      .subscribe(
        (res) => {
          if (res) {
            this.modalReservationGenericService.closeModal();
            this.sideModalService.closeSideModal();
            this.flashMessagesService.show(
              this.messagesPipe.transform('resource_list_edit_reservation_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          } else {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'resource_list_edit_reservation_error'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('resource_list_edit_reservation_error'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.spinnerService.hide();
        }
      );
  }
}
