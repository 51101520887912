<app-spinner></app-spinner>

<div class="login">
  <div class="reset-password-wrapper">
    <div class="reset-password-wrapper-form">
      <form [formGroup]="resetPasswordForm" novalidate>
        <div class="go-back" routerLink="/login">
          <i class="vdp-plegar-menu"></i>
          {{ 'resetPassword_return_btn' | messagesPipe }}
        </div>

        <img src="../../assets/img/logo-login.png" alt="logo" />

        <!-- Email input -->
        <div class="input-field">
          <label for="email" [ngClass]="{ active: emailActive }">{{
            'email' | messagesPipe
          }}</label>
          <input
            id="email"
            type="text"
            class="form-control"
            name="email"
            formControlName="email"
            (focus)="setEmailActive()"
            (focusout)="removeEmailActive()"
          />
          <span [hidden]="resetPasswordForm.controls.email.valid">{{
            'profile_email_required' | messagesPipe
          }}</span>
          <i class="vdp-icon_usuario"></i>
        </div>

        <!-- Button -->
        <div class="form-actions">
          <button
            type="submit"
            class="btn waves-effect waves-light"
            (click)="requestNewPassword()"
            [disabled]="resetPasswordForm.invalid"
          >
            {{ 'resetPassword_request_btn' | messagesPipe }}
          </button>
        </div>

        <div class="reset-error" *ngIf="resetError">
          {{ resetError }}
        </div>
      </form>
    </div>
  </div>
</div>
