import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FlashMessage } from './flash-message';
import { FlashMessageInterface } from './flash-message.interface';
import { FlashMessagesService } from '../../services/flash-messages.service';

@Component({
  selector: 'flash-messages',
  templateUrl: 'flash-messages.component.html',
  styleUrls: ['flash-messages.scss']
})
export class FlashMessagesComponent implements OnInit {
  private _defaults = {
    text: 'default message',
    cssClass: ''
  };

  text: string;
  messages: FlashMessageInterface[] = [];
  _grayOut: boolean = false;

  constructor(
    private _flashMessagesService: FlashMessagesService,
    private _cdRef: ChangeDetectorRef
  ) {
    this._flashMessagesService.show = this.show.bind(this);
    this._flashMessagesService.grayOut = this.grayOut.bind(this);
  }

  ngOnInit() {}

  show(text?: string, options = {}): void {
    let defaults = {
      timeout: 2500,
      cssClass: ''
    };

    for (var attrname in options) {
      defaults[attrname] = options[attrname];
    }

    let message = new FlashMessage(text, defaults.cssClass);
    this.messages.push(message);
    this._cdRef.detectChanges();

    window.setTimeout(() => {
      this._remove(message);
      this._cdRef.detectChanges();
    }, defaults.timeout);
  }

  grayOut(value = false) {
    this._grayOut = value;
  }

  private _remove(message: FlashMessageInterface) {
    this.messages = this.messages.filter(msg => msg.id !== message.id);
  }
}
