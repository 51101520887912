import { EquipementWs } from './equipement.ws';
import { ReservationTypesWS } from './reservation.types.ws';
import { HeadquartersWS } from './headquarters.ws';

export class RoomListWs {
  dateFrom: Date;
  dateTo: Date;
  assistants: number;
  reason: string;
  equipment: EquipementWs[];
  dateFromInMillis: number;
  dateToInMillis: number;
  periodicalDateTo: Date;
  type: ReservationTypesWS;
  duration: number;
  headquartersId: number;

  constructor(
    dateFrom: Date,
    dateTo: Date,
    reason: string,
    assistants?: number,
    equipment?: EquipementWs[],
    type?: ReservationTypesWS,
    periodicalDateTo?: Date,
    duration?: number,
    headquartersId?: number
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.reason = reason;
    this.dateFromInMillis = this.dateFrom.getTime();
    this.dateToInMillis = this.dateTo.getTime();

    if (assistants) {
      this.assistants = assistants;
    }

    if (equipment) {
      this.equipment = equipment;
    }

    if (type) {
      this.type = type;
    }

    if (periodicalDateTo) {
      this.periodicalDateTo = periodicalDateTo;
    }

    if (duration) {
      this.duration = duration;
    }

    if (headquartersId) {
      this.headquartersId = headquartersId;
    }
  }
}
