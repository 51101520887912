<div class="checkin-code">
  <!-- Spinner -->
  <app-spinner></app-spinner>

  <form
    class="row mui-form"
    [formGroup]="checkinCodeForm"
    (ngSubmit)="checkin()"
    novalidate
  >
    <!-- nfc -->
    <div class="form-group col-sm-12 mui-textfield">
      <label for="nfc">{{ 'check_in_with_code_nfc' | messagesPipe }}</label>
      <input
        type="text"
        placeholder="{{ 'check_in_with_code_nfc_placeholder' | messagesPipe }}"
        class="form-control"
        formControlName="nfc"
      />
      <span
        class="required"
        [hidden]="
          checkinCodeForm.controls.nfc.valid ||
          !checkinCodeForm.controls.nfc.touched
        "
        >{{ 'error_required' | messagesPipe }}</span
      >
    </div>

    <div class="placed-button">
      <button
        type="submit"
        class="checkin button-by"
        [disabled]="!checkinCodeForm.valid"
      >
        {{ 'reservation_checkin' | messagesPipe }}
      </button>
    </div>
  </form>
</div>
