import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { finalize } from 'rxjs/operators';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AttendanceService } from 'src/app/shared/services/attendance.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { environment, LANGUAGE } from '../../../../environments/environment';
import { LOCAL_CURRENT_USER } from '../../../core/constants/const';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { UserService } from '../../../shared/services/user.service';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-modal-language',
  templateUrl: './modal-language.component.html',
  styleUrls: ['./modal-language.component.scss'],
  animations: [slideDetailsBooking()],
})
export class ModalLanguageComponent implements OnInit {
  user: UserLdapWs;
  languageForm: FormGroup;
  messagesPipe = new MessagesPipe();
  mapLanguages = new Map();
  languageDefault: string;

  constructor(
    private attendanceService: AttendanceService,
    private flashMessagesService: FlashMessagesService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private azureService: MsalService,
    private router: Router,
    private sideModalService: SideModalService
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.user =
        this.router.getCurrentNavigation().extras.state.assistancesList;
    }
  }

  ngOnInit() {
    this.createForm();
    this.getLanguageDefault();
    this.getListLanguages();
  }

  createForm() {
    this.languageForm = new FormGroup({
      languages: new FormControl('', [Validators.required]),
    });
  }

  getLanguageDefault() {
    this.userService
      .getLanguage(this.authenticationService.getToken())
      .subscribe((response) => {
        if (response) {
          this.languageDefault = response.text;
        }
      });
  }

  getListLanguages() {
    let languages: any;
    if (this.portugueseLanguageDisabled()) {
      languages = LANGUAGE.filter((lang) => lang !== 'pt' && lang !== 'pt-br');
    } else {
      languages = LANGUAGE;
    }

    languages.forEach((element) => {
      this.mapLanguages.set(
        element,
        this.messagesPipe.transform(`language_${element}`)
      );
    });
  }

  save() {
    const language = this.languageForm.controls['languages'].value;
    this.userService
      .updateLanguage(this.authenticationService.getToken(), language)
      .pipe(
        finalize(() => {
          this.getLanguageDefault();
          this.sideModalService.closeSideModal();
          this.logout();
        })
      )
      .subscribe((response) => {
        this.flashMessagesService.show(
          this.messagesPipe.transform('language_update_ok'),
          { cssClass: 'alert-success', timeout: 3000 }
        );
      });
  }

  portugueseLanguageDisabled(): boolean {
    return environment['portugueseLanguageDisabled'];
  }

  logout() {
    if (environment['use_azure']) {
      localStorage.removeItem(LOCAL_CURRENT_USER);
      this.azureService.logout();
    } else {
      this.authenticationService.logout();
    }
  }
}
