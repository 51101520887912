<app-spinner></app-spinner>

<div class="row question-and-answers-list">
  <!-- ### Col 1: List ###  -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'question-and-answers_list_title' | messagesPipe }}</h2>

    <!-- No in progress -->
    <div class="no-question-and-answers" *ngIf="listTreeMe?.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'question-and-answers_list_not_progress' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card" *ngFor="let itemMe of listTreeMe">
      <div class="detail">
        <span class="detail--title"> {{ itemMe?.tree?.description }}</span>
        <span class="detail--date">
          {{ itemMe?.dateTreeUserLdap | date: 'medium' }}
        </span>
      </div>
      <span class="state">{{ itemMe?.treeUserLdapStatus?.name }}</span>

      <!-- Card Options -->
      <div class="card-options">
        <div class="available-options">
          <span
            class="available-options__cancel"
            (click)="deleteTree(itemMe.id)"
          >
            {{ 'question-and-answers_list_delete_title' | messagesPipe }}
          </span>

          <span class="available-options__edit" (click)="editTree(itemMe.id)">
            {{ 'question-and-answers_list_next_title' | messagesPipe }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- ### Col 2: History ###-->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reservation_historical_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-question-and-answers" *ngIf="listTreeHistory.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'reservation_historical_description' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card history" *ngFor="let itemHistory of listTreeHistory">
      <div class="detail">
        <span class="detail--title"> {{ itemHistory?.tree?.description }}</span>
        <span class="detail--date">
          {{ itemHistory?.dateTreeUserLdap | date: 'medium' }}
        </span>
      </div>
      <span class="state">{{ itemHistory?.treeUserLdapStatus?.name }}</span>
    </div>
  </div>
</div>

<!--  ### Buttons ### -->
<div class="add-question-and-answers">
  <button
    type="button"
    class="vdp-icon-mas add"
    (click)="showButtons()"
    [ngClass]="{ closed: showOptionsButtons }"
  ></button>
  <div
    class="question-and-answers-list"
    id="question-and-answers-list"
    [@slideButtons]="getSlideButtonsClass()"
  >
    <div class="question-and-answers-list-buttons">
      <!-- Option 1 -->
      <button type="button" (click)="newQuestion()">
        {{ 'question-and-answers_list_button' | messagesPipe }}
        <i class="vdp-buscar-usuario"></i>
      </button>
    </div>
  </div>
</div>

<side-modal></side-modal>
