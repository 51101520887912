import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges
} from '@angular/core';
import { slideDetailsBooking } from '../../../shared/utils/reservation.list.animations';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_ACTIVE_OPACITY,
  ANIMATE_CLASS_INACTIVE,
  ANIMATE_CLASS_INACTIVE_OPACITY
} from '../../../core/constants/consts';
import { RoomWs } from '../../../core/models/room.ws';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { RoomDetailEmitter } from '../../../core/models/room.detail.emitter';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'roomSpeedDetail',
  templateUrl: 'room.speed.detail.component.html',
  styleUrls: ['room-speed-detail.scss'],
  animations: [slideDetailsBooking()]
})
export class RoomSpeedDetailComponent implements OnInit, OnChanges {
  @Input() roomDetail: RoomWs;
  @Input() dateFromInMillis: number;
  @Input() buildingName: string;
  @Input() duration: number;
  @Input() reservationBtnDisabled: boolean;
  @Output() closeRoomDetailEmitter = new EventEmitter();
  @Output() roomReserveEmitter = new EventEmitter<RoomDetailEmitter>();
  dateFrom: Date;
  dateTo: Date;
  hours: string[];

  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.hours = this.utils.getSpeedReservationsHours();
  }

  ngOnChanges() {
    this.dateFrom = new Date(this.dateFromInMillis);
    this.dateTo = new Date(this.dateFromInMillis);
    this.dateTo.setMinutes(this.dateTo.getMinutes() + this.duration);
  }

  getClassScheduleDiv(dateIni: any, dateEnd: any) {
    return this.utils.getSpeedClassScheduleDiv(this.hours, dateIni, dateEnd);
  }

  getAssistants(): string {
    return `${this.roomDetail.capacityMin}/${this.roomDetail.capacityMax}`;
  }

  closeDetail() {
    this.closeRoomDetailEmitter.emit();
  }

  getClassLaunchTime(): string {
    let lunchDateIni = new Date(this.roomDetail.lunchStartingTime);
    let lunchDateEnd = new Date(this.roomDetail.lunchEndingTime);

    return `lunch start-${this.utils.getSpeedPosHour(
      this.hours,
      lunchDateIni
    )} end-${this.utils.getSpeedPosHour(this.hours, lunchDateEnd)}`;
  }

  getRoomDetailAnimation(): string {
    if (this.roomDetail.id != null) {
      return ANIMATE_CLASS_ACTIVE;
    } else {
      return ANIMATE_CLASS_INACTIVE;
    }
  }

  getRoomDetailAnimationOpacity(): string {
    if (this.roomDetail.id != null) {
      return ANIMATE_CLASS_ACTIVE_OPACITY;
    } else {
      return ANIMATE_CLASS_INACTIVE_OPACITY;
    }
  }

  addMinimunHour(date: Date) {
    this.utils.addSpeedMinimunHour(date);
  }

  addMaximunHour(date: Date) {
    this.utils.addSpeedMaximunHour(date);
  }

  canChangeDates(dateMin: Date, dateMax: Date): boolean {
    return this.utils.canChangeDates(dateMin, dateMax, 10);
  }

  canChangeDateFrom(): boolean {
    if (
      this.utils.canChangeDateFrom(
        this.dateFrom,
        this.roomDetail,
        this.authenticationService.getMinReservationTime()
      )
    ) {
      if (this.isMinuteMaxDifference()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  canChangeDateTo(): boolean {
    if (this.utils.canChangeDateTo(this.dateTo, this.roomDetail)) {
      if (this.isMinuteMaxDifference()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isMinuteMaxDifference(): boolean {
    let dateAux = new Date(this.dateTo.getTime() - this.dateFrom.getTime());

    if (dateAux.getMinutes() >= 30) {
      return true;
    } else {
      return false;
    }
  }

  reserveRoom() {
    let dateAux = new Date(this.dateTo.getTime() - this.dateFrom.getTime());

    let roomDetailEmmiter = new RoomDetailEmitter(
      this.roomDetail,
      this.dateFrom,
      null,
      dateAux.getMinutes()
    );
    this.roomReserveEmitter.emit(roomDetailEmmiter);
  }
}
