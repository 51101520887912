<div class="assign-place">
  <!-- Spinner -->
  <app-spinner></app-spinner>

  <form
    class="row mui-form"
    [formGroup]="languageForm"
    (ngSubmit)="save()"
    novalidate
  >
    <!-- Languages -->
    <div class="select-by form-group select-group">
      <div class="mui-select">
        <label for="languages">{{
          'language_text_available' | messagesPipe
        }}</label>
        <select
          id="languages"
          name="languages"
          class="form-control"
          formControlName="languages"
        >
          <option [value]="0" [selected]="true">
            <!--languageDefault-->
            {{ 'select' | messagesPipe }}
          </option>
          <option
            *ngFor="let language of mapLanguages | keyvalue"
            [value]="language.key"
            [selected]="languageDefault === language.key"
          >
            {{ language.value }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-12 row-security">
      <button
        type="submit"
        class="check-availability button-by"
        [disabled]="
          languageForm.controls['languages'].value === languageDefault ||
          languageForm.controls['languages'].value == 0
        "
      >
        {{ 'language_assign' | messagesPipe }}
      </button>
    </div>
  </form>
</div>
