import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideModalService {
  constructor() {}

  openSideModal: (
    nameComponent: string,
    params: any,
    title: string,
    relativeTo: ActivatedRoute,
    outside?: boolean
  ) => void;
  subjectSideModal: (relativeTo: ActivatedRoute) => Observable<any>;
  closeSideModal: (data?: any) => void;
}
