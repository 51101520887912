import { Component, OnInit, ViewChild } from "@angular/core";
import { PARKING_TYPE_FIJA } from "src/app/core/constants/consts";
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { MessagesPipe } from "src/app/shared/pipes/messages.pipe";
import { NavigatorRefreshService } from "src/app/shared/services/navigator.refresh.service";
import { MyDatePicker } from "../../shared/components/date_picker/datepicker.component";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { DalkonService } from "../../shared/services/dalkon.service";
import { FlashMessagesService } from "../../shared/services/flash-messages.service";
import { ParkingService } from "../../shared/services/parking.service";
import { Utils } from "../../shared/utils/utils";

@Component({
  selector: "parkingComponent",
  templateUrl: "parking.component.html",
  styleUrls: ["parking.component.scss"],
})
export class ParkingComponent implements OnInit {
  parkingData: any;
  parkingStatus: string;
  sidebarStatus: boolean;
  parkingDate: Date;
  datepickerOptions: any;
  permissions: MenuPermissions;
  checked1: boolean;
  checked2: boolean;
  checked3: boolean;
  list: any;
  date1Status: boolean;
  date2Status: boolean;
  date3Status: boolean;
  parkingType: string;
  hideCederHoy: boolean = false;
  cedidaHasta: string;
  fechaSorteo: string;
  @ViewChild("from1", null) from1: MyDatePicker;
  @ViewChild("to1", null) to1: MyDatePicker;
  @ViewChild("from2", null) from2: MyDatePicker;
  @ViewChild("to2", null) to2: MyDatePicker;
  @ViewChild("from3", null) from3: MyDatePicker;
  @ViewChild("to3", null) to3: MyDatePicker;
  parkingSpotMessage: string;
  yieldParkingSpotDateMessage: string;
  requestParkingSpotMessage: string;
  yieldParkingSpotMessage: string;
  yieldOrRequestMessage: string;
  yieldParkingSpotTemporaryMessage: string;
  yieldParkingFloor: string;
  readonly PARKING_TYPE_FIJA: string = PARKING_TYPE_FIJA;

  constructor(
    private parkingService: ParkingService,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private dalkonService: DalkonService,
    private flashMessagesService: FlashMessagesService,
    private navigatorRefreshService: NavigatorRefreshService
  ) {}

  ngOnInit() {
    const messagesPipe = new MessagesPipe();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      messagesPipe.transform('menu_parking')
    );
    this.checkParking();
    this.permissions = this.authenticationService.getPermissionsForCurrentUser();
  }

  isParkingTipoFija(): boolean {
    if (this.parkingData) {
      return this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA;
    }

    return false;
  }

  checkParking() {
    this.dalkonService
      .obtainData(this.authenticationService.getToken())
      .subscribe((res) => {
        this.parkingData = res;
        this.getMessages();
        if (this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA) {
          this.parkingType = PARKING_TYPE_FIJA;
          this.listTemporarilyRelease();
          if (this.parkingData.Plaza_Cedida == "true") {
            this.hideCederHoy = true;
          } else {
            this.hideCederHoy = false;
          }
        } else {
          this.parkingType = "Otra";
          this.hideCederHoy = true;
          //let formatSorteo = new Date(Date.parse( this.parkingData.Fecha_sorteo));

          this.fechaSorteo = "";
          if (this.parkingData.Fecha_sorteo) {
            let dateTimeFullAux = this.parkingData.Fecha_sorteo.split(" ");
            let dateAux = dateTimeFullAux[0].split("-");
            let timeAux = dateTimeFullAux[1].split(":");

            let formatSorteo = new Date(
              dateAux[0],
              dateAux[1] - 1,
              dateAux[2],
              timeAux[0],
              timeAux[1],
              timeAux[2],
              0
            );
            this.fechaSorteo = formatSorteo.toLocaleDateString("es-ES", {
              day: "numeric",
              month: "short",
            });
          }

          this.listAvailableParking();
        }
      });
  }

  listTemporarilyRelease() {
    this.parkingService.listTemporarilyRelease().subscribe((dates) => {
      this.clearData();
      this.setDatepickerOptions();
      this.list = dates.Valor_Devuelto;
      let index: number = 0;
      for (let date of dates.Valor_Devuelto) {
        index++;
        let formatFrom = new Date(Date.parse(date.FechaDesde));
        let formatTo = new Date(Date.parse(date.FechaHasta));

        switch (index) {
          case 1:
            this.utils.setDatePickerDate(formatFrom, this.from1);
            this.utils.setDatePickerDate(formatTo, this.to1);
            this.checked1 = true;
            let today = new Date();
            if (
              formatFrom.getDate() == today.getDate() &&
              formatFrom.getMonth() == today.getMonth() &&
              formatFrom.getFullYear() == today.getFullYear()
            ) {
              this.cedidaHasta = formatTo.toLocaleDateString("es-ES", {
                day: "numeric",
                month: "short",
              });
            }

            break;
          case 2:
            this.utils.setDatePickerDate(formatFrom, this.from2);
            this.utils.setDatePickerDate(formatTo, this.to2);
            this.checked2 = true;
            break;
          case 3:
            this.utils.setDatePickerDate(formatFrom, this.from3);
            this.utils.setDatePickerDate(formatTo, this.to3);
            this.checked3 = true;
            break;
        }
      }
      this.checkDateStatus();
    });
  }

  listAvailableParking() {
    this.parkingService.listAvailableParking().subscribe((dates) => {
      this.clearData();
      this.setDatepickerOptions();
      this.list = dates.Valor_Devuelto;
      let index: number = 0;
      for (let date of dates.Valor_Devuelto) {
        index++;
        let formatFrom = new Date(Date.parse(date.FechaDesde));
        let formatTo = new Date(Date.parse(date.FechaHasta));

        switch (index) {
          case 1:
            this.utils.setDatePickerDate(formatFrom, this.from1);
            this.utils.setDatePickerDate(formatTo, this.to1);
            this.checked1 = true;
            break;
          case 2:
            this.utils.setDatePickerDate(formatFrom, this.from2);
            this.utils.setDatePickerDate(formatTo, this.to2);
            this.checked2 = true;
            break;
          case 3:
            this.utils.setDatePickerDate(formatFrom, this.from3);
            this.utils.setDatePickerDate(formatTo, this.to3);
            this.checked3 = true;
            break;
        }
      }
      this.checkDateStatus();
    });
  }

  temporarilyFreeParking(from, to) {
    this.parkingService.temporarilyFreeParking(from, to).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            "Tu plaza ha sido cedida correctamente"
          ),
            {
              cssClass: "alert-danger",
              timeout: 3000,
            };
          this.checkParking();
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: "alert-danger", timeout: 3000 };
        }
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          "Solicitud incorrecta. Revise las fechas."
        ),
          {
            cssClass: "alert-danger",
            timeout: 3000,
          };
        this.checkParking();
      }
    );
  }

  requestInterval(from, to) {
    this.parkingService.requestInterval(from, to).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show("Has solicitado plaza correctamente"),
            {
              cssClass: "alert-danger",
              timeout: 3000,
            };
          this.checkParking();
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: "alert-danger", timeout: 3000 };
          this.checkParking();
        }
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          "Solicitud incorrecta. Revise las fechas."
        ),
          {
            cssClass: "alert-danger",
            timeout: 3000,
          };
        this.checkParking();
      }
    );
  }

  freeParking() {
    let now = "true";
    this.parkingService.temporarilyFreeParking("", "", now).subscribe(
      (res) => {
        if (res.Valor_Devuelto) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            "Tu plaza ha sido cedida correctamente"
          ),
            {
              cssClass: "alert-danger",
              timeout: 3000,
            };
          this.hideCederHoy = true;
          this.checkParking();
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: "alert-danger", timeout: 3000 };
        }
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(error),
          { cssClass: "alert-danger", timeout: 3000 };
        this.checkParking();
      }
    );
  }

  setDatepickerOptions() {
    if (this.parkingType == PARKING_TYPE_FIJA) {
      this.datepickerOptions = this.utils.setDatepickerOptions();
    } else {
      this.datepickerOptions = this.utils.setDatepickerOptionsRequest();
    }
  }

  onDateChanged($event) {
    this.checkDateStatus();
    if ($event.formatted.length > 0) {
      this.parkingDate = new Date();
      this.parkingDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.parkingDate = null;
    }
  }

  toggleSidebar() {
    this.sidebarStatus = !this.sidebarStatus;
  }

  clearData() {
    this.from1.clearDate();
    this.from2.clearDate();
    this.from3.clearDate();
    this.to1.clearDate();
    this.to2.clearDate();
    this.to3.clearDate();
    this.checked1 = false;
    this.checked2 = false;
    this.checked3 = false;
  }

  checkDateStatus() {
    this.checkDate1();
    this.checkDate2();
    this.checkDate3();
  }

  checkDate1() {
    if (this.from1.selectedDate.day == 0 || this.to1.selectedDate.day == 0) {
      this.date1Status = false;
    } else {
      this.date1Status = true;
    }
  }

  checkDate2() {
    if (this.from2.selectedDate.day == 0 || this.to2.selectedDate.day == 0) {
      this.date2Status = false;
    } else {
      this.date2Status = true;
    }
  }

  checkDate3() {
    if (this.from3.selectedDate.day == 0 || this.to3.selectedDate.day == 0) {
      this.date3Status = false;
    } else {
      this.date3Status = true;
    }
  }

  sendDate(from, to, index) {
    let formatFrom = `${from.selectedDate.year}-${from.selectedDate.month}-${from.selectedDate.day}`;
    let formatTo = `${to.selectedDate.year}-${to.selectedDate.month}-${to.selectedDate.day}`;
    if (from.selectedDate.day == 0 || to.selectedDate.day == 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show("Debes seleccionar una fecha correcta"),
        {
          cssClass: "alert-danger",
          timeout: 3000,
        };
    } else {
      if (this.parkingType == PARKING_TYPE_FIJA) {
        this.temporarilyFreeParking(formatFrom, formatTo);
      } else {
        this.requestInterval(formatFrom, formatTo);
      }
      switch (index) {
        case 0:
          this.checked1 = true;
          break;
        case 1:
          this.checked2 = true;
          break;
        case 2:
          this.checked3 = true;
          break;
      }
    }
  }

  removeDate(id, index) {
    if (this.parkingType == PARKING_TYPE_FIJA) {
      this.parkingService
        .cancelTemporarilyReleasedParking(id)
        .subscribe((res) => {
          if (res.Errores == null) {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              "Has cancelado la cesión de tu plaza"
            ),
              {
                cssClass: "alert-danger",
                timeout: 3000,
              };

            switch (index) {
              case 0:
                this.checked1 = false;
                this.from1.clearDate();
                this.to1.clearDate();
                break;
              case 1:
                this.checked2 = false;
                this.from2.clearDate();
                this.to2.clearDate();
                break;
              case 2:
                this.checked3 = false;
                this.from2.clearDate();
                this.to2.clearDate();
                break;
            }
            this.checkParking();
          } else {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(res.Errores),
              { cssClass: "alert-danger", timeout: 3000 };
          }
        });
    } else {
      this.parkingService.cancelParking(id).subscribe((res) => {
        if (res.Errores == null) {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show("Has cancelado la solicitud de plaza"),
            {
              cssClass: "alert-danger",
              timeout: 3000,
            };

          switch (index) {
            case 0:
              this.checked1 = false;
              this.from1.clearDate();
              this.to1.clearDate();
              break;
            case 1:
              this.checked2 = false;
              this.from2.clearDate();
              this.to2.clearDate();
              break;
            case 2:
              this.checked3 = false;
              this.from2.clearDate();
              this.to2.clearDate();
              break;
          }
          this.checkParking();
        } else {
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(res.Errores),
            { cssClass: "alert-danger", timeout: 3000 };
        }
      });
    }
  }

  actionDate(from, to, index) {
    switch (index) {
      case 0:
        if (this.checked1) {
          let id: number;
          if (this.parkingType == PARKING_TYPE_FIJA) {
            id = this.list[index].idLibre;
          } else {
            id = this.list[index].idRequest;
          }
          this.removeDate(id, index);
        } else {
          this.sendDate(from, to, index);
        }
        break;
      case 1:
        if (this.checked2) {
          let id: number;
          if (this.parkingType == PARKING_TYPE_FIJA) {
            id = this.list[index].idLibre;
          } else {
            id = this.list[index].idRequest;
          }
          this.removeDate(id, index);
        } else {
          this.sendDate(from, to, index);
        }
        break;
      case 2:
        if (this.checked3) {
          let id: number;
          if (this.parkingType == PARKING_TYPE_FIJA) {
            id = this.list[index].idLibre;
          } else {
            id = this.list[index].idRequest;
          }
          this.removeDate(id, index);
        } else {
          this.sendDate(from, to, index);
        }
        break;
    }
    this.checkParking();
  }

  private getMessages() {
    const messagesPipe = new MessagesPipe();
    if (
      this.parkingData != null &&
      this.parkingData.parkingTipoTitle != "Sin plaza"
    ) {
      this.parkingSpotMessage =
        messagesPipe.transform("parking_with_assigned_parkingSpot_today") +
        this.parkingData.Parking_plaza_Codigo;
    } else {
      this.parkingSpotMessage = messagesPipe.transform(
        "parking_without_assigned_parkingSpot"
      );
    }
    if (this.parkingData != null && this.parkingData.Plaza_Cedida == "true") {
      this.yieldParkingSpotDateMessage =
        messagesPipe.transform("parking_yielded_parkingSpot") +
        this.cedidaHasta;
    } else {
      this.yieldParkingSpotDateMessage = messagesPipe.transform(
        "parking_assigned_fixed_parkingSpot"
      );
    }
    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.requestParkingSpotMessage = messagesPipe.transform(
        "parking_request_seeDetails_btn"
      );
    } else {
      this.requestParkingSpotMessage = messagesPipe.transform(
        "parking_request_btn"
      );
    }
    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldParkingSpotMessage = this.parkingData.Parking_plaza_Codigo;
      this.yieldParkingFloor = this.parkingData.Parking_plaza_planta;
    } else {
      this.yieldParkingSpotMessage = messagesPipe.transform(
        "parking_request_btn"
      );
    }
    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldOrRequestMessage = messagesPipe.transform(
        "parking_parkingSpot_absence"
      );
    } else {
      this.yieldOrRequestMessage = messagesPipe.transform(
        "parking_request_periods"
      );
    }
    if (
      this.parkingData != null &&
      this.parkingData.Parking_plaza_Tipo == PARKING_TYPE_FIJA
    ) {
      this.yieldParkingSpotTemporaryMessage = messagesPipe.transform(
        "parking_yield_temporary"
      );
    } else {
      this.yieldParkingSpotTemporaryMessage = messagesPipe.transform(
        "parking_request_btn"
      );
    }
  }
}
