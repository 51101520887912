<a
  *ngIf="module.type.code == type_menu_external.typePersonalizado.code"
  (click)="navigateToCustomModule()"
  [routerLinkActive]="'active'"
  [class.active]="isActive(module.id)"
>
  <img class="color-img" [src]="module.iconFile" />
  <span>{{ module.name }}</span>
</a>
<a
  *ngIf="module.type.code != type_menu_external.typePersonalizado.code"
  [href]="url"
  [class.active]="isActive(module.id)"
  target="_blank"
>
  <img class="color-img" [src]="module.iconFile" />
  <span>{{ module.name }}</span>
</a>
