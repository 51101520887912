<div class="main-container">
  <div class="fadeInLayer">
    <div class="assistance">
      <div #calendar>
        <div class="row header-calendar">
          <div class="col-3">
            <button (click)="changeDate(-1)" class="btn-prev">
              {{ 'assistance_bottom_previous' | messagesPipe }}
            </button>
          </div>
          <div class="col-3">
            <div class="mui-select">
              <select
                class="form-control"
                id="selectMonth"
                name="selectMonth"
                (change)="changeMonthList($event.target.value)"
              >
                <option
                  *ngFor="let month of months"
                  [value]="month.value"
                  [selected]="monthSelected() === month.value"
                >
                  <span>{{ month.desc }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="mui-select">
              <select
                class="form-control"
                id="selectAnyo"
                name="selectAnyo"
                (change)="changeAnyoList($event.target.value)"
              >
                <option
                  *ngFor="let anyo of anyos"
                  [value]="anyo"
                  [selected]="anyoSelected() === anyo"
                >
                  <span>{{ anyo }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="col-3" [style.text-align]="'right'">
            <button (click)="changeDate(1)" class="btn-next">
              {{ 'assistance_bottom_next' | messagesPipe }}
            </button>
          </div>
        </div>
        <ol>
          <li *ngFor="let day of week" class="day-name">
            {{ day }}
          </li>
          <li
            (click)="clickDay(day)"
            [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
            *ngFor="let day of selectList; let first = first"
          >
            <!-- Configuración Asistencia -->
            <div
              *ngIf="!assistanceUser"
              [ngClass]="[
                getClassSelected(day),
                day.festive
                  ? 'dayCalendarFestive'
                  : day.disabled
                  ? 'dayCalendarDisabled'
                  : 'dayCalendar'
              ]"
              [style.background-color]="day.color"
              [style.border-width]="day.currentDay ? 'thick' : ''"
              [style.border-style]="day.currentDay ? 'solid' : ''"
              [style.border-color]="day.currentDay ? day.borderColor : ''"
            >
              <div class="contentIcon">
                <span [style.color]="day.textColor">{{ day?.value }}</span>
                <div class="iconsStyle" *ngIf="day.assignments?.id">
                  <div class="row">
                    <div class="col columStyle">
                      <i
                        *ngIf="day.isParking"
                        class="float-left vdp-parking_big"
                      ></i>
                      <i
                        *ngIf="day.isDinner"
                        class="float-right vdp-comedor_big"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Configuración Asistencia-Usuario -->
            <div
              *ngIf="assistanceUser"
              [ngClass]="[
                getClassSelected(day),
                day.festive
                  ? 'dayCalendarFestive'
                  : day.disabled
                  ? 'dayCalendarDisabled'
                  : 'dayCalendar'
              ]"
              [style.background-color]="day.color"
              [style.border-width]="day.currentDay ? 'thick' : ''"
              [style.border-style]="day.currentDay ? 'solid' : ''"
              [style.border-color]="day.currentDay ? day.borderColor : ''"
            >
              <div class="contentIcon">
                <span [style.color]="day.textColor">{{ day?.value }}</span>
                <div class="assistanceUserClass" *ngIf="day.assignments?.day">
                  <div class="row">
                    <div class="col columStyle">
                      <i
                        [style.visibility]="
                          !day.alertAssist ? 'hidden' : 'visible'
                        "
                        class="float-right vdp-salas-incidencia"
                      ></i>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col columStyle">
                      <span class="numb float-left">{{
                        day?.numberAssist
                      }}</span>
                      <i class="float-right vdp-buscar-usuario"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div class="bottom">
        <span class="bottom__title">{{
          'assistance_text_legend' | messagesPipe
        }}</span>
        <div class="row bottom__option">
          <div class="col-12">
            <label *ngFor="let legend of configLegend | keyvalue">
              {{ legend.value }}
              <svg
                [style.background]="legend.key"
                aria-hidden="true"
                focusable="false"
              ></svg>
            </label>
          </div>
          <div class="col-12 legendWarning" *ngIf="getAlertAssist()">
            <i class="vdp-salas-incidencia"></i>
            <span>{{ 'assistance_text_legend_alert' | messagesPipe }}</span>
          </div>
        </div>
      </div>
      <div class="save" *ngIf="!assistanceUser">
        <button
          type="button"
          [ngClass]="[getClassSelectedSave()]"
          (click)="save()"
          [disabled]="!getClassSelectedActive()"
        >
          <span>{{ 'assistance_bottom_save' | messagesPipe }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- (setDateFormEmmitter)="setMonthAndYearForModalEditClose()" -->
<app-modal-edit-assistance-iber
  (closeFormEmmitter)="closeModalEditAssistance()"
  [showForm]="showEditAssistance"
  [assistanceConfirm]="assistanceConfirm"
  [assistancesList]="selectedDays"
  [assistanceSelected]="assistanceSelected"
  [assistanceInfo]="assistanceInfo"
  [mode]="modeAssistance"
  [listUsersForDay]="listUsersForDay"
></app-modal-edit-assistance-iber>
