import { Component, Input, OnInit } from '@angular/core';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import {
  TYPE_CODE_VEHICLE,
  TYPE_CODE_VEHICLE_RESERVE,
} from '../../../../core/constants/consts';
import { UserLdapWs } from '../../../../core/models/user.ldap.ws';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-calendar-view-assistance',
  templateUrl: './calendar-view-assistance.component.html',
  styleUrls: ['./calendar-view-assistance.component.scss'],
})
export class CalendarViewAssistanceComponent implements OnInit {
  @Input() day: any;
  @Input() attendances: any;
  @Input() selectList: any;

  dateNew: Date;
  maxDayAssistance: number;
  permissions: MenuPermissions;
  user: UserLdapWs;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.dateNew = new Date();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.user = this.authenticationService.getCurrentUser();
  }

  getClassSelected(day: any) {
    let ret = '';
    if (day.selected) {
      ret = ` selected`;
    }
    if (day.disabledWhitAssistance) {
      ret += ` dayCalendarDisabledWhitAssistance`;
    }
    if (day.assignments && (day.assignments.id || day.assignments.day)) {
      ret += ` assignment`;
    }
    return ret;
  }

  getIconVehicule(day: any) {
    if (
      day?.assignments?.draw?.attendanceUserDraw &&
      day?.assignments?.draw?.attendanceUserDraw.userSelectVehicle
    ) {
      switch (
      day?.assignments?.draw?.attendanceUserDraw?.userSelectVehicle.code
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.CombustionCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';

        case 'BICICLETA':
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    }
  }
  getIconVehiculeResult(day: any) {
    if (day?.assignments?.draw?.attendanceResultDraw?.hasParking) {
      switch (
      day?.assignments?.draw?.attendanceResultDraw?.hasParking?.sizeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';

        case 'BICICLETA':
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    }

  }
  getIconVehiculeResultRecharge(day: any) {
    if (day?.assignments?.draw?.attendanceResultDraw?.hasRechargeParking) {
      switch (
      day?.assignments?.draw?.attendanceResultDraw?.hasRechargeParking
        ?.sizeCode
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.NormalCar:
          return 'vdp-parking_big';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCarN2:
          return 'vdp-calendarParkingCharge';

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';

        case 'BICICLETA':
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    }

  }
}
