import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { UserService } from 'src/app/shared/services/user.service';
import { NAME } from 'src/environments/environment';

@Component({
  selector: 'app-login-azure',
  templateUrl: './login-azure.component.html',
  styleUrls: ['./login-azure.component.scss'],
})
export class LoginAzureComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  messagesPipe = new MessagesPipe();
  title = NAME;
  isIframe = false;
  errorLoging = false;
  loggedIn = false;
  accessToken: string;
  userEmail: string;
  clientType = 'angular';
  behavior: BehaviorSubject<any> = new BehaviorSubject<any>('');

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  result: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private loginService: LoginService,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    const account = this.checkActiveAccount();
    if (account) {
      this.authService
        .acquireTokenSilent({
          ...this.msalGuardConfig.authRequest,
        } as SilentRequest)
        .subscribe(
          (next) => {
            this.validateToken(next);
            this.msalBroadcastService.msalSubject$
              .pipe(
                filter(
                  (msg: EventMessage) =>
                    msg.eventType === EventType.LOGIN_SUCCESS ||
                    msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this._destroying$)
              )
              .subscribe((result) => {
                this.setLoginDisplay();
              });

            this.msalBroadcastService.inProgress$
              .pipe(
                filter(
                  (status: InteractionStatus) =>
                    status === InteractionStatus.None
                )
              )
              .subscribe((res) => {
                this.setLoginDisplay();
              });
          },
          (error) => {
            console.error(error);
            this.loginRedirect();
          }
        );
    } else {
      this.loginRedirect();
    }
  }

  public loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  public loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe(
          (response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.validateToken(response);
          },
          (error) => {
            window.setTimeout(() => {
              this.loginPopup();
            }, 1000);
          }
        );
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  checkActiveAccount() {
    return this.authService.instance.getActiveAccount();
  }

  validateToken(value: any) {
    this.loginService
      .validateTokenAzure(
        value.idToken,
        value.accessToken,
        value.idTokenClaims.preferred_username,
        this.clientType
      )
      .subscribe(
        (userData: any) => {
          if (userData && (userData.accessToken || userData.access_token)) {
            this.authenticationService.saveToken(userData);
            this.checkRedirection(userData);
            this.errorLoging = false;
          } else {
            this.loginDisplay = false;
            this.errorLoging = true;
            this.flashMessagesService.show(
              this.messagesPipe.transform('incorrect_login'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        },
        (error) => {
          this.loginDisplay = false;
          this.errorLoging = true;
          this.flashMessagesService.show(
            this.messagesPipe.transform('incorrect_login'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  checkRedirection(userData: any): void {
    if (this.authenticationService.getToken()) {
      this.userService
        .getLanguage(this.authenticationService.getToken())
        .pipe(
          finalize(() => {
            if (userData.headquarters && userData.headquarters.id) {
              this.router.navigate(['/menu/home']);
            } else {
              this.router.navigate(['/menu/profile']);
            }
          })
        )
        .subscribe((response) => {
          if (response) {
            localStorage.setItem('language_app', response.text);
          }
        });
    } else {
      if (userData.headquarters && userData.headquarters.id) {
        this.router.navigate(['/menu/home']);
      } else {
        this.router.navigate(['/menu/profile']);
      }
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
