import { UserLdap } from './user.ldap';
export class Vehicle {
  id?: number;
  color: string;
  license: string;
  main: boolean;
  model: string;
  type: {
    name: string;
    type_id: number;
  };
  userLadp: UserLdap;
  active: boolean;

  constructor(
    color: string,
    license: string,
    main: boolean,
    model: string,
    type: { name: string; type_id: number },
    userLdap: UserLdap,
    id?: number,
    active?: boolean
  ) {
    if (id) {
      this.id = id;
    }
    this.color = color;
    this.license = license;
    this.main = main;
    this.model = model;
    this.type = type;
    this.userLadp = userLdap;
    this.active = active;
  }
}
