import { ReservationTypesWS } from './reservation.types.ws';
import { RoomWs } from './room.ws';
import { ReservationStatus } from './reservation.status';
import { HeadquartersWS } from './headquarters.ws';

export class ReservationWs {
  id: number;
  dateTo: any;
  dateFrom: any;
  reason: string;
  assistants: number;
  room: RoomWs;
  inProgress: boolean;
  status: ReservationStatus;
  reservationDuration: number;
  periodicalDateTo: Date;
  type: ReservationTypesWS;
  dateFromInMillis: number;
  headquarters: HeadquartersWS;

  constructor() {
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.assistants = 0;
    this.room = new RoomWs('', '', '', '', '');
    this.inProgress = false;
    this.status = new ReservationStatus();
  }
}
