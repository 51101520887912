import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MyDatePicker } from './datepicker.component';
import { InputFocusDirective } from '../../directives/datepicker.input.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MyDatePicker, InputFocusDirective],
  exports: [MyDatePicker, InputFocusDirective]
})
export class MyDatePickerModule {}
