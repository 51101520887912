<div class="assistance">
  <div>
    <!-- TITLE DATE -->
    <div class="row">
      <span>{{ assistanceSelected?.date | titlecase }}</span>
    </div>
    <form class="row mui-form" [formGroup]="assistanceForm" novalidate>
      <!-- PARKING -->
      <div class="row detailAssistance" *ngIf="statusModuleParking()">
        <!-- MOSTRAR CODE VEHICULO PARKING -->
        <div class="col-12" class="codeParking" *ngIf="showParkingSpotInfo">
          <span>{{ getMainVehicule()?.code }}</span>
        </div>
        <!-- ICONS & LABELS -->
        <div>
          <!-- ICON & LABEL PARKING PERMANENT -->
          <div
            *ngIf="
              assistanceInfo?.draw?.assignmentAttendance?.parkingSpot?.typeCode
            "
          >
            <div
              class="col-sm-12 iconAssistance"
              [ngClass]="activeIconParking()"
            >
              <i [ngClass]="getIconVehiculeResult()"></i>
            </div>

            <div class="col-sm-12">
              <label>{{ getMessageVehiculeResult() }}</label>
            </div>
          </div>
          <!-- ICON & LABEL PARKING -->
          <div
            *ngIf="
              assistanceInfo?.draw?.assignmentAttendanceRecharge?.parkingSpot
                ?.typeCode
            "
          >
            <div
              class="col-sm-12 iconAssistance"
              [ngClass]="activeIconParking()"
            >
              <i [ngClass]="getIconVehiculeResultRecharge()"></i>
            </div>

            <div class="col-sm-12">
              <label>{{ getMessageVehiculeResultRecharge() }}</label>
            </div>
          </div>
          <!-- ICON & LABEL DRAW -->
          <div
            *ngIf="
              assistanceInfo?.draw?.attendanceUserDraw?.userSelectVehicle
                ?.code &&
              getIconVehiculeResult() !== getIconVehicule() &&
              getIconVehiculeResultRecharge() !== getIconVehicule()
            "
          >
            <div
              class="col-sm-12 iconAssistance"
              [ngClass]="activeIconParking()"
            >
              <i [ngClass]="getIconVehicule()"></i>
            </div>

            <div class="col-sm-12">
              <label>{{ getMessageVehicule() }}</label>
            </div>
          </div>
        </div>
        <!-- SELECTOR DE PARKING -->
        <div class="col-6" *ngIf="enableSelectParking">
          <div class="select-by form-group select-group">
            <label for="placeParking">{{
              'assistance_text_available_vehicles' | messagesPipe
            }}</label>
            <select
              id="placeParking"
              name="placeParking"
              class="form-control"
              formControlName="placeParking"
            >
              <option [value]="0">
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="
                  let place of assistanceInfo?.parking?.parkingSizeAttendance
                "
                [value]="place?.typeId"
                [disabled]="
                  !assistanceInfo?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                "
              >
                {{ place?.size }}
                <span *ngIf="place?.textParkingNoPriority"
                  >({{ place?.textParkingNoPriority }})
                </span>
                {{
                  assistanceInfo?.parking?.parkingWaitingListEnabled &&
                  !place?.enabled
                    ? ('assistance_text_waiting' | messagesPipe)
                    : ''
                }}
              </option>
            </select>
          </div>
        </div>
        <!-- BUTTON PARKING -->
        <div class="col-12">
          <div
            class="placed-button"
            *ngIf="activeButtonParking() && !editingVehicle"
          >
            <button class="check-availability" (click)="editVehicle()">
              {{ 'assistance_bottom_edit' | messagesPipe }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="select-group" *ngIf="editingVehicle">
          <label for="placeParking">{{
            'assistance_text_available_vehicles' | messagesPipe
          }}</label>
          <select
            id="placeParking"
            name="placeParking"
            class="form-control"
            formControlName="placeParking"
          >
            <option delected disabled hidden [value]="0">
              Selecciona un vehículo
            </option>
            <option
              *ngFor="let vehicle of objectKeys(parkingVehicles)"
              [value]="vehicle?.type_id"
            >
              {{ vehicle?.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- COMEDOR  -->
      <div
        class="row detailAssistance"
        *ngIf="attendanceIntegrationDinnerDesk && assistanceInfo?.dinner"
      >
        <!-- ICON COMEDOR -->
        <div class="col-sm-12 iconAssistance" [ngClass]="activeIconDinner()">
          <i class="vdp-comedor_big"></i>
        </div>
        <!-- LABEL COMEDOR -->
        <div class="col-sm-12">
          <label
            >{{ getMessageDinner()
            }}<span
              *ngIf="
                checkDinnerDays(assistanceSelected.indexWeek) &&
                checkDinnerMonths(assistanceSelected.dateComplete) &&
                assistanceInfo?.dinner?.hasReservation
              "
              >{{ assistanceInfo?.dinner.inningFromHourString }}:{{
                assistanceInfo?.dinner.inningFromMinuteString
              }}</span
            ></label
          >
        </div>
        <!-- SELECTOR COMEDOR -->
        <div class="col-sm-6" *ngIf="enableSelectDinner">
          <div class="select-by form-group select-group">
            <label for="turn">{{
              'assistance_text_available_shifts' | messagesPipe
            }}</label>
            <select
              id="turn"
              name="turn"
              class="form-control"
              formControlName="turn"
              (change)="changeTurn($event.target.value)"
            >
              <option
                [value]="0"
                [selected]="
                  checkShiftPriority(assistanceInfo.dinner.availableTurns)
                "
              >
                {{ 'none' | messagesPipe }}
              </option>
              <option
                *ngFor="let turn of assistanceInfo.dinner.availableTurns"
                [value]="turn?.id"
                [selected]="assistanceForm.controls['turn'].value === turn.id"
              >
                {{ turn?.shiftFromAux }} - {{ turn?.shiftToAux }}
              </option>
            </select>
          </div>
        </div>
        <!-- BUTTON COMEDOR -->
        <div *ngIf="enableSelectDinner">
          <div class="col-sm-12">
            <div class="placed-button">
              <button
                (click)="solictTurn()"
                class="check-availability"
                [disabled]="buttonDisabledRequestDinner || !turnOK"
              >
                {{ 'assistance_bottom_solict' | messagesPipe }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="enableButtonCancelDinner">
          <div class="placed-button">
            <button
              (click)="confirmCancelAsistTurn()"
              class="check-availability"
            >
              {{ 'assistance_bottom_cancel' | messagesPipe }}
            </button>
          </div>
        </div>
      </div>
      <!-- BUTTON DISABLED COMEDOR  -->
      <div
        class="row detailAssistance"
        *ngIf="
          attendanceIntegrationDinnerDesk &&
          !assistanceInfo?.dinner &&
          (assistanceSelected.workingHome || assistanceSelected.partTime)
        "
      >
        <div class="col-sm-12 iconAssistance">
          <i class="vdp-comedor_big"></i>
        </div>
        <div class="col-sm-12">
          <label>{{
            checkDinnerDays(assistanceSelected.indexWeek) &&
            (assistanceSelected.workingHome || assistanceSelected.partTime)
              ? ('assistance_text_not_dining_room_service' | messagesPipe)
              : null
          }}</label>
        </div>
      </div>
    </form>

    <!-- BUTTON SAVE -->
    <div class="btn-flotante" *ngIf="editingVehicle">
      <a (click)="editVehicle()">
        {{ 'cancel_reservation_btn' | messagesPipe }}
      </a>
      <a
        (click)="confirmAssistance()"
        class="reserve"
        [ngClass]="[buttonDisabledConfirm ? 'a-not-active' : '']"
      >
        {{ 'assistance_button_confirm' | messagesPipe }}
      </a>
    </div>
    <!-- BUTTON CANCEL ASSISTANCE -->
    <div class="row">
      <div
        class="col-12 cancelAssistence"
        *ngIf="!assistanceSelected?.disabledWhitAssistance"
      >
        <a (click)="confirmCancelAssistance()">
          <i class="vdp-user-x">
            {{ 'assistance_bottom_cancel_assistance' | messagesPipe }}</i
          >
        </a>
      </div>
    </div>
  </div>
</div>
