import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { TreeModel } from '../../../core/models/tree.model';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { TreeService } from '../../../shared/services/tree.service';
import { slideButtons } from '../../../shared/utils/reservation.list.animations';

@Component({
  selector: 'app-question-and-answers-list',
  templateUrl: './question-and-answers-list.component.html',
  styleUrls: ['./question-and-answers-list.component.scss'],
  animations: [slideButtons()],
})
export class QuestionAndAnswersListComponent implements OnInit {
  showOptionsButtons: boolean = false;

  listTreeHistory: TreeModel[] = [];
  listTreeMe: TreeModel[] = [];

  ie: boolean;

  messagesPipe = new MessagesPipe();

  constructor(
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private router: Router,
    private treeService: TreeService
  ) {
    this.showOptionsButtons = false;
    this.ie = false;
  }

  ngOnInit(): void {
    let userAgent: string, ieReg: RegExp;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
    this.getTreeHistoric();
    this.getTreeMe();
  }

  showButtons(): void {
    this.showOptionsButtons = !this.showOptionsButtons;
  }

  newQuestion() {
    this.router.navigate(['/menu/questionsAndAnswers/type']);
  }

  getTreeHistoric() {
    this.spinnerService.show();
    this.treeService.getTreeHistoric().subscribe({
      next: (response) => {
        this.listTreeHistory = response;
        this.spinnerService.hide();
      },
      error: () => {
        this.spinnerService.hide();
        this.listTreeHistory = [];
      },
    });
  }

  getTreeMe() {
    this.spinnerService.show();
    this.treeService.getTreeMe().subscribe({
      next: (response) => {
        this.spinnerService.hide();
        this.listTreeMe = response;
      },
      error: () => {
        this.spinnerService.hide();
        this.listTreeMe = [];
      },
    });
  }

  deleteTree(id: number) {
    this.spinnerService.show();
    this.treeService.deleteTree(id).subscribe({
      next: (response) => {
        this.getTreeMe();
        this.getTreeHistoric();
        this.flashMessagesService.show(
          this.messagesPipe.transform('question-and-answers_list_delete_ok'),
          {
            cssClass: 'alert-success',
            timeout: 3000,
          }
        );
        this.spinnerService.hide();
      },
      error: () => {
        this.getTreeMe();
        this.getTreeHistoric();
        this.flashMessagesService.show(
          this.messagesPipe.transform('question-and-answers_list_delete_error'),
          {
            cssClass: 'alert-success',
            timeout: 3000,
          }
        );
        this.spinnerService.hide();
      },
    });
  }

  editTree(id: number) {
    this.router.navigate(['menu/questionsAndAnswers/new', id]);
  }

  getSlideButtonsClass(): string {
    if (this.ie) {
      const el = document.getElementById('question-and-answers-list');
      if (this.showOptionsButtons) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    } else {
      if (this.showOptionsButtons) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }
}
