<div class="new-reservation">
  <form
    class="row mui-form"
    (ngSubmit)="checkReservations()"
    [formGroup]="reservationForm"
    novalidate
  >
    <div class="form-group col-sm-6 mui-textfield">
      <label for="fecha">{{'date' | messagesPipe}}</label>
      <datepicker
        class="custom-datepicker"
        #datePickerElement
        id="fecha"
        [options]="datepickerOptions"
        (dateChanged)="onDateChanged($event)"
      ></datepicker>
      <span class="required" [hidden]="reservationDate"
        >{{'error_required' | messagesPipe}}</span
      >
    </div>
    <div class="form-group col-sm-6 mui-textfield">
      <label for="assistants">{{'assistants' | messagesPipe}}</label>
      <input
        placeholder="0"
        type="text"
        class="form-control"
        id="assistants"
        name="assistants"
        [formControl]="reservationForm.controls['assistants']"
      />
      <span
        class="required"
        [hidden]="reservationForm.controls.assistants.valid"
        >{{'error_numeric_required' | messagesPipe}}</span
      >
    </div>
    <div class="form-group select-group col-sm-6">
      <div class="mui-select">
        <label for="hourFrom">{{'from' | messagesPipe}}</label>
        <select
          #selectHourFrom
          class="form-control"
          id="hourFrom"
          name="hourFrom"
          (change)="onChangeHourFrom($event)"
        >
          <option *ngFor="let hourFrom of hoursFrom" [value]="hourFrom">
            {{hourFrom}}
          </option>
        </select>
      </div>
      <span>:</span>
      <div class="mui-select">
        <select
          #selectMinuteFrom
          class="form-control"
          id="minuteFrom"
          name="minuteFrom"
          (change)="onChangeMinuteFrom($event)"
        >
          <option *ngFor="let minuteFrom of minutesFrom" [value]="minuteFrom">
            {{minuteFrom}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group select-group col-sm-6">
      <div class="mui-select">
        <label for="hourTo">{{'since' | messagesPipe}}</label>
        <select
          #selectHourTo
          class="form-control"
          id="hourTo"
          name="hourTo"
          (change)="onChangeHourTo($event)"
        >
          <option *ngFor="let hourTo of hoursTo" [value]="hourTo">
            {{hourTo}}
          </option>
        </select>
      </div>
      <span>:</span>
      <div class="mui-select">
        <select
          #selectMinuteTo
          class="form-control"
          id="minuteTo"
          name="minuteTo"
          (change)="onChangeMinuteTo($event)"
        >
          <option *ngFor="let minuteTo of minutesTo" [value]="minuteTo">
            {{minuteTo}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="headquarters">{{'headquarters' | messagesPipe}}</label>
        <select
          id="headquarters"
          name="headquarters"
          class="form-control"
          (change)="selectHeadquarters($event)"
        >
          <option [value]="0">{{'select' | messagesPipe}}</option>
          <option
            [value]="headquarter.id"
            *ngFor="let headquarter of headquartersList"
          >
            {{headquarter.name}}
          </option>
        </select>
        <span class="required" [hidden]="selectedHeadquartersId != 0"
          >{{'error_required' | messagesPipe}}</span
        >
      </div>
    </div>
    <div class="last-textfield form-group col-sm-12 mui-textfield">
      <label for="reason">{{'meeting_reason' | messagesPipe}}</label>
      <textarea
        placeholder="{{'reservation_reason_placeholder' | messagesPipe}}"
        id="reason"
        name="reason"
        class="form-control"
        rows="3"
        [formControl]="reservationForm.controls['reason']"
      ></textarea>
      <span class="required" [hidden]="reservationForm.controls.reason.valid"
        >{{'error_required' | messagesPipe}}</span
      >
    </div>
    <div class="col-md-12" id="periodicity">
      <label>{{'periodicity' | messagesPipe}}</label>
      <div class="periodicity-type mui-select">
        <select
          #selectReservationTypes
          class="form-control"
          id="reservationTypes"
          name="reservationTypes"
          (change)="onChangeReservationType($event)"
        >
          <option
            *ngFor="let reservationType of reservationTypes"
            [value]="reservationType.id"
          >
            {{reservationType.name}}
          </option>
        </select>
      </div>
      <div
        class="periodicity-max-date"
        [hidden]="reservationTypeSelected.id == defaultReservationTypeSelected"
      >
        <label for="fecha">{{'periodicity_max_date' | messagesPipe}}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerMaxDate
          id="fechaMax"
          [options]="datepickerOptions"
          (dateChanged)="onDateMaxPeriodicity($event)"
        ></datepicker>
        <span class="required" [hidden]="reservationMaxDate"
          >{{'error_required' | messagesPipe}}</span
        >
      </div>
    </div>
    <div class="col-sm-12 form-group mui-textfield" id="equipement">
      <span>{{'equipment' | messagesPipe}}</span>
      <div class="row equipment-items">
        <div
          class="col-xs-12 col-sm-4"
          *ngFor="let eq of equimenent"
          (click)="addEquipement(eq)"
          [ngClass]="{'redFont' : equipementSelected.indexOf(eq) > -1}"
        >
          <div class="equipment-item">
            <img [src]="eq.imageUrl" height="50px" width="50px" />
            <span>{{eq.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <button
        type="submit"
        class="check-availability"
        [disabled]="( !reservationForm.valid || !reservationDate || (reservationTypeSelected.id != defaultReservationTypeSelected && !reservationMaxDate) ) && (selectedHeadquartersId && selectedHeadquartersId > 0)"
      >
        {{'btn_view_rooms' | messagesPipe}}
      </button>
    </div>
  </form>
</div>
