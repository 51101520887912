import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { ResourceWs } from '../../core/models/resource.ws';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getResourcesListAllById(id: String): Observable<any> {
    const url = `${SERVICE_BASE_URL}/api/resourses/listAll/${id}?access_token=${this.authenticationService.getToken()}`;
    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getResourcesTypeListAll(): Observable<ResourceWs[]> {
    const url = `${SERVICE_BASE_URL}/api/resourceTypeForHour/listAll?access_token=${this.authenticationService.getToken()}`;
    return this.http
      .get(url, OPTIONS)
      .map((res: Response) => {
        return res.json() as ResourceWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getResourceForHour(): Observable<any> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/historic?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getResourceForHourReservation(
    date: number,
    resourceTypeForHourId: number,
    resourceForHourId?: number,
    token?: string
  ): Observable<any> {
    let bodyString = {
      date,
      resourceTypeForHourId,
      resourceForHourId,
    };

    let tokenSend = null;
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  addResourceForHourReservation(
    dateFrom: number,
    dateTo: number,
    resourceTypeForHourId: number,
    license?: string,
    token?: string
  ): Observable<any> {
    let bodyString: any = {
      dateFrom,
      dateTo,
      resourceTypeForHourId,
    };
    let tokenSend = null;
    if (license) {
      bodyString.license = license;
    }
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations/add?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  editResourceForHourReservation(
    dateFrom: number,
    dateTo: number,
    id: number,
    token?: string
  ): Observable<any> {
    let bodyString: any = {
      dateFrom,
      dateTo,
    };
    let tokenSend = null;
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .put(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations/edit/${id}?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getResourceForHourReservationMe(): Observable<any> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations/me?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelResourceForHourReservation(
    resourceForHourReservationId: number
  ): Observable<any> {
    let bodyString = {
      resourceForHourReservationId,
    };
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
