<div class="room-detail-header">
  <div class="image">
    <div class="image-wrapper">
      <button type="button" [hidden]="hideCloseButton">
        <i class="vdp-close-lateral-foto"></i>
      </button>
      <img [src]="room.imageUrl" *ngIf="room.imageUrl" />
    </div>
  </div>
  <div class="image-info">
    <div class="name">
      <span>Sala</span>
      <span class="meeting-room">{{ room.name }}</span>
      <span class="code" *ngIf="room.conferenceCode != null"
        >{{ 'room_conference_code_title' | messagesPipe }}
        {{ room.conferenceCode }}</span
      >
      <span class="building">{{ room.floor.building.name }}</span>
      <div class="assistants" *ngIf="room.capacityMin && room.capacityMax">
        <i class="vdp-label-asistentes"></i><span>{{ getAssistants() }}</span>
      </div>
    </div>
    <i *ngIf="room.hasIncidence" class="vdp-salas-incidencia"></i>
  </div>
</div>
