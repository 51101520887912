import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_CURRENT_USER } from 'src/app/core/constants/const';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Utils } from 'src/app/shared/utils/utils';
import { HOUR_MULTIPLE_RESERVATION_DINNER } from '../../../core/constants/consts';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';

@Component({
  selector: 'app-multiple-reservation',
  templateUrl: './multiple-reservation.component.html',
  styleUrls: ['./multiple-reservation.component.scss'],
})
export class MultipleReservationComponent implements OnInit, AfterViewInit {
  messagesPipe = new MessagesPipe();
  @ViewChild('defaultOpen', null) defaultTab: ElementRef;
  multipleReservationForm: FormGroup;
  @ViewChild('datePickerFromElement', null) datePickerFromElement: MyDatePicker;
  @ViewChild('datePickerToElement', null) datePickerToElement: MyDatePicker;
  datepickerFromOptions: any = {};
  datepickerToOptions: any = {};
  startDate: Date;
  endDate: Date;
  locale: string;
  searchType = 'basic';
  selectedPeople: any[] = [];
  availableHours: number[] = [];
  url: String;
  selectOptionMenu: string;
  user: UserLdapWs;
  maxDesk: number;
  isDinner = false;

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position')
      ? 'dinner'
      : 'desks';
    this.isDinner = this.url.includes('/dinning-position') ? true : false;
    this.user = this.authenticationService.getCurrentUser();
    this.maxDesk = this.user.headquarters.maxDeskMultipleReservation;
    this.createForm();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
    this.datepickerFromOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays()
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.defaultTab.nativeElement.click();
      this.utils.setDatePickerDate(new Date(), this.datePickerFromElement);
    }, 0);
  }

  manageTabs(evt: any, tabName: string) {
    let i: number, tabcontent: any, tablinks: any;

    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' active';
  }

  setAvailableHours() {
    const user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    const closeTime = user.endingTime ? user.endingTime : user.closingTime;
    const maxHoursPerDay = (closeTime - user.openingTime) / (3600 * 1000);

    this.availableHours = Array.from(Array(maxHoursPerDay).keys()).map(
      (x) => ++x
    );
  }

  setAvailableHoursDinner() {
    this.availableHours = HOUR_MULTIPLE_RESERVATION_DINNER;
  }

  createForm() {
    const onlyNumbersRegex = /^\d+/;
    this.selectOptionMenu == 'dinner'
      ? this.setAvailableHoursDinner()
      : this.setAvailableHours();

    this.multipleReservationForm = this.formBuilder.group({
      numberOfpositions: [
        '',
        [Validators.required, Validators.pattern(onlyNumbersRegex)],
      ],
      numberOfHours: [
        '-1',
        [Validators.required, Validators.pattern(onlyNumbersRegex)],
      ],
    });
  }

  isFormValid(): boolean {
    return (
      this.multipleReservationForm.valid &&
      this.startDate != null &&
      this.endDate != null &&
      this.multipleReservationForm.controls['numberOfpositions'].value ==
        this.selectedPeople.length
    );
  }

  onStartDateChanged($event) {
    this.startDate = $event.jsdate;

    if (this.startDate) {
      if (this.startDate > this.endDate) {
        this.datePickerToElement.clearDate();
        this.datepickerToOptions = this.utils.getDatepickerOptions(
          this.authenticationService.getMaxHotDeskAdvanceDays()
        );
      }
      this.setRestrictionsToEndDate();
    } else {
      this.datePickerToElement.clearDate();
      this.datepickerToOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxHotDeskAdvanceDays()
      );
    }
  }

  onEndDateChanged($event) {
    this.endDate = $event.jsdate;
  }

  private setRestrictionsToEndDate(): void {
    const allowStartDate = new Date(this.startDate);
    allowStartDate.setDate(allowStartDate.getDate() - 1);

    this.datepickerToOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays(),
      allowStartDate
    );
  }

  getSelectedPeople($event): void {
    this.selectedPeople = $event;
  }

  seeAvailablePositions(): void {
    const dateFrom = this.startDate;
    const dateTo = this.endDate;
    const hotdeskNumber = parseInt(
      this.multipleReservationForm.controls['numberOfpositions'].value,
      10
    );
    const hourNumber = parseInt(
      this.multipleReservationForm.controls['numberOfHours'].value,
      10
    );

    localStorage.removeItem('optionReservationSelect');
    localStorage.removeItem('optionBuildingSelect');

    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'available-positions',
      dateFrom.getTime(),
      dateTo.getTime(),
      hotdeskNumber,
      hourNumber,
      this.selectedPeople
        .map((person) => {
          return person.id;
        })
        .join(','),
    ]);
  }
}
