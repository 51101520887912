<div class="main-container">
  <div class="fadeInLayer" *ngIf="!permissions?.disableReservation">
    <div
      *ngIf="parkingData?.Parking_plaza_Tipo"
      class="parking-wrapper {{ parkingData?.Parking_plaza_Tipo }}"
    >
      <div class="parking">
        <div class="parking__img">
          <img
            *ngIf="
              !isParkingTipoFija() ||
              parkingData?.parkingTipoTitle == 'Sin plaza'
            "
            src="../../../assets/img/no-parking.png"
            alt="no-parking"
          />
          <div
            *ngIf="
              isParkingTipoFija() ||
              parkingData?.parkingTipoTitle != 'Sin plaza'
            "
            class="parking-code"
          >
            <span>{{ parkingData?.Parking_plaza_Codigo }}</span>
            <span class="parking-floor">{{
              parkingData?.Parking_plaza_planta
            }}</span>
          </div>
        </div>
        <div class="parking__text">
          <span *ngIf="!isParkingTipoFija()">{{ parkingSpotMessage }} </span>
          <span *ngIf="isParkingTipoFija()"
            >{{ yieldParkingSpotDateMessage }}
          </span>
          <span
            *ngIf="!isParkingTipoFija() && !!parkingData.Fecha_sorteo"
            class="next-draw"
          >
            {{ "parking_nextDraw_message" | messagesPipe }}
            {{ fechaSorteo }}</span
          >
          <button (click)="toggleSidebar()">
            {{ requestParkingSpotMessage }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="manage-parking-layer"
      (click)="toggleSidebar()"
      [ngClass]="{ active: sidebarStatus }"
    ></div>
    <div
      class="manage-parking {{ parkingData?.Parking_plaza_Tipo }}"
      [ngClass]="{
        active: sidebarStatus,
        cedida: parkingData?.Plaza_Cedida == 'true'
      }"
    >
      <div class="manage-parking__header">
        <i (click)="toggleSidebar()" class="vdp-icon-cancelar"></i>
        <div class="manage-parking__header--box">
          <div class="manage-parking__header--box-green">
            <span>{{ yieldParkingSpotMessage }}</span>
            <span class="parking-floor">{{ yieldParkingFloor }}</span>
          </div>
          <div class="manage-parking__header--box-white">
            <span>{{ yieldOrRequestMessage }}</span>
          </div>
        </div>
      </div>
      <div class="manage-parking__content">
        <div class="manage-parking__content--items">
          <button
            *ngIf="
              isParkingTipoFija() ||
              parkingData?.parkingTipoTitle != 'Sin plaza'
            "
            (click)="freeParking()"
            [hidden]="hideCederHoy"
          >
            {{ "parking_yieldToday_message" | messagesPipe }}
          </button>
          <span class="next-draw">{{ yieldParkingSpotTemporaryMessage }}</span>
          <div class="manage-parking__content--item">
            <div class="manage-parking__content--item-from">
              <label>{{ "parking_from_date" | messagesPipe }}</label>
              <datepicker
                #from1
                class="custom-datepicker parking"
                id="from"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-to">
              <label>{{ "parking_to_date" | messagesPipe }}</label>
              <datepicker
                #to1
                class="custom-datepicker parking"
                id="to"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-switch">
              <input
                [ngClass]="{ disabled: !date1Status }"
                [checked]="checked1"
                (click)="actionDate(from1, to1, 0)"
                type="checkbox"
                id="switch1"
              />
              <label for="switch1">Toggle</label>
            </div>
          </div>

          <div class="manage-parking__content--item">
            <div class="manage-parking__content--item-from">
              <label>{{ "parking_from_date" | messagesPipe }}</label>
              <datepicker
                #from2
                class="custom-datepicker parking"
                id="fecha"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-to">
              <label>{{ "parking_to_date" | messagesPipe }}</label>
              <datepicker
                #to2
                class="custom-datepicker parking"
                id="fecha"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-switch">
              <input
                [ngClass]="{ disabled: !date2Status }"
                [checked]="checked2"
                (click)="actionDate(from2, to2, 1)"
                type="checkbox"
                id="switch2"
              />
              <label for="switch2">Toggle</label>
            </div>
          </div>

          <div class="manage-parking__content--item">
            <div class="manage-parking__content--item-from">
              <label>{{ "parking_from_date" | messagesPipe }}</label>
              <datepicker
                #from3
                class="custom-datepicker parking"
                id="fecha"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-to">
              <label>{{ "parking_to_date" | messagesPipe }}</label>
              <datepicker
                #to3
                class="custom-datepicker parking"
                id="fecha"
                [options]="datepickerOptions"
                (dateChanged)="onDateChanged($event)"
              ></datepicker>
            </div>
            <div class="manage-parking__content--item-switch">
              <input
                [ngClass]="{ disabled: !date3Status }"
                [checked]="checked3"
                (click)="actionDate(from3, to3, 2)"
                type="checkbox"
                id="switch3"
              />
              <label for="switch3">Toggle</label>
            </div>
          </div>
        </div>
        <div *ngIf="isParkingTipoFija()" class="manage-parking__content--text">
          <p>{{ "parking_yieldTemporary_message" | messagesPipe }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="disableReservation" *ngIf="permissions?.disableReservation">
    <label> {{'parking_disabled_reservation_title' | messagesPipe}}</label>
    <span> {{'parking_disabled_reservation_description' | messagesPipe}}</span>
  </div>
</div>
