import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AdinService } from 'src/app/shared/services/adin.service';
import { AttendanceService } from 'src/app/shared/services/attendance.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { NavigatorRefreshService } from 'src/app/shared/services/navigator.refresh.service';
import { SideModalService } from '../side-modal/side-modal.service';

@Component({
  selector: 'app-security-search',
  templateUrl: './security-search.component.html',
  styleUrls: ['./security-search.component.scss'],
})
export class SecuritySearchComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  securitySearchForm: FormGroup;
  optionList: any[] = [
    { id: 'search_name', description: 'security_search_name' },
    { id: 'search_plate', description: 'security_search_plate' },
    { id: 'search_place', description: 'security_search_place' },
  ];
  showResults = false;
  results: any[];
  placesAvailables: any;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    private adin: AdinService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private attendanceService: AttendanceService,
    private sideModalService: SideModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_security')
    );

    this.createForm();
    this.getPlacesAvailable();
    this.closeAssignPlaceForm();
  }

  createForm() {
    this.securitySearchForm = new FormGroup({
      securitySearchSelect: new FormControl('', Validators.required),
      searchByOption: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
    });
    this.securitySearchForm.controls['searchByOption'].disable();
  }

  securitySearchSelectChange(event: any) {
    this.securitySearchForm.controls['searchByOption'].setValue('');
    this.securitySearchForm.controls['searchByOption'].markAsUntouched();
    this.securitySearchForm.controls['searchByOption'].enable();
    if (event && event.target && !event.target.value) {
      this.securitySearchForm.controls['searchByOption'].disable();
    }
    this.showResults = false;
  }

  launchSearch() {
    const findBy =
      this.securitySearchForm.controls['securitySearchSelect'].value;
    const searchValue =
      this.securitySearchForm.controls['searchByOption'].value;

    this.spinnerService.show();

    switch (findBy) {
      case 'search_name':
        this.adin
          .searchByName(searchValue)
          .pipe(
            finalize(() => {
              this.spinnerService.hide();
            })
          )
          .subscribe(
            (response) => {
              if (response) {
                if (response.length > 0) {
                  this.showResults = true;
                  this.results = response;
                } else {
                  this.showResults = false;
                  this.results = [];
                  this.flashMessagesService.grayOut(false);
                  this.flashMessagesService.show(
                    this.messagesPipe.transform('security_search_by_name'),
                    { cssClass: 'alert-error', timeout: 3000 }
                  );
                }
              } else {
                this.showResults = false;
                this.results = [];
              }
            },
            (error) => {
              this.showResults = false;
              this.results = [];
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('security_search_service_error'),
                { cssClass: 'alert-error', timeout: 3000 }
              );
            }
          );
        break;

      case 'search_plate':
        this.adin
          .searchByPlate(searchValue)
          .pipe(
            finalize(() => {
              this.spinnerService.hide();
            })
          )
          .subscribe(
            (response) => {
              if (response) {
                if (response.length > 0) {
                  this.showResults = true;
                  this.results = response;
                } else {
                  this.showResults = false;
                  this.results = [];
                  this.flashMessagesService.grayOut(false);
                  this.flashMessagesService.show(
                    this.messagesPipe.transform('security_search_by_plate'),
                    { cssClass: 'alert-error', timeout: 3000 }
                  );
                }
              } else {
                this.showResults = false;
                this.results = [];
              }
            },
            (error) => {
              this.showResults = false;
              this.results = [];
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('security_search_service_error'),
                { cssClass: 'alert-error', timeout: 3000 }
              );
            }
          );
        break;

      case 'search_place':
        this.adin
          .searchByPlace(searchValue)
          .pipe(
            finalize(() => {
              this.spinnerService.hide();
            })
          )
          .subscribe(
            (response) => {
              if (response) {
                if (response.length > 0) {
                  this.showResults = true;
                  this.results = response;
                } else {
                  this.showResults = false;
                  this.results = [];
                  this.flashMessagesService.grayOut(false);
                  this.flashMessagesService.show(
                    this.messagesPipe.transform('security_search_by_place'),
                    { cssClass: 'alert-error', timeout: 3000 }
                  );
                }
              } else {
                this.showResults = false;
                this.results = [];
              }
            },
            (error) => {
              this.showResults = false;
              this.results = [];
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('security_search_service_error'),
                { cssClass: 'alert-error', timeout: 3000 }
              );
            }
          );
        break;
    }
  }

  getPlacesAvailable() {
    this.attendanceService.getPlacesAvailable().subscribe(
      (response) => {
        if (response) {
          this.placesAvailables = response;
        }
      },
      (error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('security_search_places_available_error'),
          { cssClass: 'alert-error', timeout: 3000 }
        );
      }
    );
  }

  assignPlace() {
    this.sideModalService.openSideModal(
      'assignPlace',
      {},
      this.messagesPipe.transform('security_assign_place_btn'),
      this.route,
      true
    );
  }

  closeAssignPlaceForm() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close) {
        this.getPlacesAvailable();
      }
    });
  }
}
