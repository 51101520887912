<div class="quesito-and-answers-type__container">
  <form
    [formGroup]="formType"
    (ngSubmit)="newTreeUserLdap()"
    class="quesito-and-answers-type__container__content"
  >
    <h1>{{ 'question-and-answers_type_title' | messagesPipe }}</h1>
    <div class="quesito-and-answers-type__container__content--list">
      <div
        class="card"
        *ngFor="let type of listTreeType"
        (click)="setType(type.id)"
        [ngClass]="{ active: type.id == formType?.controls['typeId']?.value }"
      >
        <span>{{ type.description }}</span>
      </div>
    </div>
    <button type="submit" [disabled]="!formType?.valid">
      {{ 'question-and-answers_type_title' | messagesPipe }}
    </button>
  </form>
</div>
