import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { ModalIndicateDepartmentService } from '../../shared/components/modal-indicate-department/modal-indicate-department.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate {
  permissions: MenuPermissions;
  user: UserLdapWs;
  moduleParking = false;
  moduleShuttle = false;
  moduleEvent = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalIndicateDepartmentService: ModalIndicateDepartmentService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.user = this.authenticationService.getCurrentUser();
    const url = state.url;
    let status = false;

    this.user.headquarters.modules.forEach((element) => {
      if (element.code === '0019' && element.state) {
        this.moduleParking = true;
      } else if (element.code === '0020' && element.state) {
        this.moduleShuttle = true;
      }
      if (element.code == '0029') {
        this.moduleEvent = true;
      }
    });

    switch (true) {
      case url.includes('/reservations'):
        if (
          this.permissions &&
          this.permissions.allowReservation &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;
      case url.includes('/parkingOne'):
        if (
          this.permissions &&
          this.permissions.allowParking &&
          this.permissions.parkingType === 1 &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;
      case url.includes('/parking'):
        if (
          this.permissions &&
          this.permissions.allowParking &&
          this.permissions.parkingType === 2 &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;
      case url.includes('/incidence'):
        if (
          this.permissions &&
          this.permissions.allowIncidence &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/security'):
        if (
          this.permissions &&
          this.permissions.allowSecurity &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/job-position'):
        if (
          this.permissions &&
          this.permissions.allowHotDesktop &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/dinning-position'):
        if (
          this.permissions &&
          this.permissions.allowDinnerDesktop &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/assistance'):
        if (
          this.permissions &&
          this.permissions.allowAssistance &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/assistance-user'):
        if (
          this.permissions &&
          this.permissions.allowAssistance &&
          this.user.isManager > 0 &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/cantine'):
        if (
          this.permissions &&
          this.permissions.allowCantine &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/recursos'):
        if (
          this.permissions &&
          this.permissions.allowResourceForHour &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/carWash'):
        if (
          this.permissions &&
          this.permissions.allowCarwash &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/questionsAndAnswers'):
        if (
          this.permissions &&
          this.permissions.allowTree &&
          this.moduleEvent &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/occupationParking'):
        if (
          this.permissions &&
          this.permissions.allowUserParking &&
          this.moduleParking &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;

      case url.includes('/shuttle'):
        if (this.moduleShuttle && this.checkDptForUser()) {
          status = true;
        }
        break;
      case url.includes('/dorlet'):
        if (
          this.permissions &&
          this.permissions.dorletParking &&
          this.checkDptForUser()
        ) {
          status = true;
        }
        break;
      //  INFO: MAYORDOMO Deshabilitado (permiso: '')
      case url.includes('/steward'):
        if (false && this.checkDptForUser()) {
          status = true;
        }
        break;
    }
    if (!status) {
      this.router.navigate(['/menu/home']);
      if (!this.checkDptForUser()) {
        this.modalIndicateDepartmentService.openModal();
      }
    }

    return status;
  }

  checkDptForUser() {
    this.user = this.authenticationService.getCurrentUser();
    if (this.permissions && this.user) {
      if (this.permissions.mandatoryDpto && this.user.department) {
        return true;
      } else if (!this.permissions.mandatoryDpto) {
        return true;
      }
    }
    return false;
  }
}
