import { Component, OnInit } from '@angular/core';
import { ModalIndicateDepartmentService } from './modal-indicate-department.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router } from '@angular/router';
import { BaseService } from '../../../shared/services/base.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { Headquarters } from '../../../core/models/headquarters';
import { UserService } from '../../../shared/services/user.service';
import { Department as Department } from 'src/app/core/models/department';
import { DepartmentService } from 'src/app/shared/services/department.service';
import { BuildingService } from '../../../shared/services/building.service';
import { BuildingWs } from '../../../core/models/building.ws';
import { HeadquartersWS } from '../../../core/models/headquarters.ws';
import { HeadquartersService } from '../../../shared/services/headquarters.service';
import { LOCAL_CURRENT_USER } from '../../../core/constants/const';

@Component({
  selector: 'app-modal-indicate-department',
  templateUrl: './modal-indicate-department.component.html',
  styleUrls: ['./modal-indicate-department.component.scss'],
})
export class ModalIndicateDepartmentComponent implements OnInit {
  modalConfig: any;
  user: UserLdapWs;
  messagesPipe = new MessagesPipe();
  departments: Department[];
  selectedDept: any;
  buildings: BuildingWs[];
  headquarters: HeadquartersWS[];

  constructor(
    private modalIndicateDepartmentService: ModalIndicateDepartmentService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService,
    private userService: UserService,
    private departmentService: DepartmentService,
    private buildingService: BuildingService,
    private headquartersService: HeadquartersService
  ) {
    this.modalIndicateDepartmentService.openModal = this.openModal.bind(this);
    this.modalIndicateDepartmentService.closeModal = this.closeModal.bind(this);
  }

  ngOnInit() {
    this.modalConfig = document.getElementById('modalConfig');
    this.user = this.authenticationService.getCurrentUser();
    if (this.user.headquarters) {
      this.getDepartmentListByHeadquarter(
        this.authenticationService.getToken(),
        this.user.headquarters.id
      );
      this.getBuildingListByHeadquarter(
        this.authenticationService.getToken(),
        this.user.headquarters.id
      );
      this.getFinalHeadquarterList(this.authenticationService.getToken());
    }
  }

  openModal() {
    this.modalConfig = document.getElementById('modalConfig');
    this.modalConfig.style.display = 'flex';
  }

  closeModal() {
    this.modalConfig.style.display = 'none';
  }

  logout() {
    this.authenticationService.logout();
  }

  indicateDept() {
    if (!this.selectedDept) {
      this.baseService.showErrorDialog(
        this.flashMessagesService,
        '',
        this.messagesPipe.transform('indicate_your_dept_error')
      );
    } else {
      this.updateUser(this.createJsonUpdateUser());
    }
  }

  updateUser(user: UserLdapWs) {
    this.userService
      .updateProfile(this.authenticationService.getToken(), user)
      .subscribe(
        (response) => {
          if (response) {
            this.router.navigate(['/menu/home']);
            this.closeModal();
            this.user.building = user.building;
            this.user.headquarters = user.headquarters;
            this.user.department = user.department;
            this.user.showHotdeskReservation = user.showHotdeskReservation;

            this.getUserEditPermission();

            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              this.messagesPipe.transform('updateProfile_success'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('updateProfile_error')
          );
        }
      );
  }

  getDepartmentListByHeadquarter(token: string, headquartersId: number) {
    this.departmentService
      .getDepartmentListByHeadquarterId(token, headquartersId)
      .subscribe(
        (data) => {
          this.departments = data;
          this.selectedDept = data ? data[0].id : '';
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_departments_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  getBuildingListByHeadquarter(token: string, headquartersId: number) {
    this.buildingService
      .getBuildingListByHeadquarters(token, headquartersId)
      .subscribe(
        (buildings) => {
          this.buildings = buildings;
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('getBuildings_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  getFinalHeadquarterList(token: string) {
    this.headquartersService.getFinalHeadquarterList(token).subscribe(
      (headquarters) => {
        this.headquarters = headquarters;
      },
      (error) => {
        this.flashMessagesService.show(
          this.messagesPipe.transform('getHeadquarters_error'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
      }
    );
  }

  onChangeDept(value: any) {
    this.selectedDept = value;
  }

  createJsonUpdateUser() {
    let userAux = new UserLdapWs();
    let mediaType = 'base64,';
    let headquartersSelectedAux;

    userAux.name = this.user.name;
    userAux.email = this.user.email;
    userAux.image = this.user.image;
    userAux.showHotdeskReservation = this.user.showHotdeskReservation;

    headquartersSelectedAux = this.headquarters.filter(
      (headquarter) => headquarter.id == this.user.headquarters.id
    );

    userAux.headquarters = new Headquarters(
      headquartersSelectedAux[0].id,
      headquartersSelectedAux[0].name,
      headquartersSelectedAux[0].allowParking,
      headquartersSelectedAux[0].allowReservation
    );
    if (userAux.image && userAux.image.length > 0) {
      userAux.image = userAux.image.substring(
        userAux.image.indexOf(mediaType) + mediaType.length,
        userAux.image.length
      );
    }
    if (this.user.building != null && this.user.building.id > 0) {
      userAux.building = this.buildingService.findById(
        this.buildings,
        this.user.building.id
      );
    } else {
      userAux.building = null;
    }

    userAux.department = this.departmentService.findById(
      this.departments,
      parseInt(this.selectedDept)
    );

    return userAux;
  }

  getUserEditPermission() {
    this.userService
      .getUserPermissions(this.authenticationService.getToken())
      .subscribe(
        (response) => {
          if (response.authorizations != null) {
            let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
            user.authorizations = response.authorizations;
            localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
            this.authenticationService.updateUser(response);
          }
        },
        (error) => {
          console.error();
        }
      );
  }
}
