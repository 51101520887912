import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TreeTypeModel } from '../../../core/models/tree.type.model';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { TreeService } from '../../../shared/services/tree.service';

@Component({
  selector: 'app-question-and-answers-type',
  templateUrl: './question-and-answers-type.component.html',
  styleUrls: ['./question-and-answers-type.component.scss'],
})
export class QuestionAndAnswersTypeComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  listTreeType: TreeTypeModel[] = [];
  formType: FormGroup;

  constructor(
    private treeService: TreeService,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getTreeTypeByUser();
    this.initForm();
  }

  initForm() {
    this.formType = this.formbuilder.group({
      typeId: [null, [Validators.required]],
    });
  }

  setType(id: number) {
    this.formType.setValue({
      typeId: id,
    });
  }

  navigateToQuestion(id: number) {
    this.router.navigate(['menu/questionsAndAnswers/new', id]);
  }

  getTreeTypeByUser() {
    this.spinnerService.show();
    this.treeService.getTreeTypeByUser().subscribe({
      next: (response) => {
        if (response) {
          this.listTreeType = response;
        } else {
          this.listTreeType = [];
        }
      },
      error: () => {
        this.listTreeType = [];
      },
      complete: () => {
        this.spinnerService.hide();
      },
    });
  }

  newTreeUserLdap() {
    this.treeService
      .postNewTreeUserLdap(this.formType.controls['typeId'].value)
      .subscribe({
        next: (response) => {
          if (response) {
            this.navigateToQuestion(response.id);
          }
        },
        error: () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('question-and-answers_type_next_error'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
        },
      });
  }
}
