import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() stylesContent: StyleSheet;

  spinner: any;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.show = this.show.bind(this);
    this.spinnerService.hide = this.hide.bind(this);
  }

  ngOnInit() {
    this.spinner = document.getElementById('spinner-control');
  }

  show() {
    this.ngOnInit();
    this.spinner.style.display = 'flex';
    window.setTimeout(() => (this.spinner.style.opacity = 1), 100);
  }

  hide() {
    window.setTimeout(
      () => (
        (this.spinner.style.opacity = 0), (this.spinner.style.display = 'none')
      ),
      400
    );
  }
}
